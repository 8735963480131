import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core//Grid";
import RDrawer from "../components/Top";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useLocation } from "react-router-dom";
import { header, getDomain, logout } from "../../auth";
import { set_info } from "../../helper/helper";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
    minHeight: "100vh",
  },
  container: {
    paddingTop: 19,
    paddingBottom: "61px",
    position: "relative",
    height: "calc(100% - 65px)",
  },

  fixedHeight: {
    height: 240,
  },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    marginRight: 10,
    textTransform: "none",
    letterSpacing: 1,
  },
  paper: {
    background: "#e9e9e9",
    boxShadow: "none",
    borderRadius: 15,
    padding: "20px 20px 20px 20px",
    marginTop: 10,
  },
  input: {
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    padding: "5px",
    paddingLeft: "10px",
  },
  boxbtn: {
    bottom: "20px",
    position: "absolute",
    padding: "15px 0 0",
    background: "#FFF",
    marginTop: "0px",
    width: "calc(100% - 100px)",
  },
   boxbtn1: {
    bottom: "0px",
    position: "absolute",
    padding: "15px 0 0",
    background: "#FFF",
    marginTop: "0px",
    width: "calc(100% - 100px)",
  },
}));

export default function Addcoupons() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const matches = useMediaQuery('(max-width:599px)');
  let [formData, setFormData] = useState(location.state.params);

  function formSubmit(){
    if(formData.id==="")
    {
      axios.post(getDomain()+'/coupon/store',formData,{
        headers : header()
      })
      .then(function(response){
        console.log(response);
        set_info("You have created a coupon successfully","success");
        history.goBack();
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
        set_info("Something is went wrong, please try after sometime","error");
        history.goBack();
      });
    }
    else{
      axios.put(getDomain()+'/coupon/update/'+formData.id,formData,{
        headers : header()
      })
      .then(function(response){
        console.log(response);
        set_info("You have updated a coupon successfully","success");
        history.goBack();
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
        set_info("Something is went wrong, please try after sometime","error");
        history.goBack();
      });
    }
    
  }
  const eventHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const boxareabtn = matches ? classes.boxbtn1 : classes.boxbtn;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="100%" className={classes.container}>
          <Paper className={classes.paper}>
            <Box maxWidth={610}>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Title</Typography>
                    <Input
                      name="title"
                      value={formData.title}
                      defaultValue=""
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      onChange={ eventHandler }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">No fo Use</Typography>
                    <Input
                      name="no_of_use"
                      value={formData.no_of_use}
                      defaultValue=""
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      onChange={ eventHandler }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Discount</Typography>
                    <Input
                      name="amount"
                      value={formData.amount}
                      defaultValue=""
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      onChange={ eventHandler }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Status</Typography>
                    <select onChange={ eventHandler } value={formData.status} className={classes.input} fullWidth name="status">
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </select>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Box className={boxareabtn}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={ formSubmit }
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => history.goBack()}
              size="small"
            >
              Cancel
            </Button>
          </Box>
        </Container>
      </main>
    </div>
  );
}
