import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core//Grid";
import RDrawer from "../components/Top";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 18,
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
    letterSpacing: 1,
  },

  input: {
    background: "#e9e9e9",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 13,
    padding: "5px",
    paddingLeft: "10px",
  },
  input1: {
    background: "#e9e9e9",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 13,
    padding: "5px",
    paddingLeft: "10px",
    paddingTop: 10,
  },
  btntext1: {
    fontSize: 15,
    fontWeight: "700",
    borderRadius: 10,
    letterSpacing: 1,
    textTransform: "none",
    padding: "7px 15px",
    marginLeft: 10,
  },
}));

export default function Editfaq() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="100%" className={classes.container}>
          <Box maxWidth={700} marginTop={3}>
            <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={3} md={3}>
                      <Typography variant="h2">Question Title :</Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Input
                        defaultValue="Question no 1 will be here"
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={3} md={3}>
                      <Typography variant="h2">Answer : </Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                      <Input
                        defaultValue="Lorem lipsum is dummy text Lorem lipsum is dummy textLorem lipsum is dummy textLorem lipsum is dummy textLorem lipsum is dummy textLorem lipsum is dummy text "
                        fullWidth
                        multiline
                        disableUnderline
                        rows={6}
                        className={classes.input1}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>

          <Box marginTop={5} marginLeft={21}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={() => history.push("/admin/faq")}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={() => history.push("/admin/faq")}
            >
              Cancel
            </Button>
          </Box>
        </Container>
      </main>
    </div>
  );
}
