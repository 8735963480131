import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from './frontend/components/Theme';
import Customer from "./admin/Customers/Customer";
import CustomerDetails from "./admin/Customers/CustomerDetails";
import Viewactiveplan from "./admin/Plans/Viewactiveplan";
import Viewplans from "./admin/Plans/Viewplans";
import Addnewplan from "./admin/Plans/Addnewplan";
import Editplan from "./admin/Plans/Editplan";
import Addfaq from "./admin/Contents/Addfaq";
import Editfaq from "./admin/Contents/Editfaq";
import Faq from "./admin/Contents/Faq";
import Refundpolicy1 from "./admin/Contents/Refundpolicy";
import Privacypolicy1 from "./admin/Contents/Privacypolicy";
import Termsofuse1 from "./admin/Contents/Termsofuse";
import Addcoupons from "./admin/General/Addcoupons";
import Coupons from "./admin/General/Coupons";
import Notification from "./admin/General/Notification";
import AddNotification from "./admin/General/AddNotification";
import Settings from "./admin/General/Settings";
import IPs from "./admin/IPs/IPs";
import Report from "./admin/Report/Report";
import HomeScreen from './frontend/pages/HomeScreen'
import Refundpolicy from './frontend/pages/Refundpolicy';
import Termsofuse from './frontend/pages/Termsofuse';
import Login from './frontend/pages/Login';
import Privacypolicy from './frontend/pages/Privacypolicy';
import Pricing from './frontend/pages/Pricing';
import FAQ from './frontend/pages/Faq';
import Dashboard from './frontend/pages/Dashboard'
import Dashboardlight from './frontend/pages/Dashboardlight';
import {ProtectedRoute} from './ProtectedRoute';
import {PrivateRoute,SupportRoute} from './PrivateRoute';
import ProxyTester from './frontend/pages/ProxyTester';
import { getDomain } from "./auth";
const axios = require('axios');
function App() {
  useEffect(()=>{
    axios.get(getDomain()+"/static-urls")
    .then(function(response){
      console.log(response.data);
      const allLink = response.data;
      localStorage.setItem("social_discord_url", allLink.social_discord_url);
      localStorage.setItem("social_instagram_url", allLink.social_instagram_url);
      localStorage.setItem("social_twitter_url", allLink.social_twitter_url);
    })
    .catch(function(error){
      console.log(error);
    });
  },[])
  return (
     <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
	  <Route path="/ProxyTester" exact component={ProxyTester} />
      <Route path="/" exact component={HomeScreen} />
      <Route path="/login" exact component={Login} />
        <Route path="/refund-policy" exact component={Refundpolicy} />
        <Route path="/terms-of-use" exact component={Termsofuse} />
        <Route path="/privacy-policy" exact component={Privacypolicy} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/faq" exact component={FAQ} />
        <SupportRoute path="/dashboard/:id" exact component={Dashboard} />
        <ProtectedRoute path="/dashboard" exact component={Dashboard} />
        {/*
          <Route path="/dashboardlight" exact component={Dashboardlight} />
        */}
        <SupportRoute path="/admin" exact component={Customer} />
        <PrivateRoute path="/admin/customer-details/:id" exact component={CustomerDetails} />
        <PrivateRoute path="/admin/add-new-plan" exact component={Addnewplan} />
        <PrivateRoute path="/admin/view-active-plan" exact component={Viewactiveplan} />
        <PrivateRoute path="/admin/view-plans" exact component={Viewplans} />
        <PrivateRoute path="/admin/edit-plan" exact component={Editplan} />
        <PrivateRoute path="/admin/add-faq" exact component={Addfaq} />
        <PrivateRoute path="/admin/edit-faq" exact component={Editfaq} />
        <PrivateRoute path="/admin/faq" exact component={Faq} />
        <PrivateRoute path="/admin/privacy-policy" exact component={Privacypolicy1} />
        <PrivateRoute path="/admin/refund-policy" exact component={Refundpolicy1} />
        <PrivateRoute path="/admin/terms-of-use" exact component={Termsofuse1} />
        <PrivateRoute path="/admin/add-coupons" exact component={Addcoupons} />
        <PrivateRoute path="/admin/coupons" exact component={Coupons} />
        <PrivateRoute path="/admin/notification" exact component={Notification} />
        <PrivateRoute path="/admin/add-notification" exact component={AddNotification} />
        <PrivateRoute path="/admin/settings" exact component={Settings} />
        <PrivateRoute path="/admin/ips" exact component={IPs} />
        <PrivateRoute path="/admin/report" exact component={Report} />
     </Router>
      </ThemeProvider>
  );
}

export default App;
