import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getDomain } from "../auth";
const axios = require('axios');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const dimension = {
    vertical: 'top',
    horizontal: 'center'
}

export class CustomAlert extends Component {
    render() {
        return(
            <Snackbar open={ this.props.open } autoHideDuration={60000} onClose={ this.props.handleClose } anchorOrigin={ dimension }>
                <Alert onClose={this.props.handleClose} severity={ this.props.severity } >
                    { this.props.message }
                </Alert>
            </Snackbar>
        );
    }
}

export const user_message = () =>{
    const temp=localStorage.getItem("user_message");
    return temp;
}

export const user_severity = () =>{
    const temp=localStorage.getItem("user_severity");
    return temp;
}

export const set_info = (message,severity) =>{
    localStorage.setItem("user_message", message);
    localStorage.setItem("user_severity", severity);
}
export const delete_info = (message,severity) =>{
    localStorage.removeItem("user_message");
    localStorage.removeItem("user_severity");
}
export const ConfirmAlert= props => {
    return(
        <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{ props.title }</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                { props.description }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.disagree} color="primary">
                Disagree
            </Button>
            <Button onClick={props.agree} color="primary" autoFocus>
                Agree
            </Button>
        </DialogActions>
        </Dialog>
    );
}
export const staticUrls = () =>
{
    return {
        social_discord_url: localStorage.getItem("social_discord_url"),
        social_instagram_url: localStorage.getItem("social_instagram_url"),
        social_twitter_url: localStorage.getItem("social_twitter_url")
    }
}
export const editor_apiKey = () =>
{
    return "x9g4y17yf24vlz1evvxlkg3s9plnuzxdb5myfkbsmk7kyin1";
}
