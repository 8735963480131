import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
//import GitHubIcon from '@material-ui/icons/GitHub';
import { staticUrls } from "../../helper/helper";
const links = staticUrls();
const useStyles = makeStyles((theme) => ({


 footer: {  
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: "#00051b",     
        paddingBottom: theme.spacing(3),
        "& .MuiTypography-h5": {
          marginTop: "15px",
          fontSize:"14px",
          letterSpacing:"0",
          [theme.breakpoints.down(959)]: {
            marginTop:"0px",
            marginBottom:"-8px",
          }
      } 
    },
  
    socialicon: {
        color:"#FFF"
    },
    iconbtn: {
        backgroundColor: "#1870ff",
        margin:"5px",
        height:"48px",
        width:"48px",
    },
    fottSocial:{
        textAlign:"right",
        [theme.breakpoints.down(959)]: {
         textAlign:"center",
       }   
     },
     footButton:{
        display:"flex" ,
        marginTop:"6px",
        [theme.breakpoints.down(959)]: {
         display:"block" ,
         textAlign:"center",
       } 
     },
  }));


export default function Footer() {
      const classes = useStyles();
    return (
        <div className={classes.footer}>
        <Container maxWidth="lg" component="footer" width="1200px">

                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={8}>
                    <Box className={classes.footButton}>
                        <Typography variant="h5" style={{color:"#2e3045" }}>Live Proxy Copyright @2020 All Rights Reserved</Typography>
                    </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    <Box className={classes.fottSocial}>
                    <IconButton href={ links.social_twitter_url } target="_blank" className={classes.iconbtn}><TwitterIcon className={classes.socialicon}/></IconButton>
                    <IconButton href={ links.social_discord_url } target="_blank" className={classes.iconbtn}><img src={require("../../assets/images/discordIcon.png")}/> </IconButton>
                     <IconButton href={ links.social_instagram_url } target="_blank" className={classes.iconbtn}><MailOutlineIcon className={classes.socialicon}/></IconButton>
                  </Box>
                    </Grid>
                    </Grid>
          
            </Container>
            </div>
    );
}