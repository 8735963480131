import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Link from '@material-ui/core/Link';
import { staticUrls } from "../../helper/helper";
const links = staticUrls();

const useStyles = makeStyles((theme) => ({
   footer: {
      marginTop: "-56px",
      paddingTop: "48px",
      backgroundColor: "#00051b",
      paddingLeft: "0px",
      paddingRight:"0px",
      paddingBottom: "29px",
      [theme.breakpoints.up('sm')]: {
      paddingTop: "48px",
      paddingBottom: "29px",
      },
      [theme.breakpoints.down(959)]: {
        paddingTop:"20px",
        marginTop: "-41px",
    }, 
    [theme.breakpoints.down(599)]: {
      paddingTop:"10px",
      marginTop: "-41px",
    }

    },
    dividerspacee: {
        paddingLeft: "20px",
        paddingRight: "20px",
       
    },
    logo: {
    height: "auto",
    width: "150px",
      marginRight: "10px",
    marginTop: "50px"
    },
    toolbarTitle: {
        paddingTop:"12px"
    },
    socialicon: {
        color:"#FFF"
    },
    iconbtn: {
        backgroundColor: "#1870ff",
        margin: "5px",
        marginTop: "50px",
        height:"48px",
        width:"48px",
        [theme.breakpoints.down(599)]: {
          marginTop: "20px",
         } 
  },
   startbtn: {
     color: "#dedfe4",
     textTransform: "none",
     fontFamily: "Poppins",
     fontWeight: "500",
     fontSize: 16,
     padding: "15px",
     [theme.breakpoints.down(599)]: {
      padding: "12px 15px",
      fontSize: 15,
      lineheight:"normal"
     } 
    },
  link: {
    display:"block",
    color: "#a0a3ad",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: 15,
    fontWight: "300", 
    margin:10,
    "&:hover": {
       color:"#1870ff"
     }
    },
  footerbtn: {
    textAlign: "center",
    marginTop:"60px",
    backgroundColor: "#1870ff",
    marginBottom:"40px"
  },
  copyright: {
    textAlign:"center" ,borderTop:"1px dashed #0d1b5c",
    paddingTop:"30px",
    "& h4":{
       fontSize:"13px",
       letterSpacing:"0"
    },

    [theme.breakpoints.down(599)]: {
      padingTop: "20px",
      marginTop: "20px",
     }
  },

  footInn1:{
    [theme.breakpoints.down(959)]: {
       display:"none"
     } 
  },
  footInn2:{
    [theme.breakpoints.down(959)]: {
      width:"33.33%"
    },
    [theme.breakpoints.down(599)]: {
      width:"50%"
    }  
  },
  footInn3:{
    [theme.breakpoints.down(959)]: {
      width:"33.33%"
    },
    [theme.breakpoints.down(599)]: {
      width:"50%"
    } 
  },
  footInn4:{
    textAlign:"right",
    [theme.breakpoints.down(959)]: {
      width:"33.33%"
    },
    [theme.breakpoints.down(599)]: {
      width:"100%",
      textAlign:"center",
    }  
  },
  liveproxycopyright: {
    fontSize: 15,
      fontWeight: "400",
      letterSpacing: 1,
      marginBottom: 3,
      lineHeight: "20px",
      marginTop:"25",color:"#2e3045"
  }
  }));


export default function Footer() {
      const classes = useStyles();
      const history = useHistory();
  return (
    <div  className={classes.footer}  component="footer">
      <Box className={classes.footerbtn}>
        <Button className={classes.startbtn} onClick={() => history.push("/Pricing")}>Turn your botting experience into a magic with Live Proxies, start here!</Button>
         </Box>
      <Container maxWidth="lg" width="1200px">
        <Grid container className={classes.footerOut} >
                <Grid item md={3} className={classes.footInn1}>
                  <Box display="flex" textAlign="left">
                      <img src={require("../../assets/images/logo.png")} alt="img" className={classes.logo}/>
                 </Box>
                </Grid>
                 <Grid item md={3} align="left" className={classes.footInn2}>
                 <Link variant="button"  href="/" className={classes.link} underline="none">Home
               </Link>
                <Link variant="button" className={classes.link} href="/pricing" underline="none">
              Pricing
             </Link>
            <Link variant="button" className={classes.link} href="/faq" underline="none">
              FAQ
             </Link>
            <Link variant="button" className={classes.link} href="/dashboard" underline="none">
               Dashboard
            </Link>
                 </Grid>
                  <Grid item md={3} align="left" className={classes.footInn3}>
             <Link variant="button" className={classes.link} href="/refund-policy" underline="none">
              Refund Policy
              </Link>
              <Link variant="button" className={classes.link} href="/privacy-policy" underline="none">
              Privacy Policy
             </Link>
             <Link variant="button" className={classes.link} href="/terms-of-use" underline="none">
               Terms of Use
            </Link>
              </Grid>
                 <Grid item md={3} className={classes.footInn4}> 
                    <IconButton className={classes.iconbtn} href={ links.social_twitter_url } target="_blank"><TwitterIcon className={classes.socialicon}/></IconButton>
                    <IconButton className={classes.iconbtn} href={ links.social_discord_url } target="_blank"><img src={require("../../assets/images/discordIcon.png")} alt="img"/> </IconButton>
                    <IconButton className={classes.iconbtn} href={ links.social_instagram_url } target="_blank"><MailOutlineIcon className={classes.socialicon}/></IconButton>
                  </Grid>
                </Grid>
           <Box mt={5} className={classes.copyright}>
            <Typography className={classes.liveproxycopyright}>Live Proxy Copyright @2020 All Rights Reserved</Typography>
            </Box>
      </Container>
      </div>
    );
}