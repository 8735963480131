import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {  Container } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import image1 from '../../assets/images/clocks.png'

const useStyles = makeStyles((theme) => ({
   
    description: {
      fontSize: 14,
      fontWeight: "500",
      color: "#515b79",
      letterSpacing: 1,
        marginBottom: 3,
      fontFamily:"Poppins"
      
    },
   imageSrc: {
    height: "auto",
    width:"auto",
    overflow:"hidden"
  },
    heading: {
        fontSize: "18px",
        fontWeight: "500",
        fontFamily: "Poppins",
        color: "#dbdce2",
         [theme.breakpoints.down(599)]: {
          fontWeight: "400",
         } 
   
  },
  serviceicon: {
    height: "50px",
    width:"auto",
    marginBottom:10
  },
  serviceOut:{ 
    display:"flex",
    justifyContent:"center",
    textAlign:"center",
    [theme.breakpoints.down(959)]: {
      display:"block",
      paddingBottom:"45px"
   } 
  },
  servicess: {
    display: "inline-block",
    textAlign: "center",
    margin:"75px 0",
    width:"25%",
    [theme.breakpoints.down(959)]: {
      width:"50%",
      margin:"40px 0 0",
    },
    [theme.breakpoints.down(479)]: {
      width:"100%",
      margin:"40px 0 0",
    },  
  },
  sectionHead:{
    [theme.breakpoints.down(959)]: {
      fontWeight: "500",
      fontSize:"30px", 
      marginBottom:"0px" ,
      lineHeight:"normal", 
    },
    [theme.breakpoints.down(599)]: {
      fontSize:"20px",
      letterSpacing:"0.2px"
    }
  }
}));
function HomeContent4() {
    AOS.init();
    const classes = useStyles();

    return (
        <div className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image1})`,
              backgroundSize:"cover"
            }}>
            <Container maxWidth="lg" width="1200px">
          <Box className={classes.serviceOut}>
            <Box className={classes.servicess} data-aos="fade-right" data-aos-duration="700" ><img src={require("../../assets/images/service-Icon1.png")} alt="im1" className={classes.serviceicon} />
 <Typography className={classes.heading}>Flexible Plans</Typography></Box>

            <Box className={classes.servicess} data-aos="fade-right" data-aos-duration="700" > <img src={require("../../assets/images/service-Icon2.png")} alt="im1" className={classes.serviceicon} />
<Typography className={classes.heading}>Instant Delivery</Typography></Box>
            <Box className={classes.servicess} data-aos="fade-left" data-aos-duration="700" > <img src={require("../../assets/images/service-Icon3.png")} alt="im1" className={classes.serviceicon} />
<Typography className={classes.heading}>99.99% Uptime</Typography></Box>
             <Box className={classes.servicess} data-aos="fade-left" data-aos-duration="700" ><img src={require("../../assets/images/service-Icon4.png")} alt="im1" className={classes.serviceicon} />
<Typography className={classes.heading}>Rotation and Static</Typography></Box>
               
               
               </Box>
          
           
                </Container>
      </div>
  );
}

export default HomeContent4;
