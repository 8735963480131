import React, { useEffect } from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DashboardTop from '../components/Dashoboard-top'
import { Card, Paper, CardContent, CardHeader } from "@material-ui/core";
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from "@material-ui/core/Button";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { colors, bluegradient } from '../components/colors'
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import DashboardFooter from '../components/DashboardFooter';
import { Input } from '@material-ui/core';
import { header, getDomain, user_id, isAdmin, logout, isSupport } from "../../auth";
import { useHistory } from 'react-router-dom';
import { CustomAlert, user_message, user_severity, delete_info } from "../../helper/helper";
import copy from "copy-to-clipboard";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
let dashTheme = localStorage.getItem("theme");
if (dashTheme && dashTheme == 1) {
  dashTheme = 1;
}
else {
  dashTheme = 0;
}

const axios = require('axios');
const themeDeff = [
  {
    c1: "#01092d",
    c2: "#000f48",
    c3: "#010a35",
    c4: "1px solid #121c44",
    c5: "#010a35",
    c6: "1px dashed #3f4a6b",
    c7: "16px 20px 16px !important",
    c8: "#010a35",
    c9: "1px dashed #3f4a6b", textAlign: "right",
  },
  {
    c1: "#f1f2f6",
    c2: "#fff",
    c3: "#fff",
    c4: "1px solid #c2c4d0",
    c5: "#fff",
    c6: "1px dashed #707ca0",
    c7: "17px 20px 0px",
    c8: "#e2e3e9",
    c9: "1px dashed #707ca0", textAlign: "right",
  }
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(93,93,93,0.10)",
      outline: "0px solid rgba(93,93,93,0.10)",
    },
  },

  loginWrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: themeDeff[dashTheme].c1,
    overflow: "auto",
    [theme.breakpoints.down(1200)]: {
      position: "static",
      overflow: "visible",
    },
  },

  content: {
    flexGrow: 1,
    position: "relative",
    //backgroundColor: "#f1f2f6",
    height: "calc(100% - 195px)",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: "#151948",
    justifyContent: 'flex-end',
    minHeight: "90px",
    [theme.breakpoints.down(1200)]: {
      minHeight: "65px",
      height: "65px",
    }
  },
  container1: {
    padding: "20px 30px 0px",
    margin: "0px",
    maxWidth: "100%",
    position: "relative",
    height: "calc(100% - 65px)",
    [theme.breakpoints.down(1200)]: {
      padding: "15px 20px 0px",
    },
  },
  leftCont: {
    position: "relative",
    height: "calc(100% - -14px)",
  },
  rightCont: {
    position: "relative",
    height: "calc(100% - -14px)",
    overflow: "auto",
  },
  rightContScroll: {
    height: "calc(100% - -21px)",
    overflow: "auto",
  },

  leftContScroll: {
    position: "relative",
    height: "calc(100% - -14px)",

  },

  imageSrc: {
    height: "150px",
    width: "auto"
  },




  nativeslect: {
    backgroundColor: themeDeff[dashTheme].c2,
    color: "#65708f",
    paddingLeft: "5px",
    paddingRight: "10px",
    fontSize: 15,
    fontWeight: "400",
    borderRadius: 25,
    letterSpacing: 0.2,
    marginLeft: "30px",
    height: 44,
    "& .MuiNativeSelect-icon": {
      color: "#65708f",
    },
    "& .MuiNativeSelect-iconFilled": {
      right: "12px"
    },
    "& .MuiNativeSelect-select:focus": {
      backgroundColor: themeDeff[dashTheme].c2
    },
    "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
      backgroundColor: themeDeff[dashTheme].c2,
      paddingLeft: "20px"
    },
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
      paddingLeft: "20px"
    },
    [theme.breakpoints.down(1200)]: {
      marginLeft: "20px",
      paddingLeft: "0px",
      paddingRight: "0px",
      "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
        paddingLeft: "18px",
        fontSize: "13px",
        height: "40px"
      },

    },
    [theme.breakpoints.down(1200)]: {
      height: "40px",
      fontSize: "13px",
      marginLeft: "20px",
      marginRight: "-7px",
      "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
        paddingLeft: "12px",
        paddingRight: "20px",
      },
      "& .MuiNativeSelect-iconFilled": {
        right: "8px"
      },
    },

  },
  myselect1: {
    width: "130px",
    [theme.breakpoints.down(1200)]: {
      width: "144px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom: "15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
  },
  myselect2: {
    width: "183px",
    [theme.breakpoints.down(1200)]: {
      width: "190px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom: "15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
  },
  myselect3: {
    width: "135px",
    [theme.breakpoints.down(1200)]: {
      width: "111px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom: "15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
  },
  myselect4: {
    width: "135px",
    [theme.breakpoints.down(1200)]: {
      width: "100px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom: "15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
  },
  myselect5: {
    backgroundColor: themeDeff[dashTheme].c2,
    color: "#65708f",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontSize: 15,
    fontWeight: "400",
    borderRadius: 25,
    letterSpacing: 0.2,
    marginLeft: "30px",
    height: 44,
    width: "135px",
    [theme.breakpoints.down(1200)]: {
      width: "120px",
      marginLeft: "20px",
      height: "40",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom: "15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
  },
  proxybtnn: {
    marginRight: "30px",
    [theme.breakpoints.down(1200)]: {
      paddingRight: "0px",
    },
    [theme.breakpoints.down(959)]: {
      padding: "20px 20px 0px",
    },
    [theme.breakpoints.down(767)]: {
      padding: "0px 20px 0px",
      marginRight: "0px"
    },
  },
  proxiess: {
    marginLeft: 20,
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
    color: "#cde8fd",
    fontSize: 13,
    fontWeight: "500",
    letterSpacing: 0.3,
    lineHeight: "44px",
    height: "44px",
    borderRadius: "40px",
    padding: "6px 25px",
    [theme.breakpoints.down(1200)]: {
      height: "40px",
      padding: "6px 20px"
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
    },
    [theme.breakpoints.down(450)]: {
      width: "89%",
	  marginBottom:"13px",
    },
  },
  proxytext: {
    color: "#217cfd",
    fontSize: 20,
    fontWeight: "500",
    letterSpacing: 0.3,
    fontFamily: "Poppins",
    padding: "21px 0px 18px",
    [theme.breakpoints.down(1200)]: {
      fontSize: 18,
    }
  },

  tblcell: {
    color: "#cde8fd",
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "Poppins",
    border: "none"
  },
  tblcell1: {
    color: "#3a4468",
    fontSize: 14,
    fontWeight: "400",
    fontFamily: "Poppins",
    border: "none",

  },

  paper: {
    maxWidth: "98%",
    boxShadow: "none",

  },
  Cardheader: {
    padding: "17px 30px 0px",
    "& .MuiTypography-h5": {
      fontSize: "20px",
      color: "#fff",
      marginTop: "10px",
      [theme.breakpoints.down(1200)]: {
        padding: "0",
        fontSize: "18px",
        margin: "0"
      },
    },
    [theme.breakpoints.down(1200)]: {
      padding: "17px 20px 0px",
    },
  },
  Cardheader2: {
    padding: "17px 30px 0px",
    "& .MuiTypography-h5": {
      fontSize: "20px",
      color: "#217cfd",
      marginTop: "10px",
      [theme.breakpoints.down(1200)]: {
        padding: "0px",
        margin: "0px",
        fontSize: "18px",
      },

    }
  },
  Cardheader1: {
    fontSize: "20px",
    marginTop: "10px",
    color: "#217cfd",
    letterSpacing: 1,
    fontFamily: "Poppins",
    [theme.breakpoints.down(1200)]: {
      fontSize: "18px",
      marginTop: "4px"
    },
    [theme.breakpoints.down(450)]: {
      marginTop: "0px",
      marginBottom: "15px"
    }
  },
  paper1: {
    maxWidth: "100%",
    boxShadow: "none",

  },
  btnnew: {
    backgroundColor: "#207003", height: 25, borderRadius: 25, color: "#fff", marginLeft: 5,
    textTransform: "none",
    fontSize: 14,
    letterSpacing: 0.4
  },
  cardd: {
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",

    marginTop: 4,
    borderRadius: "20px",
    "& .MuiCardContent-root": {
      padding: "20px 30px 15px"
    },
    [theme.breakpoints.down(1200)]: {
      "& .MuiCardContent-root": {
        padding: "16px 20px 15px"
      },
    }


  },
  cardd2: {
    backgroundColor: themeDeff[dashTheme].c3,
    marginTop: 4,
    borderRadius: "20px",
    "& .MuiCardContent-root": {
      padding: "20px 30px 15px"
    },
    [theme.breakpoints.down(1200)]: {
      "& .MuiCardContent-root": {
        padding: "16px 20px 15px"
      },
    }
  },
  cardcontentplan: {
    padding: "16px 30px",
    "& table": {
      borderCollapse: "collapse",
      border: themeDeff[dashTheme].c4,
      "& th": {
        border: themeDeff[dashTheme].c4,
      },
      "& td": {
        border: themeDeff[dashTheme].c4,
      },
    },
    [theme.breakpoints.down(1200)]: {
      padding: themeDeff[dashTheme].c7,
    },
  },
  cardplan: {
    backgroundColor: themeDeff[dashTheme].c5,
    marginTop: "0px",
    height: 350,
    borderRadius: "20px",
    height: "calc(100% - 0px)",
    overflow: "auto",
    "& .MuiTableContainer": {
      background: "none !important"
    },
    "& .MuiPaper-root": {
      background: "none !important"
    },

    [theme.breakpoints.down(1200)]: {
      height: "calc(100% - 20px)",
    },
  },
  packgetext: {
    color: "#1f2f47",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "poppins",
    [theme.breakpoints.down(1200)]: {
      fontSize: 15,
    }
  },
  packgetext1: {
    color: "#3f4a6b",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "poppins",
    [theme.breakpoints.down(1200)]: {
      fontSize: 15,
    }
  },
  ambitiontext: {
    color: "#cae2fe",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "Poppins",
    marginBottom: 10,
    [theme.breakpoints.down(1200)]: {
      fontSize: 15,
      marginBottom: 5,
      marginTop: 0,
    }
  },
  ambitiontext1: {
    color: "#7f8292",
    fontSize: 16,
    fontWeight: "400",
    fontFamily: "Poppins",
    marginBottom: 10,
    [theme.breakpoints.down(1200)]: {
      marginBottom: 5,
      fontSize: 15,
    }
  },
  ambitiontext2: {
    color: "#7f8292",
    fontSize: 13,
    fontWeight: "300",
    fontFamily: "Poppins",
    marginBottom: "2px",
    whiteSpace: "nowrap"
  },
  lightfooter: {

    backgroundColor: "#e2e3e9 ! important"


  },
  tableheadplan: {
    backgroundColor: "#020e4a",
    borderRadius: "20px"
  },
  tableheadplan2: {
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",

    borderRadius: "20px"
  },
  tblbodyplan: {
    backgroundColor: themeDeff[dashTheme].c8
  },
  paperclass: {
    backgroundColor: themeDeff[dashTheme].c3,
    width: "100%",
    padding: "0px 30px",
    marginTop: "4px",
    borderRadius: "18px",
    position: "relative",
    boxShadow: "none",
    height: "calc(100% - 0px)",
    [theme.breakpoints.down(1200)]: {
      padding: "0px 20px",
    },
  },
  copybtn: {
    borderTop: themeDeff[dashTheme].c9,
    "& .MuiIconButton-root": {
      margin: "10px 0px 10px 10px",
      padding: "8px",
      float: "right",
      border: "1px solid #18a0fb",
      "& svg": {
        fontSize: "17px"
      }
    }
  },
  generateproxy: {
    borderTop: themeDeff[dashTheme].c6,
    padding: "22px 0px 0",
    height: "calc(100% - 126px)",
    position: "relative",
    overflow: "auto",
    [theme.breakpoints.down(1200)]: {
      height: "540px"
    },
    [theme.breakpoints.down(959)]: {
      height: "320px"
    }
  },
  inputPassword: {
    background: "#f1f2f7",
    borderRadius: 8,
    borderBottom: "none",
    fontSize: 14,
    fontWeight: "500",
    boxShadow: "none",
    position: "relative",
    paddingLeft: 10,
    padding: "2px",
    height: 43,
  },
  applybox1: {
    backgroundColor: colors.skyblue,
    height: 44,
    width: "35%",
    color: colors.white,
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    letterSpacing: 1,
    [theme.breakpoints.down(599)]: {
      width: "35%"
    }
  },
  cardheaderbox: {
    justifyContent: "space-between", display: "flex", padding: "20px 30px 4px",
    [theme.breakpoints.down(1200)]: {
      padding: "20px 20px 4px",
    },
    [theme.breakpoints.down(450)]: {
      display: "block"
    }
  },
  proxybox: {
    padding: "20px 0px 0px",
    [theme.breakpoints.down(1200)]: {
      padding: "15px 0px 0px",
    }
  },
  darkfooter: {
    backgroundColor: "#00051b",
  },
  selectOut: {
    display: "flex",
    paddingTop: "20px",
    [theme.breakpoints.down(959)]: {
      display: "block"
    }
  },
  loginFooter: {
    position: "fixed",
    width: "100%",
    bottom: "0",
    left: "0",
    [theme.breakpoints.down(1200)]: {
      position: "static",
    },
  },
  paddingInn: {
    padding: "16px 16px 0px !important",
  },
  copyTextMsg: {
    color: "red",
    float: "right",
    paddingTop: "8px",
    margin: "10px 0px 10px 10px",
  },
  input1: {
    background: "#f4f3f8",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    padding: "5px",
    display: "flex",
    paddingLeft: 10,
    [theme.breakpoints.down(1200)]: {
      width: "100%"
    },
  },
  mydialog: {
    background: "#1872ff",
    width: "600px",

    padding: "20px 25px",
    "& h2.MuiTypography-h6": {
      color: "#fff",
      marginBottom: "0px", fontSize: "22px",
    }

  },
  myAuthIpdialog: {
    background: "#1872ff",
    display:"flex",
	justifyContent:"space-between",
    paddingTop: "20px",
    paddingBottom: "20px",
    "& h2.MuiTypography-h6": {
      color: "#fff",
      marginBottom: "0px", fontSize: "22px",
    }

  },
  dialogpaper: {

    "& .MuiPaper-rounded ": {
      borderRadius: "20px"
    }
  },
  dialogcontnet: {
    padding: "5px 25px",
    minHeight: 150
  },
  dialogaction: {
    padding: "10px 17px",
    backgroundColor: "#f9f9f9"
  },
  btntext: {
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 7,
    border: "1px solid",
    textTransform: "none",
    padding: "7px 15px",
  },
  autonewaltext1: {
    paddingTop: 10,
    paddingRight: "0",
    fontSize: 15,
    color: colors.skyblue,
    [theme.breakpoints.down(599)]: {
      letterSpacing: "0.1px",
      fontSize: 14,
    },
  },

}));


function Dashboard(props) {
  let id = user_id();
  const [shareProxyEnabled, setShareProxyEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  if (props.match.params.id && props.match.params.id > 0) {
    if (isAdmin() || isSupport()) {
      id = props.match.params.id;
    }
  }
  const [copyProxies, setCopyProxies] = React.useState(false);
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");

  function showMessage(newMessage, newSeverity) {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------

  const classes = useStyles();
  const history = useHistory();
  const planStatus = ["Expired", "Active", "Standby"];
  let [userPlans, setUserPlans] = React.useState([]);
  let [activePlan, setActivePlan] = React.useState({});
  let [standByPlan, setStandByPlan] = React.useState({});
  let [proxies, setProxies] = React.useState([]);
  let [renew, setRenew] = React.useState(false);
  let [userRole, setUserRole] = React.useState(2);
  const [allContries, setAllContries] = React.useState({});
  const [countryList, setCountryList] = React.useState([]);
  const endpointList = [
    { value: "", name: "Network" },
    { value: "private-release-proxies", name: "Private Release Proxies" },
    { value: "daily-shared-proxies", name: "Booster Proxies" }
  ];

  const blankObj = {
    user_id: id,
    provider_id: 1,
    type: "",
    region: "",
    country: "",
    qty: "",
    active_plan_id: 0
  }
  const [formObj, setFormObj] = React.useState(blankObj)
  const inputChange = (e) => {
    setFormObj({ ...formObj, [e.target.name]: e.target.value });
    if (e.target.name == "region" && e.target.value != "") {
      setCountryList(allContries[e.target.value]);
    }
    else if (e.target.name == "region") {
      setCountryList([]);
    }
  }
  const generateProxy = () => {
    setSeverity("error");
	
    if (formObj.region == "") {	
      showMessage("Please choose a network!", "error");
    }
    else if (formObj.country == "") {
      showMessage("Please choose a country!", "error");
    }
    else if (formObj.type == "" || (formObj.region === "private-release-proxies" && formObj.type !== "static")) {
      showMessage("Please choose a proxy type!", "error");
    }
    else if (formObj.qty == "") {
      showMessage("Please Enter quantity", "error");
    }
    else {
      formObj.active_plan_id = activePlan.id;
      axios.get(getDomain() + "/generate-proxy", { params: formObj, headers: header() })
        .then(function (response) {
          console.log(response.data.proxies);
          if (response.data.error) {
            showMessage(response.data.error, "error");
          }
          else if (response.data.proxies.length) {
            setProxies(response.data.proxies);
            setCopyProxies(response.data.proxies);
          }
        })
        .catch(function (error) {
          console.log(error);
          showMessage("Something went wrong, please try again later.", "error");
          setCopyProxies(null);
        })
    }

  }
  useEffect(() => {
    axios.get(getDomain() + "/dashboard?id=" + id, {
      headers: header()
    })
      .then(function (response) {
        console.log(response.data);
        setUserPlans(response.data.userPlans);
        setActivePlan(response.data.activePlan);
        setStandByPlan(response.data.standByPlan);
        console.log(response.data.userPlans);
        setRenew(response.data.allow_renew);
        setUserRole(response.data.user_role)
        setShareProxyEnabled(response.data.share_proxy_enabled);
		setRows(response.data.ips); 
        setLoading(false);		
        // console.log(response.data);
      })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 401) {
          logout();
          window.location.reload();
        }
      });
	  // axios.get(getDomain()+'/get-auth-ips',{
        // headers : header()
      // })
      // .then(function(response){
		  // if(response.data.ips && response.data.ips.length>0)
		  // {
			  // setRows(response.data.ips); 
		  // }
		  // else
		  // {
			   // setRows([]);
		  // }
       
        // //console.log(response.data.ips);
      // })
      // .catch(function(error){
        // console.log(error);
        // if(error.response.status===401)
        // {
          // logout();
          // window.location.reload();
        // }
        // //showMessage("Something is went wrong, please try after sometime","error");
      // });
	  
	 axios.get(getDomain() + "/countries/dashboard")
      .then(function (response) {
        console.log(response.data);
        setAllContries(response.data);
      })
      .catch(function (error) {
        console.log(error);
      })  
	  
	if (user_message() && user_severity()) {
	showMessage(user_message(), user_severity())
	delete_info()
	}
	
  }, []);
  

  const copyText = () => {
    if (copyProxies) {
      let text = copyProxies.join("\n");
      copy(text);
      showMessage(copyProxies.length + " Proxies Copied Successfully", "success");
    }
    else {
      showMessage("no ips", "error");
    }
  }

  const downloadTxtFile = () => {
    if (copyProxies) {
      const element = document.createElement("a");
      const file = new Blob([copyProxies.join("\n")],
        { type: 'text/plain;charset=utf-8' });
      element.href = URL.createObjectURL(file);
      element.download = "proxies.txt";
      document.body.appendChild(element);
      element.click();
    }
    else {
      showMessage("no ips", "error");
    }

  }
  const emptyObj = {
    user_id: "",
    sub_plan_id: "",
  }
  const [planFormData, setPlanFormData] = React.useState(emptyObj);
  const [PopUpMessage, setPopUpMessage] = React.useState("");
  const [openPlanPopUp, setopenPlanPopUp] = React.useState(false);
  const [openAuthIpModel, setModelAuthIpOpen] = React.useState(false);
  const [allPlans, setAllPlansData] = React.useState([]);
  const [couponName, setCouponName] = React.useState("");
  const [couponApply, setCouponApply] = React.useState("");
  const [paymentAmount, setPaymentAmount] = React.useState("");
  const [planMethod, setPlanMethod] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [authIpName, setAuthIpName] = React.useState("");
  
  const handleModelAuthIpOpen = () => {
	setAuthIpName("");  
    setModelAuthIpOpen(true);
  };
  
  const addMoreAuthIp = () =>{
	let ip = authIpName;
	let objArr = rows.length > 0?[...rows]:[];
	const ipFormat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
	if(ip !== ""){
	   if(objArr.length === 3){
		 showMessage("You can use up to 3 different instance IPs at a time. !","error"); 
         return false;		 
	   }else{
		if(ipFormat.test(ip)){
		  objArr.push(ip);
		}else{
		  showMessage("Please enter a valid IP!","error");
		  return false;
		}
	   }	
	}
	if(objArr.length===0 && ip === "")
	{
		showMessage("Please enter a valid IP!","error");
		  return false;
	}
	axios.post(getDomain()+"/add-auth-ips?id=" + id,{ips:objArr},{
		headers : header()
	  })
	.then(function(response){
		console.log(response);
		if(response.status && response.data.ips)
		{
			setRows(response.data.ips);
			showMessage("IP updated","success");
			setAuthIpName(""); 
		}
		else if(!response.status)
		{
			showMessage(response.message,"error");
		}
	})
	.catch(function (error) {
		console.log(error);
		showMessage("Something is went wrong, please try after sometime","error");
	});
  }
  
  const authIpUpdate = (e) => {
    console.log(e.target.value, e.target.name);
    setAuthIpName(e.target.value);
  };
  
  const deleteAuthIpOption = (index) => {
    let objArr = rows.filter((value, i) => i !== index);
    setRows(objArr);
	//showMessage("IP deleted", "success");
  }
    
  const handleCloseAuthIpPopUp = () => {
    setModelAuthIpOpen(false);
  };
  
  const handleClickopenPlanPopUp = () => {
    setPlanMethod("renew");
    setopenPlanPopUp(true);
    setPopUpMessage("Loading.......");
    axios.get(getDomain() + "/plan-list", { headers: header() })
      .then(function (response) {
        // let id = user_id();
        setPopUpMessage("");
        setAllPlansData(response.data);
        setPlanFormData({ ...planFormData, user_id: id })
      })
      .catch(function (error) {
        console.log(error);
        setPlanFormData(emptyObj)
        showMessage("Something went wrong, please try again later.", "error");
      });

  };
  const handleClickopenUnlimitedPopUp = () => {
    setPlanMethod("unlimited");
    setopenPlanPopUp(true);
    setPopUpMessage("Loading.......");
    axios.get(getDomain() + "/plan-list", { params: { type: '-1' }, headers: header() })
      .then(function (response) {
        // let id = user_id();
        setPopUpMessage("");
        setAllPlansData(response.data);
        setPlanFormData({ ...planFormData, user_id: id })
      })
      .catch(function (error) {
        console.log(error);
        setPlanFormData(emptyObj)
        showMessage("Something went wrong, please try again later.", "error");
      });

  };

  const handleClosePlanPopUp = () => {
    setopenPlanPopUp(false);
    setPlanFormData(emptyObj);
  };
  const eventHandler = (e) => {
    setCouponApply("");
    setPlanFormData({ ...planFormData, coupon: "" });
    setPlanFormData({ ...planFormData, sub_plan_id: e.target.value });
  }

  const addPlanSubmit = () => {
    if (planFormData.sub_plan_id != "") {
      setPopUpMessage("Sending data,Please wait... ");
      // let params = "?plan_price_id=" + planFormData.sub_plan_id + "&user_id=" + planFormData.user_id + "&method=" + planMethod + "&coupon=" + planFormData.coupon;
      // console.log(params);
      // window.location.href = getDomain() + "/buy-plan" + params;
	  
	  axios
        .post(getDomain() + "/buy-plan",{ plan_price_id: planFormData.sub_plan_id,user_id: planFormData.user_id,method:planMethod,coupon: planFormData.coupon},{
           headers: header(),
         })
        .then(function (response) {
         // console.log(response.data.data.stripe_key,2222222222);
          if (response.data.status === true) {
			if(response.data.type === "stripe"){
				let stripe = window.Stripe(response.data.data.stripe_key);  
				stripe.redirectToCheckout({
				  sessionId: response.data.data.session_id,
				 })
				.then(function (result) {
				   if (result.error) {
				   alert(result.error);
				   }
				});	
		   }else if(response.data.type === "paypal"){
			    window.location.href = response.data.payurl;
		   }
          }
		  else
		  {
			showMessage(response.data.message, "error");  
		  }
        })
        .catch(function (error) {
       //  console.log(error,22222222);
          showMessage("Something went wrong, please try again later.", "error");
        });
		
    }
    else {
      showMessage("Please select a plan", "error");
    }
  }
  const couponUpdate = (e) => {
    console.log(e.target.value, e.target.name);
    setCouponName(e.target.value);
  };
  const applyCoupon = (e) => {
    let coupon = couponName;
    console.log(coupon);
    if (coupon !== "") {
      axios.post(getDomain() + '/coupon-check', { coupon: coupon, sub_plan_id: planFormData.sub_plan_id },{
          headers: header(),
         })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status === true) {
            setPlanFormData({ ...planFormData, coupon: coupon });
            setCouponApply(response.data.amount);
            setPaymentAmount(response.data.payment_amount);
            showMessage("Coupon Applied", "success");
          }
          else {
            showMessage("Please Enter valid coupon", "error");
          }
        })
        .catch(function (error) {
          console.log(error);
          showMessage("Something went wrong, please try again later.", "error");
        })
    }
    else {
      showMessage("Please Enter coupon", "error");
    }

  };
  const deleteCoupon = (e) => {
    setCouponApply("");
    setPlanFormData({ ...planFormData, coupon: "" });
  };

  return (
    <>
      <CustomAlert open={open} severity={severity} message={message} handleClose={handleClose} />
      <div className={classes.loginWrapper}>
        <DashboardTop />
        <div className={classes.drawerHeader} />
        <main className={classes.content} >

          <Box className={classes.selectOut} justifyContent="space-between">
            <Box>
              {/* 
                        <NativeSelect
                              name="provider_id"
                              value={formObj.provider_id}
                              onChange={inputChange}
                              variant="filled"
                             
                              className={clsx(classes.nativeslect,classes.myselect1)}
          disableUnderline
        >
          <option value="1">Api Provider</option>
                          </NativeSelect>
                          */}
              {
              loading?
              <Button className={clsx(classes.nativeslect, classes.myselect2)} disabled>
                <CircularProgress style={{color: "#65708f"}} />
              </Button>
              :
              <NativeSelect
                name="region"
                value={formObj.region}
                onChange={inputChange}
                variant="filled"
                className={clsx(classes.nativeslect, classes.myselect2)}
                disableUnderline
              >
                {endpointList.map((e, key) => {
                  if(e.value === 'daily-shared-proxies' && !shareProxyEnabled) {
                    return <option key={key} value={e.value} disabled>{e.name}</option>;
                  }
                  return <option key={key} value={e.value}>{e.name}</option>;
                })}
              </NativeSelect>              
            }
              
              <NativeSelect
                name="type"
                value={formObj.type}
                onChange={inputChange}
                variant="filled"
                className={clsx(classes.nativeslect, classes.myselect4)}
                disableUnderline
              >
                <option value="">Type</option>
                <option value="static">Static</option>
              </NativeSelect>
              <NativeSelect
                name="country"
                value={formObj.country}
                onChange={inputChange}
                variant="filled"

                className={clsx(classes.nativeslect, classes.myselect3)}
                disableUnderline
              >
                <option value="">Country</option>
                {countryList.map((e, key) => {
                  return <option key={key} value={e.code}>{e.name}</option>;
                })}
              </NativeSelect>
              <Input
                type="number"
                name="qty"
                value={formObj.qty}
                placeholder="Quantity"
                onChange={inputChange}
                disableUnderline
                inputProps={{ "aria-label": "description" }}
                className={classes.myselect5}
              />
              <Button variant="contained" className={classes.proxiess} onClick={generateProxy}>
                Generate Proxies
              </Button>
            </Box>
			 { userRole == 1 ?
              <Button variant="contained" style={{marginRight:"25px"}} className={classes.proxiess} onClick={handleModelAuthIpOpen}>
                IP AUTHORIZATION
              </Button> :"" } 
          </Box>

          <Container className={classes.container1}>

            <Grid container spacing={4} className={classes.leftCont}>
              <Grid item xs={12} md={6} lg={6} className={classes.leftContScroll}>
                <Paper className={classes.paperclass}>
                  <Typography className={classes.proxytext}>Generated Proxies</Typography>
                  <Box className={classes.generateproxy}>
                    {proxies.map((e) => {
                      return <div className={classes.ambitiontext2}>{e}</div>;
                    })}


                  </Box>
                  <Box className={classes.copybtn}>
                    {/* <IconButton color="secondary" onClick={ copyText } className={classes.copybtn}><LibraryBooksIcon/></IconButton>*/}
                    <IconButton color="secondary" onClick={downloadTxtFile} className={classes.copybtn}><ExitToAppIcon /></IconButton>
                    <div className={classes.copyTextMsg}>PLEASE EXPORT/COPY PROXIES AND SAVE THEM</div>
                  </Box>
                </Paper>
              </Grid>



              <Grid item xs={12} md={6} lg={6} className={classes.rightCont}>
                <Grid container spacing={4} className={classes.rightContScroll}>
                  <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Card className={classes.cardd}>
                      <CardHeader
                        title="Active Plan"
                        className={classes.Cardheader}
                        titleTypographyProps={{ align: 'left' }} />
                      <CardContent>
                        <Box className={classes.proxybox} borderTop="1px dashed #fff">
                          <Typography className={classes.packgetext}>Plan Name</Typography>
                          <Typography className={classes.ambitiontext}>{activePlan.title ? activePlan.title : "NA"}</Typography>
                          <Typography className={classes.packgetext}>Start Date</Typography>
                          <Typography className={classes.ambitiontext}>{activePlan.plan_active_date ? activePlan.plan_active_date : "NA"}</Typography>
                          <Typography className={classes.packgetext}>End Date</Typography>
                          <Typography className={classes.ambitiontext}>{activePlan.plan_expired_date ? activePlan.plan_expired_date : "NA"}</Typography>
                          <Typography className={classes.packgetext}>Remaining Data</Typography>
                          <Typography className={classes.ambitiontext}>{activePlan.data == 0 ? 'UNLIMITED' : ((activePlan.data - activePlan.data_used) ? (activePlan.data - activePlan.data_used).toFixed(2) + " GB" : "0 GB")}</Typography>
                        </Box>
                      </CardContent>
                    </Card>

                  </Grid>
                  <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Card className={classes.cardd2}>
                      <CardHeader
                        title="Standby Plan"
                        titleTypographyProps={{ align: 'left' }}
                        className={classes.Cardheader2}
                      />
                      <CardContent>
                        <Box className={classes.proxybox} borderTop={dashTheme ? "1px dashed #3f4a6b" : "1px dashed #707ca0"}>
                          <Typography className={classes.packgetext1}>Plan Name</Typography>
                          <Typography className={classes.ambitiontext1}>{standByPlan.title ? standByPlan.title : "NA"}</Typography>
                          <Typography className={classes.packgetext1}>Start Date</Typography>
                          <Typography className={classes.ambitiontext1}>{standByPlan.plan_active_date ? standByPlan.plan_active_date : "NA"}</Typography>
                          <Typography className={classes.packgetext1}>End Date</Typography>
                          <Typography className={classes.ambitiontext1}>{standByPlan.plan_expired_date ? standByPlan.plan_expired_date : "NA"}</Typography>
                          <Typography className={classes.packgetext1}>Remaining Data</Typography>
                          <Typography className={classes.ambitiontext1}>{standByPlan.data == 0 ? 'UNLIMITED' : ((standByPlan.data - standByPlan.data_used) ? (standByPlan.data - standByPlan.data_used).toFixed(2) + " GB" : "0 GB")}</Typography>

                        </Box>
                      </CardContent>
                    </Card>

                  </Grid>
                  <Grid item xs={12} md={12} lg={12} sm={12} className={classes.paddingInn}>
                    <Card className={classes.cardplan}>
                      <Box className={classes.cardheaderbox}>
                        <Typography className={classes.Cardheader1}>Order History</Typography>
                        <Grid item justify="flex-end">
                          {userRole == 4 || userRole == 1 || userRole == 3 ?
                            (<Button
                              className={classes.proxiess}
                              tooltip="Unlimited Plan"
                              onClick={handleClickopenUnlimitedPopUp}
                            >
                              Unlimited Plan
                            </Button>) : ""
                          }
                          {renew ? (
                            <Button
                              className={classes.proxiess}
                              tooltip="Add Plan"
                              onClick={handleClickopenPlanPopUp}

                            >
                              Renew Plan
                            </Button>) : (<Button variant="contained" href="/pricing" className={classes.proxiess}>
                              Buy Plan
                            </Button>)
                          }
                        </Grid>

                      </Box>


                      <CardContent className={classes.cardcontentplan}>
                        <Box paddingTop="17px" borderTop={dashTheme ? "1px dashed #3f4a6b" : "1px dashed #707ca0"}>
                          <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                              <TableHead className={dashTheme == 0 ? classes.tableheadplan : classes.tableheadplan2}>
                                <TableRow >
                                  <TableCell align="left" className={classes.tblcell}>Plan Name</TableCell>
                                  <TableCell align="left" className={classes.tblcell}>Duration</TableCell>
                                  <TableCell align="left" className={classes.tblcell}>Data/IP</TableCell>
                                  <TableCell align="left" className={classes.tblcell}>Purchase Date</TableCell>
                                  <TableCell align="left" className={classes.tblcell}>Plan Start Date</TableCell>
                                  <TableCell align="left" className={classes.tblcell}>Exp Date</TableCell>
                                  <TableCell align="left" className={classes.tblcell}>Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody className={classes.tblbodyplan}>
                                {userPlans.map((plan) => (
                                  <TableRow key={plan.id}>
                                    <TableCell align="left" className={classes.tblcell1}>{plan.title}</TableCell>
                                    <TableCell align="left" className={classes.tblcell1}>{plan.date_diff}</TableCell>
                                    <TableCell align="left" className={classes.tblcell1}>{plan.data == 0 ? 'UNLIMITED' : plan.data} / {plan.no_proxy}</TableCell>
                                    <TableCell align="left" className={classes.tblcell1}>{plan.purchase_date}</TableCell>
                                    <TableCell align="left" className={classes.tblcell1}>{plan.plan_active_date}</TableCell>
                                    <TableCell align="left" className={classes.tblcell1}>{plan.plan_expired_date}</TableCell>
                                    <TableCell align="left" className={classes.tblcell1}>{planStatus[plan.status]}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


          </Container>
          <div className={classes.loginFooter}>
            <div className={dashTheme == 0 ? classes.darkfooter : classes.lightfooter}>
              <DashboardFooter />
            </div>
          </div>
          <Dialog
            open={openPlanPopUp}
            onClose={handleClosePlanPopUp}
            className={classes.dialogpaper}

          >
            <DialogTitle className={classes.mydialog}>Add Plan</DialogTitle>
            <DialogContent className={classes.dialogcontnet}>
              {allPlans.length > 0 && PopUpMessage == "" ?
                <DialogContentText >
                  <Box marginTop={2} justifyContent="space-between" display="flex">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2" marginBottom={5}>Plan</Typography>

                        <NativeSelect
                          id="demo-customized-select-native"
                          name="add-plan"
                          disableUnderline
                          value={planFormData.sub_plan_id}
                          className={classes.input1}
                          onChange={eventHandler}
                        >
                          <option key="0" value="0">
                            Select Plan
                          </option>
                          {allPlans.map(plan => {
                            return (
                              <optgroup key={plan.id} label={plan.title}>
                                {plan.options.map(opt => {
                                  return (
                                    <option key={opt.id} value={opt.id}>
                                      ${opt.plan_price}-{(opt.plan_data) == 0 ? "UNLIMITED" : opt.plan_data + " GB"}  /  {opt.no_proxy} IPS |  {opt.validity} Days
                                    </option>
                                  )
                                })}
                              </optgroup>
                            )
                          })}
                        </NativeSelect>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h2" marginBottom={5}>Coupon Code</Typography>
                        <Box display="flex">
                          <Input
                            type="text"
                            name="coupon"
                            disableUnderline
                            fullWidth
                            className={classes.inputPassword}
                            inputProps={{ "aria-label": "description" }}
                            onChange={couponUpdate}
                          />

                          {couponApply ? <IconButton onClick={(e) => deleteCoupon()} style={{ padding: "0px 0px 0px 2px" }}>
                            <DeleteIcon size="large" style={{ color: colors.skyblue, fontSize: 30 }} />
                          </IconButton> : <Button variant="contained" className={classes.applybox1} disabled={(planFormData.sub_plan_id == '') ? true : false}
                            onClick={(e) => applyCoupon()}
                          >Apply</Button>}

                        </Box>
                      </Grid>
                      {couponApply
                        ?
                        <Typography variant="h5" className={classes.autonewaltext1}>
                          Coupon Applied {couponApply}% [Amount= {paymentAmount}]
                        </Typography>
                        :
                        ""
                      }
                    </Grid>

                  </Box>
                  <Box marginTop={2} justifyContent="space-between" display="flex">
                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.btntext}
                        size="small"
                        onClick={addPlanSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.btntext}
                        size="small"
                        onClick={handleClosePlanPopUp}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </DialogContentText>
                : <Typography variant="h2" style={{ marginTop: 70 }}>{PopUpMessage}</Typography>}
            </DialogContent>
            <DialogActions className={classes.dialogaction}>

            </DialogActions>
          </Dialog>
		  
		<Dialog open={openAuthIpModel} className={classes.dialogpaper}>
		<form noValidate autoComplete="off">
            <div className={classes.myAuthIpdialog}>
				<DialogTitle> IP AUTHORIZATION </DialogTitle>
				<IconButton aria-label="Close" style={{color:"#fff"}} onClick={handleCloseAuthIpPopUp} >
				  <CloseIcon />
				</IconButton>
            </div>
          <DialogContent className={classes.dialogcontnet}>
            {
              <DialogContentText>
				 <Box
                  marginTop={2}
                  display="flex"
                >
                      <Input
                        type="text"
                        name="auth_ip"
						placeholder="Please enter your instance IP address"
                        disableUnderline
                        className={classes.inputPassword}
						onChange={authIpUpdate}
						value={authIpName}
						style={{width:"100%"}}
                      />
                </Box>
                <Box borderTop="1px solid #8186B7" marginTop={1}>
				<TableContainer>
				 <Table size="small" aria-label="a dense table">
				  <TableHead>
					<TableRow >
					  <TableCell align="left">IP</TableCell>
					  <TableCell align="left">Action</TableCell>
					</TableRow>
				  </TableHead>
				  <TableBody>
					{rows.map((e, key) => (
					  <TableRow>
						<TableCell align="left">{e}</TableCell>
						<TableCell align="left">
						<DeleteIcon
                            size="small"
                           className={classes.iconcolor}
                           onClick={() => {
                            deleteAuthIpOption(key)
                           }}
						   cursor="pointer"
                          />
						  </TableCell>
					  </TableRow>
					))}
				  </TableBody>
				</Table>
			    </TableContainer>
				</Box>
			   <Box
                  marginTop={2}
                  justifyContent="space-between"
                  display="flex"
                >
					<Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      size="small"
                      onClick={addMoreAuthIp}
                    >
                      Update
                    </Button>
					<Typography  style={{marginLeft:"15px",color:"#000"}}>You can use up to 3 different instance IPs at a time.</Typography>
                </Box>
              </DialogContentText>
            }
          </DialogContent>
		  </form>
        </Dialog>
		  
        </main>
      </div>

    </>
  );
}
export default Dashboard;
