const colors = {
    skyblue: "#166bfa",
    white: "#fff",
    darkBlue: "#01092d",
    lightblue: "#65708f",
    lightgray: "#838da7",
    skyblue1: "#0059fd",
    skyblue2:"#1771ef",
    mainColor: "#151948",
    green: "#30ac00",
    lightpurple: "#60698a",
    accent: "#dbdce2",
    lightpurple1: "#5e6c91",
    whitelight: "#c8cad7",
    lightblue1: "#151948",
  lightblue2: "#959db6",
  mediumblue: "#000c40",
  whitemed:"#dad2d1"
  
 
  };

  
  export { colors, };