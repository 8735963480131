import React from "react";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import image1 from '../../assets/images/innerbanner.jpg';
//import image2 from '../../assets/images/banner-img.png';
import video1 from '../../assets/images/PL_06.mp4'; 
import AOS from "aos";
import "aos/dist/aos.css";
import ReactPlayer from "react-player";
const useStyles = makeStyles((theme) => ({
 mainFeaturedContent: {
     padding:"135px 0px 50px 0px",
     [theme.breakpoints.down(959)]: {
      padding:"75px 0px 30px 0px",
      textAlign:"center",
      "& b":{
        fontWeight:500, 
      }
    },
    [theme.breakpoints.down(599)]: {
      padding:"45px 0px 0px 0px",
      textAlign:"center",
     },
},
  mainHeading:{
  [theme.breakpoints.down(959)]: {
    fontWeight:500, 
  },
  [theme.breakpoints.down(599)]: {
    fontSize:"23px",
    lineHeight:"normal",
    letterSpacing:"0.2px"
  },
  [theme.breakpoints.down(479)]: {
    fontSize:"19px",
    letterSpacing:"0.2px"
  },
},
mainImagebanner:{
  margin:"30px 0",
  [theme.breakpoints.down(959)]: {
    display:"block",
    textAlign:"center",
    width:"100%" 
  },
  
},
reactPlayer:{
   width:"400px",
   maxWidth:"100%",
},

 getsartedbutton: {
    borderRadius: "40px",
    border: "2px solid #1870ff",
    color: "#1870ff",
    letterSpacing: 0.6,
    fontWeight: "600",
    fontSize: 16,
    marginTop: 40,
    padding: "10px 25px",
    [theme.breakpoints.down(599)]: {
      fontSize: "15px",
      marginTop: "25px",
      padding: "8px 20px",
      fontWeight: "400",
    } 
    },
   imageSrc: {
    height: "auto",
     width: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  image2: {
    height: "auto",
    width:"600px",
    maxWidth: "100%",
    
   }
}));
function HomeContent1() {
    const history = useHistory();
    const classes = useStyles();
    AOS.init();
    return (
       <div className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image1})`,
            }}>
            <Container maxWidth="lg" width="1200px" margin="0px auto">
     <Grid container>
        <Grid item md={6} data-aos="zoom-in" data-aos-duration="700">
          <div className={classes.mainFeaturedContent}>
              <Typography
                variant="h3"  gutterBottom className={classes.mainHeading}>
             We Combined the Quality of Residential Proxies With <b style={{color:"#1772ff"}}>STABILITY</b> and <b style={{color:"#1772ff"}}>SPEED</b>
            </Typography>
           
                <Button variant="outlined" onClick={() => history.push("/pricing")} className={classes.getsartedbutton}>Get Started Now</Button>
           
          </div>
          </Grid>
           <Grid item md={6} className={classes.mainImagebanner}>
            <ReactPlayer className='reactPlayer' 
             
              //style={ {"width": "auto !important", "height": "auto !important"} }
              width="auto !important"
              height="auto !important"
              url={"https://liveproxies.io"+video1}  
loop={true}
playing={true}
controls={false}
                muted             
               />
            </Grid>
          </Grid>
          </Container>
            </div>
  );
}

export default HomeContent1;
