import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Container } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import AOS from "aos";
import "aos/dist/aos.css";
import dividerimage from '../../assets/images/divider.png'

const useStyles = makeStyles((theme) => ({
    root1: {
     backgroundColor:"#010c39",
     paddingBottom:"50px"
    },
    logoimage: {
        height: "60px",
        width: 60,
        opacity: 0.6,
        marginTop: "29px"
        
    },
  
    card1: {
        backgroundColor: "#010c39",
        boxShadow: "none",
         padding:"10px",
         "&:hover": {
                 background: "rgb(23,111,254)",
                 background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
                 background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
                 background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
                filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
          borderRadius: "25px", 
             "& .makeStyles-iconimg-43": {
              border:"3px solid #fff",
              backgroundColor: "#189dfd",
             },
             "& .makeStyles-subheading1-37": {
                  color: "#f9fbfe",
             },
             
             "& .makeStyles-description-38": {
                 color: "#fff",
             },
             
           "& a.makeStyles-link-40": {
               color:"#fff !important",
               cursor:"pointer",
           },
           "& .makeStyles-logoimage-34": {
             opacity:1
           }
        
        }
         
    },
    subheading: {
      fontSize: 24,
      fontWeight: "500",
      color: "#f9fbfe",
      letterSpacing: 1,
      marginTop:20,
      [theme.breakpoints.down(959)]: {
        fontSize: 22,
      },
      [theme.breakpoints.down(599)]: {
        fontSize: 19,
        letterSpacing:"0.2px"
      }
    },
  subheading1: {
      fontSize: 24,
      fontWeight: "500",
      color: "#b7b8ce",
      letterSpacing: 1, 
      marginTop: 20,
      [theme.breakpoints.down(959)]: {
        fontSize: 22,
      },
      [theme.breakpoints.down(599)]: {
        fontSize: 19,
        letterSpacing:"0.2px"
      }   
      
    },
    description: {
      fontSize: 15,
      fontWeight: "400",
      color: "#515b79",
      letterSpacing: 1,
      marginBottom: 3,
      fontFamily: "Poppins",      
      [theme.breakpoints.down(599)]: {
        letterSpacing: "0.1px",
        fontSize: 14,
      }  
    },
      description1: {
      fontSize: 14,
      fontWeight: "500",
      color: "#bad1ee",
      letterSpacing: 1,
        marginBottom: 3,
      fontFamily:"Poppins",
      [theme.breakpoints.down(599)]: {
        letterSpacing: "0.1px",
        fontSize: 14,
      } 

      
    },
     link: {
         textDecoration: "none",
         color: "#1157c9",
         padding: "17px 0px 0px",
          display: "block"
        
         
    },
    dividerimg: {
        height: 20,
        width: "auto",
        marginTop: "30px",
        [theme.breakpoints.down(959)]: {
          marginTop: "15px",
          height: "17px",
        } ,
        [theme.breakpoints.down(599)]: {
          marginTop: "11px",
          height: "12px",
        }     
     },
    contents: {
    padding: "30px !important",
    textAlign: "center",
    minHeight: "370px",
    [theme.breakpoints.down(599)]: {
      padding: "20px 5px 12px !important",
      minHeight: "100px",
     } 
     },
    iconimg: {
    width: "130px",
    display: "inline-block",
    height: "130px",
    border:"3px solid #01092e",
    lineHeight: "184px",
    textAlign:"center",
    borderRadius: "50%",
    margin: "0px",
    backgroundColor:"#01092e"
  },
  mydialog: {
     background: "#1872ff",
   
    padding: "20px 25px",
    "& h2.MuiTypography-h6": {
      color: "#fff",
      marginBottom:"0px",fontSize:"22px"
    }
  },
  dialogpaper: {
    
    "& .MuiPaper-rounded ":{
     borderRadius: "20px"
     }
  },
  dialogcontnet: {
    padding:"5px 25px"
  },
  dialogaction: {
    padding: "10px 17px",
    backgroundColor:"#f9f9f9"
  },
  sectionHead:{
    [theme.breakpoints.down(959)]: {
      fontWeight: "500",
      fontSize:"30px", 
      marginBottom:"0px",
      lineHeight:"normal",  
    },
    [theme.breakpoints.down(599)]: {
      fontSize:"20px",
      letterSpacing:"0.2px"
    }
  },
  headOut:{
    padding:"45px 0 30px",
    [theme.breakpoints.down(599)]: {
      padding:"35px 0 20px", 
    }
  },
  popupbox: {
    backgroundColor: "#FFFFFF",
    width:"440px",
    position:"fixed",
    zIndex:"99",
    bottom:"40px",
    left:"85px",
    borderRadius:"6px",
    padding:"20px 20px 20px 55px",
    "& img": {
      borderRadius: "50%",
      height:"80px",
      position:"absolute",
      left:"-40px",
      top:"50%",
      marginTop:"-40px",
      [theme.breakpoints.down(599)]: {
      display:"none"
    } 
 
    },
    [theme.breakpoints.down(1200)]: {
       bottom:"20px",
       left:"55px"
    }, 
    [theme.breakpoints.down(599)]: {
      left:"0",
      bottom:"0",
      width:"100%",
      borderRadius:"0px",
      padding:"15px 5%"
    } 
  },
  cookietext: {
    color: "#1872ff",
    fontSize: "16px",
    lineHeight:"19px",
    marginBottom:"7px",
    paddingRight:"15px",
    fontWeight: "500",
    fontFamily:"Poppins",
    [theme.breakpoints.down(599)]: {
      marginBottom:"5px",
      width:"100%",
    } 
  },
  gotitbtn: {
     background: "rgb(23,111,254)",
                 background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
                 background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
                 background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
                filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
    color: "#FFF",
    borderRadius: "30px",
     padding:"7px 15px",
     marginLeft:"3px",
     fontSize:"13px",
     [theme.breakpoints.down(599)]: {
      marginLeft:"0",
      float:"left"
    } 
  },
  closeicon: {
    justifyContent: "flex-end",
    textAlign:"right",
    position:"absolute",
    right: "-3px",
    top: "-2px",
    "& .MuiSvgIcon-root":{
       fontSize:"21px"
    }
  },
  cookieBottom:{
    float:"left",
    width:"100%",
    fontSize:"13px",
  },
  cookieText:{
    float:"left",
    width:"75%",
    [theme.breakpoints.down(599)]: {
      marginLeft:"0",
      float:"left",
      width:"100%",
      marginBottom:"10px"
    } 
    
  },
  cookieButton:{
    float:"right",
    [theme.breakpoints.down(599)]: {
      float:"left",
      } 
    
  }
  
}));
function HomeContent2() {
  AOS.init();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [show, setshow] = React.useState(true);
  const closepopup = () => {
     setshow(false)
   }
  const handleClickOpen = () => {
    setOpen(true);
    };
    
    const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose = () => {
      setOpen(false);
      setOpen1(false);
  };
 return (
        <div className={classes.root1}>
            <Container maxWidth="lg">
             <Box textAlign="center" className={classes.headOut}>
                    <Typography variant="h3" className={classes.sectionHead}>Live Features</Typography>
                    
                    <img src={dividerimage} alt="no img" className={classes.dividerimg}/>
          </Box>
          {show ? (
            <Box className={classes.popupbox}>           
              <img src={require("../../assets/images/Discord-Profile.png")} alt="img1"></img>
              <div>
                <div className={classes.closeicon}>
                  <IconButton onClick={closepopup}>
                    <CloseIcon />
                  </IconButton>
                </div>
                
                <Typography className={classes.cookietext}>This website uses cookies to improve the user experience.</Typography>
              </div>
              <div className={classes.cookieBottom}>
                <span className={classes.cookieText}>To learn more about our cookie policy please check our privacy policy</span>
                <span className={classes.cookieButton}><Button onClick={closepopup} className={classes.gotitbtn}>Got It!</Button></span>
              </div>           
            </Box>
          ) : (null)}
            <Grid container spacing={3}>
                <Grid item md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="700">
                    <Card className={classes.card1}>
                        <CardContent className={classes.contents}>
                                <Box textAlign="center">
                                  
                                         <div className={classes.iconimg}>
                                        <img src={require("../../assets/images/features1.png")} alt="im1" className={classes.logoimage} />
                                        </div>
                             <Typography gutterBottom className={classes.subheading1}>
                                    Know-How
          </Typography>
                                </Box>
          <Typography className={classes.description}>
           After countless releases, A/B testings, and years of experience, we decided to turn our know-how into product and share it with our customers.
          </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="700">
                    <Card className={classes.card1}>
                        <CardContent className={classes.contents}>
                            <Box textAlign="center">
                           <Box className={classes.iconimg}>
                                        <img src={require("../../assets/images/features2.png")} alt="im1" className={classes.logoimage} />
                                        </Box>
                             <Typography gutterBottom className={classes.subheading1}>
                                   Dedication
          </Typography>
                                </Box>
          <Typography className={classes.description} >
                                Private release proxies are one of the game-changer features that Live Proxies bring to the market.</Typography>
                                <Box textAlign="center"><Link className={classes.link} underline="none" onClick={handleClickOpen}>Read More...</Link></Box>
                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="700">
              
                    <Card className={classes.card1}>
                        <CardContent className={classes.contents}>
                            <Box textAlign="center">
                            <Box className={classes.iconimg}>
                                        <img src={require("../../assets/images/features3.png")} alt="im1" className={classes.logoimage} />
                                        </Box>
                             <Typography gutterBottom className={classes.subheading1}>
                                   Speed
          </Typography>
                                </Box>
          <Typography className={classes.description}>
          Reliability partnered with lightning fast response speeds.
          </Typography>
                                <Box textAlign="center"><Link className={classes.link} underline="none" onClick={handleClickOpen1}>Read More...</Link></Box>

                        </CardContent>
                    </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="700">
                    <Card className={classes.card1}>
                        <CardContent className={classes.contents}>
                            <Box textAlign="center" >
                          <Box className={classes.iconimg}>
                                        <img src={require("../../assets/images/features4.png")} alt="im1" className={classes.logoimage} />
                                        </Box>
                             <Typography gutterBottom className={classes.subheading1}>
                                   Quality
          </Typography>
                                </Box>
          <Typography className={classes.description}>
          Our proxies are entirely residential through real IP devices. Our pools are consistently refreshed to make sure you are provided with undoubted reliability. </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="700">
                    <Card className={classes.card1}>
                        <CardContent className={classes.contents}>
                                <Box textAlign="center">
                                    <Box className={classes.iconimg}>
                                        <img src={require("../../assets/images/features5.png")} alt="im1" className={classes.logoimage} />
                                        </Box>
                              <Typography gutterBottom className={classes.subheading1}>
                                   Support
          </Typography>
                                </Box>
          <Typography className={classes.description}>
          Join our Discord server to get instant support and solutions to any issues you run into! We're here to help!</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4} sm={6} xs={12} data-aos="fade-up" data-aos-duration="700">
                    <Card className={classes.card1}>
                        <CardContent className={classes.contents}>
                            <Box textAlign="center">
                          <Box className={classes.iconimg}>
                                        <img src={require("../../assets/images/features6.png")} alt="im1" className={classes.logoimage} />
                                        </Box>
                             <Typography gutterBottom className={classes.subheading1}>
                                   Success
          </Typography>
                                </Box>
                                <Typography className={classes.description}>
                                Live Proxies guarantee little to now blocks and high-speed performance to see you through a release successfully!
          </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                </Grid>
                 <Dialog
        open={open}
            onClose={handleClose}
            className={classes.dialogpaper}
        
      >
        <DialogTitle className={classes.mydialog}>Dedication</DialogTitle>
        <DialogContent className={classes.dialogcontnet}>
          <DialogContentText >
                            <p>Private release proxies are one of the game-changer features that Live Proxies bring to the market.</p>
                            <p>Our residential plans are exclusive to a single customers, unique to you and no other user.</p>
                            <p> You generate hundreds of individual profiles not to be clipped by the major retailers, and you assign different proxies to each task. So far, so good. But have you ever considered if the real IPs behind those proxies are private to you? </p>
                            <p>Enjoy the assurance and reliability of a completely private pool of Live Proxies to push you through releases...</p>
                        </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogaction}>
        
          <Button onClick={handleClose} color="secondary">
           close
          </Button>
        </DialogActions>
                </Dialog>
                 <Dialog
        open={open1}
        onClose={handleClose}
        className={classes.dialogpaper}
       
      >
        <DialogTitle className={classes.mydialog}>Speed</DialogTitle>
        <DialogContent className={classes.dialogcontnet}>
         
                         <DialogContentText >
                            <p>Reliability partnered with lightning fast response speeds.</p>
                            <p>Preparing for drops such as Supreme and Shopify can be stressful, finding data centre proxies with the performance you need and finding them banned minutes before a release is frustrating.</p>
                            <p>We have combatted that issue for you, giving you the reliability and versatility of residential plans combined with the performance you need to hit releases!</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogaction}>
        
          <Button onClick={handleClose} color="secondary">
           close
          </Button>
        </DialogActions>
      </Dialog>
                </Container>
      </div>
  );
}

export default HomeContent2;
