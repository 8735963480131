import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Top from "../components/Top";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Footer1 from "../components/Footer1";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import image1 from '../../assets/images/innerbanner.jpg'
import Button from '@material-ui/core/Button'
import dividerimage from '../../assets/images/divider.png'
import { colors } from '../components/colors'
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import { isAuthenticated, getDomain } from "../../auth";
import { useHistory } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
//import { PagesSharp } from "@material-ui/icons";
import { CustomAlert, user_message, user_severity, delete_info } from "../../helper/helper";

const axios = require('axios');
const useStylesDrop = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "#01092d",
    overflow: "auto",
    [theme.breakpoints.down(1200)]: {
      position: "static",
      overflow: "visible",
    },
  },
  content: {
    flexGrow: 1,
    height: "auto",
    backgroundColor: colors.darkBlue,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: colors.mainColor,
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  container1: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    backgroundColor: colors.mediumblue,
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "100%",
    maxWidth: "720px",
    [theme.breakpoints.down(959)]: {
      maxWidth: "633px",
    },
    [theme.breakpoints.down(599)]: {
      maxWidth: "90%",
    },
  },
  subhead1: {
    fontWeight: "500",
    fontSize: 16,
    marginTop: 3,
    marginBottom: 10,
    letterSpacing: 0.6,
    color: colors.whitemed,

  },
  subhead2: {
    fontWeight: "500",
    fontSize: 16,
    marginTop: 20,
    marginBottom: 10,
    letterSpacing: 0.6,
    color: colors.whitemed,

  },
  imageSrc: {
    height: "auto",
    width: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingBottom: "15px"
  },


  paragraph: {
    color: colors.whitemed,
    fontSize: "14px",
    fontWight: "400",
    marginTop: "5px",
    marginBottom: "12px",
    letterSpacing: 0.6,
    lineHeight: "20px"
  },
  paper: {
    display: "flex",
    marginTop: "145px",
    marginBottom: "140px",
    backgroundColor: colors.mediumblue,
    boxShadow: "none",
    alignItems: 'center',
    borderRadius: "25px",
    overflow: "hidden",
    [theme.breakpoints.down(959)]: {
      marginTop: "80px"
    },
    [theme.breakpoints.down(599)]: {
      marginTop: "40px",
      display: "block",
      marginBottom: "60px"
    },
  },

  lgnfrmimg: {
    height: "auto",
    width: "172px",
    [theme.breakpoints.down(599)]: {
      width: "112px",
    },
  },
  dividerimg: {
    height: 20,
    width: "auto",
    marginTop: "30px",
    [theme.breakpoints.down(1200)]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down(959)]: {
      display: "none",
    }
  },
  pagetitle: {
    lineHeight: "30px !important",
    fontSize: "32px !important",
    [theme.breakpoints.down(1200)]: {
      fontSize: "28px !important",
      fontWeight: "500"
    },
    [theme.breakpoints.down(959)]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.down(599)]: {
      fontSize: "20px !important",
      marginBottom: "0px",
    }
  },
  titlebox: {
    textAlign: "center",
    marginBottom: "24px",
    paddingTop: "50px",
    [theme.breakpoints.down(1200)]: {
      paddingTop: "35px",
      marginBottom: "10px"
    },
    [theme.breakpoints.down(959)]: {
      paddingTop: "26px",
      marginBottom: "10px"
    },
    [theme.breakpoints.down(599)]: {
      paddingTop: "33px",
      marginBottom: "5px"
    },

  },
  discordimg: {
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )"
    , textAlign: "center", width: "auto", justifyContent: "center", padding: "35px 55px",
    [theme.breakpoints.down(959)]: {
      padding: "30px 30px",
    },
  },
  lgnbtnbox: {
    backgroundColor: colors.mediumblue,
    textAlign: "center",
    borderTopRightRadius: "25px",
    borderBottomRightRadius: "25px",
    padding: "50px",
    [theme.breakpoints.down(959)]: {
      padding: "30px 30px",
    },
    [theme.breakpoints.down(599)]: {
      padding: "22px 30px 28px",
    },
  },
  lgnbtn: {
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
    color: "#fff",
    textAlign: "center",
    borderRadius: "25px",
    padding: "10px 70px",
    fontFamily: "Poppins",
    letterSpacing: "1px",
    [theme.breakpoints.down(599)]: {
      padding: "10px 30px",
    },
  },
  welcometxt: {
    fontSize: 27,
    fontWeight: "500",
    color: colors.whitemed,
    letterSpacing: 1,
    fontFamily: "Poppins",
    marginBottom: 10,
    [theme.breakpoints.down(599)]: {
      fontSize: "22px",
      marginBottom: "6px"
    },
  },
  lgntxt: {
    fontSize: 15,
    fontWeight: "100",
    color: colors.lightgray,
    letterSpacing: 1,
    marginBottom: 30,
    lineHeight: "20px",
    fontFamily: "Poppins",
    [theme.breakpoints.down(599)]: {
      marginBottom: 20,
    },
  },
  loginFooter: {
    position: "fixed",
    width: "100%",
    bottom: "0",
    left: "0",
    [theme.breakpoints.down(1200)]: {
      position: "static",
    },
  }
}));

function Login() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  if (isAuthenticated()) {
    history.push("/dashboard");
  }

  const loginProcess = (e) => {
    window.location.href = getDomain() + "/login";
  }
  useEffect(() => {
    const values = queryString.parse(location.search)
    console.log(values);
    if (values.code) {
      handleToggle();
      axios.get(getDomain() + "/auth-vk?code=" + values.code)
        .then(function (response) {
          //console.log(response.data);
          if (response.data.role === 1 || response.data.role === 3) {
            localStorage.setItem("admin", response.data.role);
          }
          if (response.data.role === 4) {
            localStorage.setItem("unlimited_user", response.data.role);
          }
          localStorage.setItem("api_token", response.data.api_token);
          localStorage.setItem("user_id", response.data.user_id);
          localStorage.setItem("display_name", response.data.display_name);
          localStorage.setItem("avatar", response.data.avatar);
          history.push("/dashboard");
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    if (values.status && parseInt(values.status) == 0) {
      showMessage("We temporarily disabled your account. Please open a ticket in our Discord server!", "error");
    }
  }, []);
  //-------------------------------
  const classesDrop = useStylesDrop();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    return;//setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  //------------------------------
  //-------------------------------------------
  const [alertOpen, setAlertOpen] = React.useState(false);
  const handleAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");

  function showMessage(newMessage, newSeverity) {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setAlertOpen(true);
  }
  //----------------------------------------
  return (
    <>
      <CustomAlert open={alertOpen} severity={severity} message={message} handleClose={handleAlert} />
      <div className={classes.loginWrapper}>
        <Top />
        <div className={classes.drawerHeader} />
        <main className={classes.content} >
          <div className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image1})`,
            }}>
            <Box className={classes.titlebox}>
              <Typography variant="h3" className={classes.pagetitle}>Login</Typography>
              <img src={dividerimage} alt="no img" className={classes.dividerimg} />
            </Box>
          </div>
          <Backdrop className={classesDrop.backdrop} open={open} onClick={handleClose}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Container className={classes.container1}>
            <Paper className={classes.paper}>


              <Box className={classes.discordimg}>
                <img src={require("../../assets/images/discordLogo.png")} alt="img" className={classes.lgnfrmimg} />

              </Box>
              <Box className={classes.lgnbtnbox}>
                <Typography className={classes.welcometxt}>Welcome Back!</Typography>
                <Typography className={classes.lgntxt}>We're so excited to see you again</Typography>
                <Button variant="contained" className={classes.lgnbtn} onClick={loginProcess}>Login Via Discord Only</Button>
              </Box>

            </Paper>
          </Container>
          <div className={classes.loginFooter}>
            <Footer1 />
          </div>
        </main>
      </div>

    </>
  );
}
export default Login;
