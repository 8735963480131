import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GitHubIcon from '@material-ui/icons/GitHub';
import Button from '@material-ui/core/Button';
import { staticUrls } from "../../helper/helper";
const links = staticUrls();

const useStyles = makeStyles((theme) => ({
footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
       marginTop: theme.spacing(2),
        paddingTop:"20px",
        paddingLeft: "30px",
        paddingRight: "30px",       
        paddingBottom: "18px",
        fontSize:"14px",
          letterSpacing:"0",
          [theme.breakpoints.down(1200)]: {
            paddingLeft: "20px",
            paddingRight: "20px", 
         }
    },
   socialicon: {
        color:"#FFF"
    },
    iconbtn: {
        backgroundColor: "#1870ff",
        margin:"5px",
        height:"48px",
        width:"48px",
    },
    container1: {
        maxWidth: "100%",
        paddingLeft: "0px",
        paddingRight:"0px",
        [theme.breakpoints.down(959)]: {
            textAlign:"center"
         }

    },
    joindisocrdbtn: {
         background: "rgb(23,111,254)",
     background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
     background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
     color: "#cde8fd",
     fontSize: 13,
     fontWeight: "500",
     letterSpacing: 0.3,
     lineHeight: "42px",
        height: "40px",
     whiteSpace: "nowrap",
     borderRadius: "40px",
        padding: "6px 20px",
        marginLeft: "12px",
     marginTop:"10px",
     [theme.breakpoints.down(959)]: {
        margin: "21px 0 0 0"
      } 
    },
    copyRight:{
        marginTop: "22px", 
        color: "#2e3045",
        marginRight: "10px",
        fontSize:"14px",
        letterSpacing:"0",
        [theme.breakpoints.down(959)]: {
          marginTop: "7px",
          marginRight: "0px",
          letterSpacing:"0px"
        } 
    },
    fottSocial:{
       textAlign:"right",
       [theme.breakpoints.down(959)]: {
        textAlign:"center",
      }   
    },
    footButton:{
       display:"flex" ,
       [theme.breakpoints.down(959)]: {
        display:"block" ,
      } 
    }
  }));
export default function DashboardFooter(props) {
    const classes = useStyles();
    const { backgroundColor } = props;
    return (
        <div className={classes.footer} {...backgroundColor}>
        <Container className={classes.container1} >
           <Grid container spacing={3}>
           <Grid item xs={12} md={8} lg={8}>
                        <Box className={classes.footButton}>
                        <Typography variant="h5" className={classes.copyRight}>Live Proxy Copyright @2020 All Rights Reserved</Typography>
                            <Button variant="contained" className={classes.joindisocrdbtn} href={ links.social_discord_url } target="_blank">Join Our Discord</Button>
                            </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    <Box className={classes.fottSocial}>
                     <IconButton href={ links.social_twitter_url } target="_blank" className={classes.iconbtn}><TwitterIcon className={classes.socialicon}/></IconButton>
                     <IconButton href={ links.social_discord_url } target="_blank" className={classes.iconbtn}><img src={require("../../assets/images/discordIcon.png")}/> </IconButton>
                     <IconButton href={ links.social_instagram_url } target="_blank" className={classes.iconbtn}><MailOutlineIcon className={classes.socialicon}/></IconButton>
                  </Box>
                    </Grid>
                    </Grid>
                </Container>
            </div>
    );
}