import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import VisibilityOffSharpIcon from "@material-ui/icons/VisibilityOffSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import DeleteIcon from "@material-ui/icons/Delete";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router-dom";
import RDrawer from "../components/Top";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import { header, getDomain, logout } from "../../auth";
import { CustomAlert, user_message, user_severity, delete_info, ConfirmAlert } from "../../helper/helper";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
    minHeight: "100vh",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },
  title1: {
    justifyContent: "flex-end",
    display: "flex",
    paddingBottom: 5,
  },
  title2: {
    paddingBottom: 10,
  },
  btntext: {
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 7,
    border: "1px solid",
    textTransform: "none",
    padding: "7px 15px",
  },
  iconcolor: {
    color: "#81839f",
    fontSize: "20px",
  },
  
  tbllayout: {
    "& table": {
      overflow: "hidden",
      borderRadius: 10,
      "& thead th span.MuiTableSortLabel-root:hover": {
        color: "#fff !important",
      },
        "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
        color: "#fff !important",
      },
        "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
          color: "#fff !important",
        }
    },
  },
}));
const tableIcons = {
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
     };


export default function Notification() {
  const classes = useStyles();
  const history = useHistory();

  let [rows, setRows] = useState([]);
  var [ deleteObj, setDeleteObj ]=useState({});
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  useEffect(()=>{
    if(user_message())
    {
      setSeverity(user_severity());
      setMessage(user_message());
      setOpen(true);
    }
    delete_info();
  },[])
  function showMessage(newMessage,newSeverity)
  {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [modelInfos, setModelInfos] = React.useState({ title: "", description: "" });
  const disagree = () =>{
    setConfirmOpen(false);
  }
  const agree = () =>{
    setConfirmOpen(false);
    deleteRow(deleteObj);
  }
  const openModel = (rowData) =>{
    setDeleteObj(rowData)
    setModelInfos({
      title: "Delete Notification",
      description: "Do you really want to delete this Notification ?"
    })
    setConfirmOpen(true);
  }
  //----------------------------------------
  useEffect(()=>{
      axios.get(getDomain()+'/notification',{
        headers : header()
      })
      .then(function(response){
        setRows(response.data);
        console.log(response.data);
      })
      .catch(function(error){
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
        showMessage("Something is went wrong, please try after sometime","error");
      });
  },[])

  function deleteRow(rowData){
    axios.delete(getDomain()+'/notification/destroy/'+rowData.id,{
      headers : header()
    })
    .then(function(response){
      console.log(response);
      setRows(rows.filter(item=>item !==rowData));
      setDeleteObj({});
      showMessage("Notification deleted successfully","success");
    })
    .catch(function(error){
      console.log(error);
      showMessage("Something is went wrong, please try after sometime","error");
    });
    }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CustomAlert open={ open } severity={ severity } message={ message } handleClose={ handleClose } />
        <ConfirmAlert open={ confirmOpen } handleClose={ disagree } agree={ agree } disagree={ disagree }
        title={ modelInfos.title } description={ modelInfos.description } />

        <Container maxWidth="100%" className={classes.container}>
          <Hidden smUp implementation="css">
            <Box className={classes.title2}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.btntext}
                onClick={() => history.push("/admin/add-otification",{ params: {
                  id: "",
                  message: "",
                  all_users : "",
                  created_at: "",
                  updated_at: "",
              } })}
              >
                Add New Notification
              </Button>
            </Box>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Box className={classes.title1}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.btntext}
                onClick={() => history.push("/admin/add-notification",{ params: {
                  id: "",
                  message: "",
                  all_users : "",
                  created_at: "",
                  updated_at: "",
              } })}
              >
                Add New Notification
              </Button>
            </Box>
          </Hidden>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tbllayout}>
                <MaterialTable
                   icons={tableIcons}
                  columns={[
                    {
                      title: "Message",
                      field: "message",
                    },
                    {
                      title: "Created Date",
                      field: "created_at",
                      type: "date",
                    },
                  ]}
                  data={rows}
                  options={{
                    showTitle: false,
                    actionsColumnIndex: -1,
                    searchFieldVariant: "outlined",
                    outline: "none",
                    searchFieldStyle: {
                      height: 38,
                      paddingRight:"0"
                    },
                    headerStyle: {
                      backgroundColor: "#151948",
                      color: "#FFF",
                      alignContent: "left",
                      textAlign: "left",
                    },

                    rowStyle: {
                      backgroundColor: "#e9e9e9",
                      color: "#797fb3",
                      alignContent: "left",
                      textAlign: "left",
                    },

                    cellStyle: {
                      alignContent: "left",
                      textAlign: "left",
                    },

                    exportButton: true,
                  }}
                  style={{
                    boxShadow: "none",
                  }}
                  actions={[
                    {
                      icon: () => (
                        <VisibilityOffSharpIcon
                          size="small"
                          className={classes.iconcolor}
                        />
                      ),
                      tooltip: "Edit User",
                      onClick: (event, rowData) =>
                        alert("You saved " + rowData.name),
                    },
                    {
                      icon: () => (
                        <EditSharpIcon
                          size="small"
                          className={classes.iconcolor}
                        />
                      ),
                      tooltip: "Format User",
                      onClick: (event, rowData) =>
                      history.push("/admin/add-notification", {params:rowData}),
                    },
                    {
                      icon: () => (
                        <DeleteIcon
                          size="small"
                          className={classes.iconcolor}
                        />
                      ),
                      tooltip: "Format User",
                      onClick: (event, rowData) =>
                      openModel(rowData),
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
