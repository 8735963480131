import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
//import IconButton from "@material-ui/core/IconButton";
//import FileCopyIcon from "@material-ui/icons/FileCopy";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NativeSelect from '@material-ui/core/NativeSelect';
import RDrawer from "../components/Top";

import { Editor } from '@tinymce/tinymce-react';
import { header, getDomain,logout } from "../../auth";
import { CustomAlert, ConfirmAlert } from "../../helper/helper";
import Input from "@material-ui/core/Input";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position:"absolute",
    height:"100%",
    width:"100%",
    overflow:"hidden"
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingTop: 19,
    paddingBottom: "20px",
    position:"relative",
    height: "calc(100% - 64px);",
    width:"100%",
  },

  fixedHeight: {
    height: 240,
  },

  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    margin: "0 15px 0 0",
    textTransform: "none",
    letterSpacing: "0.4px",
    padding:"7px 15px", 
    boxShadow:"none"
  },

  paper: {
    background: "#f1f2f7",
    borderRadius: 8,
    padding: 20,
    marginTop: 10,
    marginBottom: 20,
    height: "calc(100% - 140px);",
    boxShadow: "none",
    position:"relative",
    overflow:"auto"

  },
  input: {
    background: "#f1f2f7",
    borderRadius: 8,
    borderBottom: "none",
    fontSize: 14,
    fontWeight: "500",
    boxShadow: "none",
    position:"relative",
    paddingLeft: 10,
    padding: "2px",
    height: 43,
  },
  iptext: {
    color: "#6d6e71",
    fontSize: "13px",
    fontWeight:"400"
  },
  selectbox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop:"0px",
    marginBottom:"10px",
    [theme.breakpoints.down(1200)]: {
      display: "block",
      width:"calc(100% - -15px)"
    }, 
    "& p":{
      fontSize:"15px",
      marginBottom:"5px",
      [theme.breakpoints.down(959)]: {
        fontSize:"14px",
      }, 
    }
  },
  iconbtnbox: {
    paddingTop:"29px"
  },
  iconbtn: {
    color: "#0e50f9",
    border: "1px solid #0e50f9",
    padding: "8px",
    borderRadius: "7px",
    [theme.breakpoints.down(1200)]: {
      marginBottom:"10px"
    } 
  },
  nativeslect: {
        backgroundColor: "#f1f2f7",
        color: "#b6b7bb",
        paddingRight: "10px",
        fontSize: 14,
        fontWeight: "400",
        borderRadius: 7,
        letterSpacing:"0.1px",
        marginRight:"10px",
        height: 44,
        width:"210px",
        [theme.breakpoints.down(1200)]: {
          width:"100%",
        }, 
        "& .MuiNativeSelect-icon": {
            color: "#b6b7bb",
        },
        "& .MuiNativeSelect-iconFilled": {
            right:"12px"
      },
          "& .MuiNativeSelect-select:focus": {
            backgroundColor: "#f1f2f7"
      },
      "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
           backgroundColor: "#f1f2f7",
           paddingLeft:"13px"
    },
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
           paddingLeft:"13px"
         }
  },
  dropdwnbox: {
    display:"flex",
    [theme.breakpoints.down(1200)]: {
      display: "block",
    },
    "& .MuiBox-root":{
      float:"left",
      width:"50%",
      marginBottom:"15px",
      paddingRight:"15px",
      [theme.breakpoints.down(479)]: {
        width:"100%",
      },
    }
  },
 
}));

export default function IPs() {
  const endpointList = [
    {value:"", name: "Choose Network"},
    {value:"private-release-proxies", name: "Private Release Proxies"},
    {value:"daily-shared-proxies", name: "Booster Proxies"}
  ];
  const [countryList, setCountryList] =React.useState([]);
  const classes = useStyles();
  const [apis, setapis] = React.useState(1)
  const [endpoint, setendpoint]=React.useState("")
  const [country, setcountry]=React.useState("")
  const [datalimit, setDataLimit]=React.useState("")
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  function showMessage(newMessage,newSeverity)
  {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [modelInfos, setModelInfos] = React.useState({ title: "", description: "" });
  const disagree = () =>{
    setConfirmOpen(false);
  }
  const agree = () =>{
    setConfirmOpen(false);
    saveData();
  }
  const openModel = () =>{
    if(apis==="")
    {
      showMessage("Please choose api!","error");
    }
    else if(endpoint==="")
    {
      showMessage("Please choose a network!","error");
    }
    else if(country==="")
    {
      showMessage("Please choose a country!","error");
    }
    else{
      setModelInfos({
        title: "Update Ips",
        description: " Your are saving ips for Endpoint: "+ endpoint + " & country: " + country
      })
      setConfirmOpen(true);
    }
  }
  //----------------------------------------

  let [allIps, setAllIps]= useState("");

  const handleChange = (event) => {
   setapis(event.target.value)
  };
  const ipChange = (e) => {
    setAllIps(e.target.value);
  }
  const handleChange1 = (event) => {
     setendpoint(event.target.value)
  };
   const handleChange3 = (event) => {
   setcountry(event.target.value)
  };
  const dataHandler = (e) => {
    console.log(e.target.name,e.target.value)
    setDataLimit(e.target.value )
  }
  const getEndpoints = () =>
  {
    setAllIps("");
    if(apis==="")
    {
      showMessage("Please choose api!","error");
    }
    else if(endpoint==="")
    {
      showMessage("Please choose a network!","error");
    }
    else if(country==="")
    {
      showMessage("Please choose a country!","error");
    }
    else
    {
      let params =  {
        provider_id: apis,
        location: endpoint,
        country: country
      };
      axios.get(getDomain()+'/get-endpoints',{params,headers : header()})
      .then(function(response){
        console.log(response.data);
        setAllIps(response.data[0]);
        setDataLimit(response.data[1]);
        if(response.data[0].length==0)
        {
          showMessage("Sorry we don't have any ip for provided information","error");
        }
      })
      .catch(function(error){
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
        showMessage("Something is went wrong, please try after sometime","error");
      });
    }
  }
  const saveData = () =>{
    console.log(allIps);
    let params =  {
      provider_id: apis,
      location: endpoint,
      country: country,
      ip: allIps,
      data_limit:datalimit,
    };
    axios.put(getDomain()+'/save-endpoints', params,{
      headers : header()
    })
    .then(function(response){
      console.log(response);
      showMessage("You have successfully save informations","success");
    })
    .catch(function(error){
      console.log(error);
      showMessage("Something is went wrong, please try after sometime","error");
    });
  }
  useEffect(()=> {
    axios.get(getDomain()+"/countries/admin")
    .then(function(response){
      setCountryList(response.data);
    })
    .catch(function(error){
      console.log(error);
    })
  },[]);
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CustomAlert open={ open } severity={ severity } message={ message } handleClose={ handleClose } />
        <ConfirmAlert open={ confirmOpen } handleClose={ disagree } agree={ agree } disagree={ disagree }
        title={ modelInfos.title } description={ modelInfos.description } />

        <Container maxWidth="100%" className={classes.container}>
          <Box className={classes.selectbox}>
            <Box className={classes.dropdwnbox}>
              <Box>
              <Typography>API</Typography>
            <NativeSelect
                              value={apis}
                              onChange={handleChange}
                              variant="filled"
                             className={classes.nativeslect}
                              disableUnderline
        >
          {/*
        <option value="" disabled>Provider 1</option>
          <option value="Provider2">Provider 2</option>
          */}
                      <option value="1">Choose API</option>
                </NativeSelect>
              </Box>
               <Box>
              <Typography>Endpoint</Typography>
            <NativeSelect
                              value={endpoint}
                              onChange={handleChange1}
                              variant="filled"
                              className={classes.nativeslect}
                              disableUnderline
        >
          { endpointList.map((e, key) => {
            return <option key={key} value={e.value}>{e.name}</option>;
          })}
               </NativeSelect>
              </Box>
              
               <Box>
              <Typography>Country</Typography>
            <NativeSelect
                              value={country}
                              onChange={handleChange3}
                              variant="filled"
                              className={classes.nativeslect}
          disableUnderline
        >
          <option value="">Choose Country</option>
          { countryList.map((e, key) => {
            return <option key={key} value={e.code}>{e.name}</option>;
          })}
                 </NativeSelect>
                </Box>
                </Box>
          
           <Box className={classes.iconbtnbox}>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                size="small"
                onClick={ getEndpoints }
              >
                Filter
              </Button>
           </Box>
          </Box>
          {endpoint=="daily-shared-proxies"?(
               <Box>
              <Typography>Data Limit</Typography>
              <Input 
                      type="text"
                      name="data_limit"
                      value={datalimit}
                      disableUnderline
                      fullWidth
                      onChange={ dataHandler }
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                    />
              </Box>
              ):''}
          <Paper className={classes.paper}>
          <textarea name="body"
            onChange={ ipChange }
            value={ allIps }
            rows="21" cols="120"
            />     
          </Paper>
          <Box marginTop={2} display="flex">
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                size="small"
                onClick={openModel}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                size="small"
              >
                Cancel
              </Button>
            </Box>
            
          </Box>
        </Container>
      </main>
    </div>
  );
}
