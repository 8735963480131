import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Top from "../components/Top";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Footer1 from "../components/Footer1";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import image1 from "../../assets/images/innerbanner.jpg";
import Button from "@material-ui/core/Button";
//import List from "@material-ui/core/List";
import EmailIcon from "@material-ui/icons/Email";
//import ListItem from "@material-ui/core/ListItem";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
//import ListItemText from "@material-ui/core/ListItemText";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { colors } from "../components/colors";
import dividerimage from "../../assets/images/divider.png";
import { IconButton } from "@material-ui/core";
//import NativeSelectInput from "@material-ui/core/NativeSelect/NativeSelectInput";
import { getDomain, user_id, logout, header } from "../../auth"; // passwordExpire
import { CustomAlert } from "../../helper/helper";
import { useHistory } from "react-router-dom";
import Input from "@material-ui/core/Input";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(93,93,93,0.10)",
      outline: "0px solid rgba(93,93,93,0.10)",
    },
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    fontFamily: "Poppins",
    height: "auto",
    backgroundColor: colors.darkBlue,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: colors.mainColor,
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  container1: {
    backgroundColor: colors.darkBlue,
  },
  cardHeader: {
    "& .MuiTypography-h5": {
      fontSize: "30px",
      marginTop: "27px",
      color: "#cdcfde",
      fontWeight: "500",
      textAlign: "center",
      [theme.breakpoints.down(599)]: {
        fontSize: "24px",
        marginTop: "17px",
      },
    },
  },
  cardHeader1: {
    "& .MuiTypography-h5": {
      fontSize: "30px",
      marginTop: "27px",
      color: colors.white,
      fontWeight: "500",
      textAlign: "center",
      [theme.breakpoints.down(599)]: {
        fontSize: "24px",
        marginTop: "17px",
      },
    },
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: "10px",
  },

  imageSrc: {
    height: "auto",
    width: "auto",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingBottom: "15px",
  },
  img: { width: "auto", right: 0 },
  subheader: {
    fontSize: "15px",
    letterSpacing: 0.3,
    color: colors.lightgray,
    fontWeight: "400",
    [theme.breakpoints.down(599)]: {
      fontSize: "14px",
    },
  },
  subheader1: {
    fontSize: "15px",
    letterSpacing: 0.3,
    color: colors.white,
    fontWeight: "400",
    [theme.breakpoints.down(599)]: {
      fontSize: "14px",
    },
  },

  cardcolor: {
    maxWidth: 350,
    backgroundColor: "#000c40",
    borderRadius: "40px",
    margin: "0px auto",
    "& .MuiCardContent-root": {
      padding: "15px 30px",
      [theme.breakpoints.down(1200)]: {
        padding: "0px 20px 6px",
      },
    },
    "& .MuiCardActions-root": {
      padding: "5px 30px 40px",
      [theme.breakpoints.down(599)]: {
        padding: "5px 20px 21px",
      },
    },
  },
  cardcolor1: {
    maxWidth: 350,
    backgroundColor: colors.skyblue1,
    borderRadius: "40px",
    margin: "0px auto",
    "& .MuiCardContent-root": {
      padding: "15px 30px",
      [theme.breakpoints.down(1200)]: {
        padding: "0px 20px 6px",
      },
    },
    "& .MuiCardActions-root": {
      padding: "5px 30px 40px",
      [theme.breakpoints.down(599)]: {
        padding: "5px 20px 21px",
      },
    },
  },
  listItemText: {
    color: colors.lightgray,
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: "400",
    "& .MuiListItem-gutters li": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.down(599)]: {
      fontWeight: "400",
      letterSpacing: "0.1px",
    },
  },
  listItemText1: {
    color: colors.white,
    fontSize: 15,
    letterSpacing: 1,
    fontWeight: "400",
    "& .MuiListItem-gutters li": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.down(599)]: {
      fontWeight: "400",
      letterSpacing: "0.1px",
    },
  },
  btntext: {
    borderRadius: 30,
    backgroundColor: colors.white,
    color: colors.skyblue2,
    fontSize: "16px",
    fontWeight: "500",
    letterSpacing: 1.4,
    margin: "10px 0px",
    height: 50,
  },
  btntext1: {
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 7,
    border: "1px solid",
    textTransform: "none",
    padding: "7px 15px",
  },
  nativeslect: {
    backgroundColor: colors.darkBlue,
    color: colors.lightgray,
    paddingLeft: "25px",
    paddingRight: "10px",
    fontSize: 15,
    fontWeight: "400",
    borderRadius: 25,
    width: "100%",
    letterSpacing: 1,
    marginLeft: "0px",
    marginBottom: 15,
    height: 44,
    "& .MuiNativeSelect-icon": {
      color: colors.lightblue,
    },
    "& .MuiNativeSelect-iconFilled": {
      right: "12px",
    },
    "& .MuiNativeSelect-select:focus": {
      backgroundColor: colors.darkBlue,
    },
    "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
      backgroundColor: colors.darkBlue,
    },
  },
  autonewaltext: {
    paddingTop: 10,
    paddingRight: "0",
    fontSize: 15,
    color: colors.lightblue,
    [theme.breakpoints.down(599)]: {
      letterSpacing: "0.1px",
      fontSize: 14,
    },
  },
  autonewaltext1: {
    paddingTop: 10,
    paddingRight: "0",
    fontSize: 15,
    color: colors.white,
    [theme.breakpoints.down(599)]: {
      letterSpacing: "0.1px",
      fontSize: 14,
    },
  },
  autorenewalbox: {
    display: "flex",
    justifyContent: "space-around",
    "& label": {
      marginLeft: "1px",
      marginRight: "9px",
      [theme.breakpoints.down(599)]: {
        marginleft: "0",
        marginRight: "0",
      },
    },
  },
  inputbox: {
    backgroundColor: colors.darkBlue,
    color: colors.lightblue,
    paddingLeft: "15px",
    fontSize: 14,
    fontWeight: "400",
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    width: "65%",
    letterSpacing: 0.1,
    marginTop: "5px",
    marginBottom: "4px",
    height: 44,
    outline: "none",
    border: "1px solid #01092d",
    [theme.breakpoints.down(599)]: {
      width: "65%",
    },
  },
  inputbox1: {
    backgroundColor: colors.skyblue,
    color: colors.white,
    paddingLeft: "15px",
    fontSize: 14,
    fontWeight: "400",
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    width: "65%",
    letterSpacing: 0.1,
    marginTop: "5px",
    marginBottom: "4px",
    height: 44,
    outline: "none",
    border: "1px solid #166bfa",
    [theme.breakpoints.down(599)]: {
      width: "65%",
    },
  },
  inputPassword: {
    background: "#f1f2f7",
    borderRadius: 8,
    borderBottom: "none",
    fontSize: 14,
    fontWeight: "500",
    boxShadow: "none",
    position: "relative",
    paddingLeft: 10,
    padding: "2px",
    height: 43,
  },
  pricsecolor: {
    color: colors.skyblue,
    marginTop: "20px",
    marginBottom: "27px",
    [theme.breakpoints.down(599)]: {
      marginTop: "7px",
      marginBottom: "17px",
      letterSpacing: "0.2px",
    },
  },
  textmail: {
    color: colors.lightblue2,
    fontSize: 18,
    fontWeight: "400",
    fontFamily: "Poppins",
    marginBottom: "12px",
    [theme.breakpoints.down(959)]: {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  mailbox: {
    marginBottom: "54px",
    marginTop: "60px",
    textAlign: "center",
    [theme.breakpoints.down(959)]: {
      marginBottom: "36px",
      marginTop: "40px",
    },
  },
  pricsecolor1: {
    color: "#001839",
    marginTop: "20px",
    marginBottom: "27px",
    [theme.breakpoints.down(599)]: {
      marginTop: "7px",
      marginBottom: "17px",
      letterSpacing: "0.2px",
    },
  },

  nativeslect1: {
    backgroundColor: colors.skyblue,
    color: colors.white,
    paddingLeft: "25px",
    paddingRight: "10px",
    fontSize: 15,
    fontWeight: "400",
    borderRadius: 25,
    width: "100%",
    letterSpacing: 1,
    marginLeft: "0px",
    marginBottom: 15,
    height: 44,
    "& .MuiNativeSelect-icon": {
      color: colors.white,
    },
    "& .MuiNativeSelect-iconFilled": {
      right: "12px",
    },
    "& .MuiNativeSelect-select:focus": {
      backgroundColor: colors.skyblue,
    },
    "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
      backgroundColor: colors.skyblue,
    },
  },
  applybox: {
    backgroundColor: colors.skyblue,
    height: 44,
    width: "35%",
    color: colors.white,
    marginTop: "5px",
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    letterSpacing: 1,
    [theme.breakpoints.down(599)]: {
      width: "35%",
    },
  },
  applybox1: {
    backgroundColor: colors.darkBlue,
    height: 44,
    width: "35%",
    color: colors.white,
    marginTop: "5px",
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
    letterSpacing: 1,
    [theme.breakpoints.down(599)]: {
      width: "35%",
    },
  },
  maillink: {
    color: colors.skyblue2,
    textDecoration: "none",
    letterSpacing: 1,
    fontSize: 18,
    fontFamily: "Poppins",
    "& svg": {
      marginRight: "8px",
      display: "inline-block",
      float: "left",
      marginLeft: "9px",
    },
    [theme.breakpoints.down(959)]: {
      fontSize: "14px",
      "& svg": {
        marginRight: "6px",
        marginLeft: "0px",
        marginTop: "0px",
        fontSize: "21px",
      },
    },
  },

  logoesbox: {
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px",
    display: "block",
    width: "96%",
    marginLeft: "2%",
    textAlign: "center",
    [theme.breakpoints.down(1200)]: {
      marginTop: "29px",
      marginBottom: "29px",
    },
    [theme.breakpoints.down(1025)]: {
      position: "relative",
      height: "66px",
      display: "flex",
      overflow: "hidden",
      marginTop: "20px",
    },
    [theme.breakpoints.down(959)]: {
      marginTop: "13px",
      marginBottom: "22px",
      height: "62px",
    },
  },
  logoesboxInner: {
    display: "flex",
    overflow: "auto",
    justifyContent: "center",
    [theme.breakpoints.down(1025)]: {
      position: "absolute",
      width: "100%",
      left: "0",
      top: "0",
      display: "flex",
      overflow: "auto",
    },
  },
  brandbox: {
    padding: "12px 14px 12px 6px",
    backgroundColor: "#000c40",
    margin: "10px",
    display: "flex",
    height: "auto",
    borderRadius: "8px",
    [theme.breakpoints.down(959)]: {
      height: "auto",
    },
    [theme.breakpoints.down(1025)]: {
      display: "flex",
    },
    "& .MuiIconButton-root": {
      float: "left",
      paddingTop: "4px",
      [theme.breakpoints.down(959)]: {
        padding: "2px 1px 2px 6px",
      },
    },
    "& img": {
      marginLeft: "7px",
      height: "32px",
      //marginLeft:"3px",
      [theme.breakpoints.down(959)]: {
        marginLeft: "9px",
        height: "28px",
      },
    },
  },
  mylist: {
    marginBottom: "10px",
    "& li.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "2px",
      "& .MuiListItemIcon-root": {
        minWidth: "42px !important",
        width: "42px !important",
        [theme.breakpoints.down(599)]: {
          minWidth: "34px !important",
          width: "34px !important",
        },
      },
    },
  },
  li: {
    marginBottom: "10px",
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "2px",
      "& .MuiListItemIcon-root": {
        minWidth: "42px",
        [theme.breakpoints.down(599)]: {
          minWidth: "34px",
          "& img": {
            width: "auto",
          },
        },
      },
    },
  },
  couponstextt: {
    fontSize: 18,
    fontWeight: "500",
    color: "#d2d2d5",
    letterSpacing: 1,
    marginBottom: 3,
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
  mycoupons: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "30px",
    marginBottom: "25px",
    border: "1px dashed #166bfa",
    "& h6": {
      marginBottom: "15px",
    },
  },
  mycoupons1: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "30px",
    marginBottom: "25px",
    border: "1px dashed #fff",
    "& h6": {
      marginBottom: "15px",
    },
  },
  pricingcontent2: {
    display: "inline-block",
    [theme.breakpoints.down(959)]: {
      justifyContent: "center",
      display: "flex",
      paddingTop: "11px",
    },
  },
  pricingcontent1: {
    display: "inline-block",
  },
  mydialog: {
    background: "#1872ff",

    padding: "20px 25px",
    "& h2.MuiTypography-h6": {
      color: "#fff",
      marginBottom: "0px",
      fontSize: "22px",
    },
  },
  dialogpaper: {
    "& .MuiPaper-rounded ": {
      borderRadius: "20px",
    },
  },
  dialogcontnet: {
    padding: "5px 25px",
  },
  dialogaction: {
    padding: "10px 17px",
    backgroundColor: "#f9f9f9",
  },
  dividerimg: {
    height: 20,
    width: "auto",
    marginTop: "30px",
    [theme.breakpoints.down(1200)]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down(959)]: {
      display: "none",
    },
  },

  pagetitle: {
    lineHeight: "30px !important",
    fontSize: "32px !important",
    [theme.breakpoints.down(1200)]: {
      fontSize: "28px !important",
      fontWeight: "500",
    },
    [theme.breakpoints.down(959)]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.down(599)]: {
      fontSize: "20px !important",
      marginBottom: "0px",
    },
  },
  titlebox: {
    textAlign: "center",
    marginBottom: "24px",
    paddingTop: "50px",
    [theme.breakpoints.down(1200)]: {
      paddingTop: "35px",
      marginBottom: "10px",
    },
    [theme.breakpoints.down(959)]: {
      paddingTop: "26px",
      marginBottom: "10px",
    },
    [theme.breakpoints.down(599)]: {
      paddingTop: "33px",
      marginBottom: "5px",
    },
  },
}));
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginLeft: theme.spacing(1),
    marginTop: "6px",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: colors.green,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: colors.green,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },

  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Pricing() {
  const classes = useStyles();

  const [state1, setState1] = React.useState({
    checked1: true,
    checked2: true,
    checked3: true,
    checked4: true,
    checked5: true,
    checked6: true,
    checked7: true,
  });
  const emptyObj = {
    user_id: "",
    password: "",
  };
  const [plans, setPlans] = React.useState([]);
  const [validity, setValidity] = React.useState([]);
  const [selectedVal, setSelectedVal] = React.useState([]);
  const [discPrice, setDiscPrice] = React.useState([]);
  const [couponPer, setCouponPer] = React.useState([]);
  const [openPasswordPopUp, setOpenPasswordPopUp] = React.useState(false);
  const [PopUpMessage, setPopUpMessage] = React.useState("");
  const [passwordFormData, setPasswordFormData] = React.useState(emptyObj);
  const [planShow, setPlanShow] = React.useState("");
  const [purchaseBtnShow, setPurchaseBtnShow] = React.useState("");
  //const [passwordData, setPasswordData] = React.useState([]);
  const history = useHistory();
  let [payInfo, setPayInfo] = React.useState([]);
  const updatePlanList = (pwd) => {
    axios
      .get(getDomain() + "/pricing-plan-list?pwd=" + pwd, {
        headers: header(),
      })
      .then(function (response) {
        console.log(response.data);
        response.data[1].forEach((element, index) => {
          let payObj = {
            index: 0,
            planId: element.id,
            planPriceId: element.options[0].id,
            planPrice: element.options[0].plan_price,
            coupon: "",
            couponApply: false,
          };
          setPayInfo((arr) => [...arr, payObj]);
          //---------------------------------
          let opVal = [];
          element.options.map((e, key) => {
            opVal[key] = e.validity;
          });
          setValidity((arr) => [...arr, [...new Set(opVal)]]);
          setSelectedVal((arr2) => [...arr2, opVal[0]]);
          //--------------------------------
        });
        setPlans(response.data[1]);
        //setPasswordData(response.data[2]);
        //console.log(response.data[2].password_status);
		if(response.data[2] && response.data[2].btnenabled === 1)
		{
			setPurchaseBtnShow(true);
		}
		else{
			setPurchaseBtnShow(false);
		}
        if (response.data && response.data[1].length > 0) {
          setPlanShow("");
        } else if (response.data[2].password_status === "enabled") {
          setOpenPasswordPopUp(true);
          setPlanShow(true);
        } else {
          showMessage("Something went wrong, please try again later.", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
        showMessage("Something went wrong, please try again later.", "error");
      });
  };
  useEffect(() => {
    //passwordExpire();
    updatePlanList("");
  }, []);
  const eventHandler = (e) => {
    setPasswordFormData({ ...passwordFormData, password: e.target.value });
  };
  const addPasswordSubmit = () => {
    if (passwordFormData.password != "") {
      //let pass = passwordData.password;
      //pass = pass.slice(10, pass.length - 1);
      if (passwordFormData.password) {
        updatePlanList(passwordFormData.password);
        // == btoa(passwordFormData.password)
        //localStorage.setItem("pricing_password", passwordFormData.password);
        //localStorage.setItem("pricing_password_time", new Date());
        handleClosePasswordPopUp();
        // showMessage("Authenticated successfully.");
        // setPlanShow("");
      } else {
        showMessage("Authentication failed.", "error");
        setOpenPasswordPopUp(true);
        setPlanShow(true);
      }
    } else {
      showMessage("Please Enter Password.", "error");
    }
  };
  const handleClosePasswordPopUp = () => {
    setOpenPasswordPopUp(false);
    setPasswordFormData(emptyObj);
  };
  const priceHandler = (e) => {
    const value = parseInt(e.target.value);
    const id = parseInt(e.target.id);
    let payObj = {
      index: value,
      planId: plans[id].id,
      planPriceId: plans[id].options[value].id,
      planPrice: plans[id].options[value].plan_price,
      coupon: payInfo[id].coupon,
      couponApply: payInfo[id].couponApply,
    };
    let newArr = [...payInfo];
    newArr[id] = payObj;
    setPayInfo(newArr);
  };

  const handleChangecheckbox = (event) => {
    setState1({ ...state1, [event.target.name]: event.target.checked });
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const buy = (key) => {
    if (user_id()) {
      // let params =
        // "?plan_price_id=" +
        // payInfo[key].planPriceId +
        // "&user_id=" +
        // user_id() +
        // "&method=buy";
	  let coupon = "";	
      if (payInfo[key].couponApply === true) {
        coupon = payInfo[key].coupon;
      }
      // window.location.href = getDomain() + "/buy-plan" + params;
	   axios
        .post(getDomain() + "/buy-plan",{ plan_price_id: payInfo[key].planPriceId,user_id: user_id(),method:'buy',coupon: coupon},{
           headers: header(),
         })
        .then(function (response) {
         // console.log(response.data.data.stripe_key,2222222222);
          if (response.data.status === true) {
			if(response.data.type === "stripe"){
				let stripe = window.Stripe(response.data.data.stripe_key);  
				stripe.redirectToCheckout({
				  sessionId: response.data.data.session_id,
				 })
				.then(function (result) {
				   if (result.error) {
				   alert(result.error);
				   }
				});	
		   }else if(response.data.type === "paypal"){
			    window.location.href = response.data.payurl;
		   }
          }
		  else
		  {
			showMessage(response.data.message, "error");  
		  }
        })
        .catch(function (error) {
       //  console.log(error,22222222);
          showMessage("Something went wrong, please try again later.", "error");
        });
    }
    else {
      history.push("login");
    }
  };
  const couponUpdate = (e) => {
    const id = parseInt(e.target.name);
    let value = "";
    if (payInfo[id].couponApply) {
      value = payInfo[id].coupon;
    } else {
      value = e.target.value;
    }
    let payObj = {
      index: payInfo[id].index,
      planId: payInfo[id].planId,
      planPriceId: payInfo[id].planPriceId,
      planPrice: payInfo[id].planPrice,
      coupon: value,
      couponApply: payInfo[id].couponApply,
    };
    let newArr = [...payInfo];
    newArr[id] = payObj;
    setPayInfo(newArr);
  };
  const deleteCoupon = (key) => {
    let payObj = {
      index: payInfo[key].index,
      planId: payInfo[key].planId,
      planPriceId: payInfo[key].planPriceId,
      planPrice: payInfo[key].planPrice,
      coupon: "",
      couponApply: false,
    };
    let newArr = [...payInfo];
    newArr[key] = payObj;
    setPayInfo(newArr);
  };
  const applyCoupon = (key) => {
	if(user_id()){  
    if (payInfo[key].coupon != "") {
      let payObj = {
        index: payInfo[key].index,
        planId: payInfo[key].planId,
        planPriceId: payInfo[key].planPriceId,
        planPrice: payInfo[key].planPrice,
        coupon: payInfo[key].coupon,
        couponApply: payInfo[key].couponApply,
      };
      let newArr = [...payInfo];
      axios
        .post(getDomain() + "/coupon-check", { coupon: payInfo[key].coupon },{
          headers: header(),
         })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status === true) {
            payObj.couponApply = true;
            newArr[key] = payObj;
            setPayInfo(newArr);
            //-------coupon---------
            let amount = payInfo[key].planPrice;
            let coupon_amt = (amount * response.data.amount) / 100;
            amount = amount - coupon_amt;
            let newDisArr = [...discPrice];
            let newCouArr = [...couponPer];
            newDisArr[key] = amount;
            newCouArr[key] = response.data.amount;
            setDiscPrice(newDisArr);
            setCouponPer(newCouArr);
            //----------------------
            showMessage("Coupon Applied", "success");
          } else {
            payObj.couponApply = false;
            newArr[key] = payObj;
            setPayInfo(newArr);
            showMessage("Please Enter valid coupon", "error");
          }
        })
        .catch(function (error) {
          console.log(error);
          showMessage("Something went wrong, please try again later.", "error");
        });
    } else {
      showMessage("Please Enter valid coupon", "error");
    }
	}else{
		history.push("login");
	}
  };
  const validityHandler = (e, key) => {
    let newArr = [...selectedVal];
    newArr[key] = e.target.value;
    setSelectedVal(newArr);
    console.log(plans[key].options, e.target.value);
    let len = plans[key].options.length;
    let i = 0;
    for (i = 0; i < len; i++) {
      if (plans[key].options[i].validity == e.target.value) {
        let payObj = {
          index: payInfo[key].index,
          planId: payInfo[key].planId,
          planPriceId: plans[key].options[i].id,
          planPrice: plans[key].options[i].plan_price,
          coupon: payInfo[key].coupon,
          couponApply: payInfo[key].couponApply,
        };
        let newArr = [...payInfo];
        newArr[key] = payObj;
        setPayInfo(newArr);
        break;
      }
    }
  };
  //-------------------------------------------
  const [alertOpen, setAlertOpen] = React.useState(false);
  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  function showMessage(newMessage, newSeverity) {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setAlertOpen(true);
  }
  //----------------------------------------

  return (
    <>
      <Top />
      <div className={classes.drawerHeader} />
      <main
        className={classes.content}
        style={{
          overflow: "hidden",
        }}
      >
        <div
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image1})`,
          }}
        >
          <Box className={classes.titlebox}>
            <Typography variant="h3" className={classes.pagetitle}>
              Pricing
            </Typography>
            <img
              src={dividerimage}
              alt="no img"
              className={classes.dividerimg}
            />
          </Box>
        </div>
        <CustomAlert
          open={alertOpen}
          severity={severity}
          message={message}
          handleClose={alertHandleClose}
        />
        <Box className={classes.logoesbox}>
          <Box className={classes.logoesboxInner}>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked1}
                name="checked1"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand1.png")}
                alt="no img"
              />
            </Box>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked2}
                name="checked2"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand2.png")}
                alt="no img"
              />
            </Box>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked3}
                name="checked3"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand3.png")}
                alt="no img"
              />
            </Box>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked4}
                name="checked4"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand4.png")}
                alt="no img"
              />
            </Box>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked5}
                name="checked5"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand5.png")}
                alt="no img"
              />
            </Box>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked6}
                name="checked6"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand6.png")}
                alt="no img"
              />
            </Box>
            <Box className={classes.brandbox}>
              <Checkbox
                checked={state1.checked7}
                name="checked7"
                onChange={handleChangecheckbox}
              />
              <img
                src={require("../../assets/images/brand7.png")}
                alt="no img"
              />
            </Box>
          </Box>
        </Box>
        <Container
          maxWidth="lg"
          width="1200px"
          margin="0px auto"
          component="main"
        >
          <Grid container spacing={5} alignItems="flex-end">
            {/*--------------------------------------------------------------------------------*/}
            {planShow == ""
              ? plans.map((element, key) => {
                  return (
                    <Grid item xs={12} sm={12} md={4}>
                      <Card
                        className={
                          key === 1 ? classes.cardcolor1 : classes.cardcolor
                        }
                      >
                        <CardHeader
                          title={element.title}
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          className={
                            key === 1 ? classes.cardHeader1 : classes.cardHeader
                          }
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <Typography
                              className={
                                key === 1
                                  ? classes.subheader1
                                  : classes.subheader
                              }
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: element.body,
                                }}
                              />
                            </Typography>
                          </div>
                          <NativeSelect
                            value={selectedVal[key]}
                            onChange={(e) => validityHandler(e, key)}
                            variant="filled"
                            className={
                              key === 1
                                ? classes.nativeslect1
                                : classes.nativeslect
                            }
                            disableUnderline
                          >
                            {validity[key].map((ee, ii) => {
                              return <option value={ee}>{ee} Days</option>;
                            })}
                          </NativeSelect>
                          <NativeSelect
                            id={key}
                            value={payInfo[key].index}
                            onChange={priceHandler}
                            variant="filled"
                            className={
                              key === 1
                                ? classes.nativeslect1
                                : classes.nativeslect
                            }
                            disableUnderline
                          >
                            {element.options.map((element2, key2) => {
                              if (selectedVal[key] == element2.validity) {
                                return (
                                  <option value={key2}>
                                    {" "}
                                    ${element2.plan_price}-{element2.plan_data}{" "}
                                    GB / {element2.no_proxy} IPS |{" "}
                                    {element2.validity} Days
                                  </option>
                                );
                              }
                            })}
                          </NativeSelect>

                          <Box textAlign="center">
                            <Typography
                              variant="h3"
                              className={
                                key === 1
                                  ? classes.pricsecolor1
                                  : classes.pricsecolor
                              }
                            >
                              $
                              {payInfo[key].couponApply
                                ? discPrice[key]
                                : payInfo[key].planPrice}
                            </Typography>
                          </Box>
                          <Box
                            className={
                              key === 1 ? classes.mycoupons1 : classes.mycoupons
                            }
                          >
                            <Typography
                              className={classes.couponstextt}
                              style={
                                key === 1
                                  ? { color: colors.white }
                                  : { color: colors.skyblue }
                              }
                            >
                              Coupon Code
                            </Typography>
                            <Box display="flex">
                              <input
                                type="text"
                                name={key}
                                value={payInfo[key].coupon}
                                placeholder="Type here"
                                className={
                                  key === 1
                                    ? classes.inputbox1
                                    : classes.inputbox
                                }
                                onChange={couponUpdate}
                              />
                              <Button
                                variant="contained"
                                disabled={payInfo[key].couponApply}
                                className={
                                  key === 1
                                    ? classes.applybox1
                                    : classes.applybox
                                }
                                onClick={(e) => applyCoupon(key)}
                              >
                                Apply
                              </Button>
                              {payInfo[key].couponApply ? (
                                <IconButton
                                  onClick={(e) => deleteCoupon(key)}
                                  style={{ padding: "0px 0px 0px 2px" }}
                                >
                                  <DeleteIcon
                                    size="large"
                                    style={
                                      key === 1
                                        ? {
                                            color: colors.white,
                                            marginTop: "6px",
                                            fontSize: 30,
                                          }
                                        : {
                                            color: colors.skyblue,
                                            marginTop: "6px",
                                            fontSize: 30,
                                          }
                                    }
                                  />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </Box>
                            {payInfo[key].couponApply ? (
                              <Typography
                                variant="h5"
                                className={
                                  key === 1
                                    ? classes.autonewaltext1
                                    : classes.autonewaltext
                                }
                              >
                                Coupon Applied {couponPer[key]}%
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Box>
                          {/*
                          <Box className={classes.autorenewalbox}>
                             <Typography variant="h5" className={key===1?classes.autonewaltext1:classes.autonewaltext}>Auto-Renewal Payment</Typography>
                          <FormControlLabel
       control={<IOSSwitch checked={false} name="checkedA" />}
                             />
                              <IconButton onClick={handleClickOpen} style={{padding:"0px 0px 0px 2px"}}>
                             <HelpIcon size="large" style={key===1?{color: colors.white,marginTop:"6px",fontSize:30}:{color: colors.skyblue,marginTop:"6px",fontSize:30}}/>
                             </IconButton>
                             </Box>
                            */}
                        </CardContent>
                        <CardActions>
                          {element.limit_proxy_data > 0 && purchaseBtnShow? (
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.btntext}
                              onClick={(e) => buy(key)}
                            >
                              Buy Now
                            </Button>
                          ) : (
                            <Button
                              fullWidth
                              variant="contained"
                              className={classes.btntext}
                              disabled={true}
                              style={{ color: "white" }}
                            >
                              Sold Out
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })
              : ""}
            {/*--------------------------------------------------------------------------------*/}
          </Grid>
          <Box className={classes.mailbox}>
            <Typography className={classes.textmail}>
              Currently, private release proxies are only valid for U.S.
            </Typography>

            <Typography className={classes.textmail}>
              <span className={classes.pricingcontent1}>
                {" "}
                Have questions? Open a ticket in Discord or send an email to
              </span>{" "}
              <span className={classes.pricingcontent2}>
                <a className={classes.maillink}>
                  <EmailIcon color="secondary" />
                  team@liveproxies.io
                </a>
              </span>
            </Typography>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            className={classes.dialogpaper}
          >
            <DialogTitle className={classes.mydialog}>Auto Renewal</DialogTitle>
            <DialogContent className={classes.dialogcontnet}>
              <DialogContentText>
                <p>
                  Auto-Renewal signifies your premium subscription will be
                  regularly renewed at the end of your package's expiry date or
                  if you reach your data limit earlier than your package's
                  expiry date.
                </p>
                <p>
                  You can always cancel your auto-recurring payments in your
                  PayPal account.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogaction}>
              <Button onClick={handleClose} color="secondary">
                close
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
        <Footer1 />
        <Dialog open={openPasswordPopUp} className={classes.dialogpaper}>
          <DialogTitle className={classes.mydialog}>
            Authentication Password
          </DialogTitle>
          <DialogContent className={classes.dialogcontnet}>
            {
              <DialogContentText>
                <Box
                  marginTop={2}
                  justifyContent="space-between"
                  display="flex"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h2" marginBottom={5}>
                        Enter Password
                      </Typography>
                      <Input
                        type="text"
                        name="pricing_password"
                        onChange={eventHandler}
                        disableUnderline
                        fullWidth
                        className={classes.inputPassword}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  marginTop={2}
                  justifyContent="space-between"
                  display="flex"
                >
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext1}
                      size="small"
                      onClick={addPasswordSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </DialogContentText>
            }
          </DialogContent>
          <DialogActions className={classes.dialogaction}></DialogActions>
        </Dialog>
      </main>
    </>
  );
}
export default Pricing;
