import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
 
    root1: {
        
  },
  nested: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  
  MuiListItem: {
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 12,
    paddingBottom: 12,
  },
  listItemText: {
    color: "#d0dad1",
    letterSpacing: 1,
    fontSize: 14.5,
    marginLeft: 0,
    
  },
  
  iconcolor: {
    color: "#d0dad1",
    fontSize: 20,
  },
  
  dividerspace: {
    marginBottom: 8,
  },
  link1: {
    
    margin: "13px 26px 20px 26px !important",
    color: "#fff",
    fontSize: 15,
    fontWeight: "500px",
    textDecoration: "none",
    borderRadius: "25px",
    letterSpacing: 0.6,
    fontFamily: "Poppins",
    display: "block",
    textAlign:"center",
    padding: "6px 25px",
      background: "rgb(23,111,254)",
background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )"
    
  },
}));

export default function ListItem1() {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root1}>
      <Divider className={classes.dividerspace} />
      <ListItem button className={classes.MuiListItem} onClick={() => history.push("/")} >         
        <ListItemText
          primary="Home"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
          <Divider />
           <ListItem button className={classes.MuiListItem} onClick={() => history.push("/pricing")} >
         
        <ListItemText
          primary="Pricing"
          classes={{ primary: classes.listItemText }}
        />
          </ListItem>
          <Divider />
              <Divider />
           <ListItem button className={classes.MuiListItem} onClick={() => history.push("/faq")} >
         
        <ListItemText
          primary="FAQ" 
          classes={{ primary: classes.listItemText }}
        />
          </ListItem>
		<ListItem button className={classes.MuiListItem} onClick={() => history.push("/ProxyTester")} >
		<ListItemText primary="ProxyTester" classes={{ primary: classes.listItemText }}/>
        </ListItem>
          <Divider />
          <Divider />
          <ListItem button className={classes.MuiListItem} onClick={() => history.push("/dashboard")} >
             <ListItemText 
               primary="Dashboard" 
               classes={{ primary: classes.listItemText }} />
          </ListItem >
        <Divider />
        <Button href="/login" variant="contained" className={classes.link1}>
        Login
          </Button>
      <Divider />
      
     
    </div>
  );
}
