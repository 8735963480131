import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core//Grid";
import { useHistory } from "react-router-dom";
import RDrawer from "../components/Top";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },
  title1: {
    justifyContent: "flex-end",
    display: "flex",
    marginBottom: 5,
  },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 5,
    marginBottom: 10,
  },
  button: {
    marginRight: 10,
    textTransform: "none",
    letterSpacing: 1,
  },
  paper: {
    background: "#e9e9e9",

    borderRadius: 15,
    padding: "10px 20px 20px 20px",
    marginTop: 10,
    paddingBottom: 20,
    boxShadow: "none",
  },
  btntext1: {
    fontSize: 14,
    fontWeight: "500",
    borderRadius: 10,
    letterSpacing: 1,
    textTransform: "none",
    padding: "7px 15px",
    marginLeft: 10,
  },
  input: {
    borderRadius: 5,
    border: "1px solid",
    borderColor: "#d2d4d8",
    color: "#1d2255",
    fontSize: 14,
    padding: "5px",
    pointerEvent: "none",
    paddingLeft: "10px",
    boxShadow: "none",
  },
}));

export default function Viewplan({ navigation }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="100%" className={classes.container}>
          <Box className={classes.title1}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.btntext1}
              onClick={() => history.push("/admin/Viewplans")}
            >
              Active Plans
            </Button>
          </Box>
          <Paper className={classes.paper}>
            <Typography className={classes.titleplan} color="secondary">
              Cool 50
            </Typography>
            <Box maxWidth={610}>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Plan Name</Typography>
                    <Input
                      defaultValue="Cool 50"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Data</Typography>
                    <Input
                      defaultValue="4 BG"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Duration</Typography>
                    <Input
                      defaultValue="30 Days"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2"># Proxy</Typography>
                    <Input
                      defaultValue="50"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Availability</Typography>
                    <Input
                      defaultValue="100"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2"># Sold</Typography>
                    <Input
                      defaultValue="100"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Status</Typography>
                    <Input
                      defaultValue="Active"
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                      disabled
                    />
                  </Grid>
                  
                </Grid>
              </form>
            </Box>
          </Paper>
          <Box marginTop={5}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={() => history.push("/admin/Viewplans")}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={() => history.push("/admin/Editplan")}
            >
              Edit
            </Button>
          </Box>
        </Container>
      </main>
    </div>
  );
}
