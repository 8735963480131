import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DashboardTop from '../components/Dashoboard-top'
import { Card, Paper,CardContent,CardHeader } from "@material-ui/core";
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from "@material-ui/core/Button";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { colors,bluegradient } from '../components/colors'
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import DashboardFooter from '../components/DashboardFooter';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Input } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(93,93,93,0.10)",
      outline: "0px solid rgba(93,93,93,0.10)",
    },
  },
   content: {
    flexGrow: 1,
     backgroundColor: "#f1f2f6",
     position:"relative", 
     height:"calc(100% - 195px)",
    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor:"#151948",
    justifyContent: 'flex-end',
     minHeight: "90px",
     [theme.breakpoints.down(1200)]: {
      minHeight: "65px",
      height: "65px",
     }
    },
    container1: {
        padding: "20px 30px 0px",
        margin: "0px",
        maxWidth:"100%",
        position:"relative",
        height:"calc(100% - 65px)",
        [theme.breakpoints.down(1200)]: {
          padding: "15px 20px 0px",
        },
    },

    leftCont:{
      position:"relative", 
      height:"calc(100% - -14px)",
     },
     rightCont:{
      position:"relative", 
      height:"calc(100% - -14px)",
      overflow:"auto",
     },
     rightContScroll:{
      height:"calc(100% - -21px)",
      overflow:"auto",
    },

     leftContScroll:{
        position:"relative", 
        height:"calc(100% - -14px)",
        
      },
    imageSrc: {
    height: "150px",
    width:"auto"
  },
     

       
  
    nativeslect: {
        backgroundColor: "#fff",
        color: "#65708f",
        paddingLeft: "5px",
        paddingRight: "10px",
        fontSize: 15,
        fontWeight: "400",
        borderRadius: 25,
        letterSpacing:0.2,
        marginLeft: "30px",
        height: 44,
        "& .MuiNativeSelect-icon": {
            color: "#65708f",
        },
        "& .MuiNativeSelect-iconFilled": {
            right:"12px"
        },
          "& .MuiNativeSelect-select:focus": {
            backgroundColor: "#fff"
        },
         "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
           backgroundColor: "#fff",
           paddingLeft:"20px"
        },
        "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
           paddingLeft:"20px"
        },
        [theme.breakpoints.down(1200)]: {
          marginLeft:"20px",
          paddingLeft:"0px",
          paddingRight:"0px",
          "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
            paddingLeft:"18px",
            fontSize:"13px",
            height:"40px"
          },
       },
       [theme.breakpoints.down(1200)]: {
        height:"40px",
        fontSize:"13px",
        marginLeft:"20px",
        marginRight:"-7px",
        "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
          paddingLeft:"12px",
          paddingRight:"20px",
        },
        "& .MuiNativeSelect-iconFilled": {
          right:"8px"
        },
       },

  },
  myselect1: {
    width:"130px",
    [theme.breakpoints.down(1200)]: {
      width: "144px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom:"15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
},
   myselect2: {
    width:"183px",
    [theme.breakpoints.down(1200)]: {
      width: "190px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom:"15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
},
    myselect3: {
    width:"135px",
    [theme.breakpoints.down(1200)]: {
      width: "111px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom:"15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
},
     myselect4: {
    width:"135px",
    [theme.breakpoints.down(1200)]: {
      width: "100px",
    },
    [theme.breakpoints.down(767)]: {
      width: "200px",
      marginBottom:"15px;"
    },
    [theme.breakpoints.down(450)]: {
      width: "calc(100% - 40px)"
    },
},
  myselect5: {
        backgroundColor: "#fff",
        color: "#65708f",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontSize: 15,
        fontWeight: "400",
        borderRadius: 25,
        letterSpacing:0.2,
        marginLeft: "30px",
        height: 44,
        width:"135px",
        [theme.breakpoints.down(1200)]: {
          width: "120px",
          marginLeft: "20px",
          height: "40",
        },
        [theme.breakpoints.down(767)]: {
          width: "200px",
          marginBottom:"15px;"
        },
        [theme.breakpoints.down(450)]: {
          width: "calc(100% - 40px)"
        },
},
proxybtnn: {
    marginRight: "30px",
    [theme.breakpoints.down(1200)]: {
      paddingRight: "0px",
    },
    [theme.breakpoints.down(959)]: {
      padding: "20px 20px 0px",
    },
    [theme.breakpoints.down(767)]: {
      padding: "0px 20px 0px",
      marginRight:"0px"
    },
  },
   proxiess: {
     background: "rgb(23,111,254)",
     background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
     background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
     color: "#cde8fd",
     fontSize: 13,
     fontWeight: "500",
     letterSpacing: 0.3,
     lineHeight: "44px",
     height: "44px",
     borderRadius: "40px",
     padding: "6px 25px",
     [theme.breakpoints.down(1200)]: {
      height:"40px",
      padding: "6px 20px"
      },
      [theme.breakpoints.down(767)]: {
        width:"200px",
       },
       [theme.breakpoints.down(450)]: {
         width:"100%",
      },
    },
    proxytext: {
        color: "#217cfd",
        fontSize: 20,
        fontWeight: "500",
        letterSpacing: 0.3,
        fontFamily: "Poppins",
        padding:"21px 0px 18px",
        [theme.breakpoints.down(1200)]: {
          fontSize: 18,
       }
    },
    
    tblcell: {
        color: "#cde8fd",
        fontSize: 14,
        fontWeight: "500",
        fontFamily: "Poppins",
         border:"none"
    },
      tblcell1: {
         color: "#3a4468",
        fontSize: 14,
        fontWeight: "400",
          fontFamily: "Poppins",
          border: "none",
        
    },
    
    paper: {
         maxWidth: "98%",
        boxShadow: "none",
       
    },
    Cardheader: {
      padding: "17px 30px 0px",
      "& .MuiTypography-h5": {
          fontSize: "20px",
          color: "#fff",
            marginTop: "10px",
            [theme.breakpoints.down(1200)]: {
            padding: "0",
            fontSize: "18px",
            margin:"0"
          },
        },
        [theme.breakpoints.down(1200)]: {
          padding: "17px 20px 0px", 
        },
      },
      Cardheader2: {
        padding: "17px 30px 0px",
         "& .MuiTypography-h5": {
             fontSize: "20px",
               color: "#217cfd",
              marginTop: "10px",
              [theme.breakpoints.down(1200)]: {
               padding: "0px",
               margin: "0px",
               fontSize: "18px",
             },   
             
          }
     },
     Cardheader1:{
      fontSize: "20px",
      marginTop:"10px",
      color: "#217cfd",
      letterSpacing: 1,
      fontFamily:"Poppins",
      [theme.breakpoints.down(1200)]: {
        fontSize: "18px",        
        marginTop: "4px"
      }     
         
        },
     paper1: {
         maxWidth: "100%",
        boxShadow: "none",
       
    },
    btnnew: {
        backgroundColor: "#207003", height: 25, borderRadius: 25, color: "#fff", marginLeft: 5,
        textTransform: "none",
        fontSize: 14,
       letterSpacing:0.4
    },
    cardd: {
        background: "rgb(23,111,254)",
  background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
  background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
  background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
  filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",

    marginTop: 4,
    boxShadow:"none",
    borderRadius: "20px",
    "& .MuiCardContent-root": {
    padding: "20px 30px 15px"
    },
    [theme.breakpoints.down(1200)]: {
      "& .MuiCardContent-root": {
        padding: "16px 20px 15px"
        },
    }

    },
    cardd2: {
      backgroundColor: "#fff",
      boxShadow:"none",
      marginTop: 4,
      borderRadius: "20px",
      "& .MuiCardContent-root": {
      padding: "20px 30px 15px"
  },
  [theme.breakpoints.down(1200)]: {
    "& .MuiCardContent-root": {
      padding: "20px 20px 15px"
      },
  }
  },
  cardcontentplan: {
    padding: "16px 30px",
    "& .MuiPaper-elevation1": {
       boxShadow:"none",
    },
      "& table": {
        borderCollapse: "collapse",
        border: "1px solid #c2c4d0",
     "& th": {
        border: "1px solid #c2c4d0",
    },
    "& td": {
        border: "1px solid #c2c4d0",
     },  
  },
  [theme.breakpoints.down(1200)]: {
    padding: "17px 20px 0px",
 },
    },
    cardplan: {
      backgroundColor: "#fff",
      marginTop: "0px",
      height:350,
      borderRadius: "20px",
      height:"calc(100% - 0px)",
      overflow:"auto",
      boxShadow: "none" ,
      [theme.breakpoints.down(1200)]: {
        height:"calc(100% - 20px)",
     },
    },
    packgetext: {
        color: "#1f2f47",
        fontSize: 16,
        fontWeight: "500",
        fontFamily:"poppins",
        [theme.breakpoints.down(1200)]: {
          fontSize: 15,
       }
    },
    packgetext1: {
        color: "#3f4a6b",
        fontSize: 16,
        fontWeight: "500",
        fontFamily:"poppins",
        [theme.breakpoints.down(1200)]: {
          fontSize: 15,
       }
    },
    ambitiontext: {
        color: "#cae2fe",
        fontSize: 16,
        fontWeight: "500",
        fontFamily: "Poppins",
        marginBottom:10,
        [theme.breakpoints.down(1200)]: {
          fontSize: 15,
          marginBottom:5,
          marginTop:0,
       }
    },
    ambitiontext1: {
        color: "#7f8292",
         fontSize: 16,
        fontWeight: "400",
        fontFamily: "Poppins",
         marginBottom:10,
         [theme.breakpoints.down(1200)]: {
          marginBottom:5,
          fontSize: 15,
       }
  },
  ambitiontext2: {
       color: "#7f8292",
         fontSize: 13,
        fontWeight: "300",
        fontFamily: "Poppins",
         marginBottom:"2px",
         whiteSpace:"nowrap"
  },
  lightfooter: {
    
      backgroundColor:"#e2e3e9 ! important"
   
    
  },
    tableheadplan: {
      background: "rgb(23,111,254)",
     background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
     background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )",
   
         borderRadius:"20px"
    },
    tblbodyplan: {
         backgroundColor:"#e2e3e9" 
  },
  paperclass: {
    backgroundColor: "#fff",
    width: "100%",
    padding: "0px 30px",
    marginTop: "4px",
    borderRadius:"18px",
    boxShadow: "none",
    position:"relative", 
    height:"calc(100% - 0px)",
      [theme.breakpoints.down(1200)]: {
         padding: "0px 20px",
      }
  },
  copybtn: {
    borderTop: "1px dashed #707ca0", textAlign: "right",
    "& .MuiIconButton-root": {
      margin: "10px 0px 10px 10px",
      padding:"8px",
      border: "1px solid #18a0fb",
      "& svg": {
        fontSize:"17px"
      }
    }
  },
  generateproxy: {
    borderTop: "1px dashed #707ca0",
    padding: "22px 0px 0",
    height:"calc(100% - 126px)",
    position:"relative",
    overflow:"auto",
    [theme.breakpoints.down(1200)]: {
      height:"540px"
   },
   [theme.breakpoints.down(959)]: {
    height:"320px"
   }
  },
  cardheaderbox: {
    justifyContent:"space-between", display:"flex", padding:"20px 30px 4px",
    [theme.breakpoints.down(1200)]: {
      padding: "20px 20px 4px",
   },
  },
  proxybox: {
    padding: "20px 0px 0px",
    [theme.breakpoints.down(1200)]: {
      padding: "15px 0px 0px",
   }
  },
  selectOut:{
    display:"flex",
    paddingTop:"20px",
    [theme.breakpoints.down(959)]: {  
      display:"block"
   }
  },

  loginFooter:{
    position:"fixed",
    width:"100%",
    bottom:"0",
    left:"0",
    [theme.breakpoints.down(1200)]: {
      position:"static",
     },
  },
  loginWrapper:{
    position:"absolute",
    top:"0",
    left:"0",
    width:"100%",
    height:"100%",
    background:"#f1f2f6",
    overflow:"auto",
    [theme.breakpoints.down(1200)]: {
     position:"static",
     overflow:"visible",
    },
  },
  paddingInn:{
    padding:"16px 16px 0px!important",
  } 
  
}));


const rows = [
   {
        id:"1",
        sampletitle: "sample",
         sampletitle1: "sample",
        sampletitle2: "sample",
        sampletitle3: "sample",
         sampletitle4: "sample",
       
    },
     {
        id:"2",
        sampletitle: "sample",
         sampletitle1: "sample",
        sampletitle2: "sample",
        sampletitle3: "sample",
         sampletitle4: "sample",
       
    },
      {
        id:"3",
         sampletitle: "sample",
         sampletitle1: "sample",
        sampletitle2: "sample",
        sampletitle3: "sample",
         sampletitle4: "sample",
       
    },
       {
        id:"4",
         sampletitle: "sample",
         sampletitle1: "sample",
        sampletitle2: "sample",
        sampletitle3: "sample",
         sampletitle4: "sample",
       
    },
        {
        id:"5",
       sampletitle: "sample",
         sampletitle1: "sample",
        sampletitle2: "sample",
        sampletitle3: "sample",
         sampletitle4: "sample",
       
    }
];
const plans = [
   {
        id:"1",
        plan: "Ambition 150",
         duration: "1 year",
        purchasedate: "06.01.2020",
        expdate: "06.01.2021",
        status: "active",
       
    },
     {
        id:"2",
          plan: "Ambition 150",
         duration: "1 year",
        purchasedate: "06.01.2020",
        expdate: "06.01.2021",
        status: "on hold",
       
    },
     {
        id:"3",
        plan: "Ambition 150",
         duration: "1 year",
        purchasedate: "06.01.2020",
        expdate: "06.01.2021",
        status: "active",
       
    },
     {
        id:"4",
          plan: "Ambition 150",
         duration: "1 year",
        purchasedate: "06.01.2020",
        expdate: "06.01.2021",
        status: "on hold",
       
    },
       {
        id:"5",
        plan: "Ambition 150",
         duration: "1 year",
        purchasedate: "06.01.2020",
        expdate: "06.01.2021",
        status: "active",
       
    },
     {
        id:"6",
          plan: "Ambition 150",
         duration: "1 year",
        purchasedate: "06.01.2020",
        expdate: "06.01.2021",
        status: "on hold",
       
    },
    
];
function Dashboardlight() {
    const classes = useStyles();
   
  const [networktype, setnetworktype] = React.useState("")
    const [authenticationtype,setauthenticationtype]=React.useState("")
  const [country,setcountry]=React.useState("")

  const handleChange = (event) => {
   setnetworktype(event.target.value)
    
  };
   const handleChange1 = (event) => {
   setauthenticationtype(event.target.value)
    
  };
  const handleChange2 = (event) => {
   setcountry(event.target.value)
    
  };

  return (
      <>
      <div className={classes.loginWrapper}>
          <DashboardTop />
          <div className={classes.drawerHeader}/>
          <main className={classes.content} >
                 
          <Box className={classes.selectOut} justifyContent="space-between">
                      <Box>
                            <NativeSelect
                              value={networktype}
                              onChange={handleChange}
                              variant="filled"
                             
                              className={clsx(classes.nativeslect,classes.myselect1)}
          disableUnderline
        >
        <option value="" disabled>Network</option>
          <option value="LAN">LAN</option>
                      <option value="WAN">WAN</option>
                          </NativeSelect>
                          <NativeSelect
                              value={authenticationtype}
                              onChange={handleChange1}
                              variant="filled"
                             
                              className={clsx(classes.nativeslect,classes.myselect2)}
          disableUnderline
        >
        <option value="" disabled>Authentication</option>
          <option value="test">Test</option>
                <option value="test1">Test1</option>
                          </NativeSelect>
                           <NativeSelect
                              value={country}
                              onChange={handleChange2}
                              variant="filled"
                             
                              className={clsx(classes.nativeslect,classes.myselect3)}
          disableUnderline
        >
        <option value="" disabled>Country</option>
          <option value="test">US</option>
                <option value="test1">US</option>
            </NativeSelect>
              <NativeSelect
                              value={country}
                              onChange={handleChange2}
                              variant="filled"
                             
             
               className={clsx(classes.nativeslect,classes.myselect4)}
          disableUnderline
        >
        <option value="" disabled>Type</option>
          <option value="test">US</option>
                <option value="test1">US</option>
                          </NativeSelect>
                          
                          <Input
                      placeholder="Quantity"
                      disableUnderline
                          inputProps={{ "aria-label": "description" }}
                          className={classes.myselect5}
                      /> 
                          
                          
                      </Box>
                      <Box className={classes.proxybtnn}>
                          <Button variant="contained" className={classes.proxiess}>
                              Genereate Proxies
                          </Button>
                          
                      </Box>
            </Box>
       
              <Container className={classes.container1}>
                 
              <Grid container spacing={4} className={classes.leftCont}>
              <Grid item xs={12} md={6} lg={6} className={classes.leftContScroll}>
              <Paper className={classes.paperclass}>
                <Typography className={classes.proxytext}>Generate Proxies</Typography>
                <Box className={classes.generateproxy}>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S218.liveproxies.io:33128:ewwe!a3:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S43.liveproxies.io:33128:we!a15:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S94.liveproxies.io:33128:ewe!a23:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S204.liveproxies.io:33128:we!a30:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S218.liveproxies.io:33128:ewwe!a3:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S43.liveproxies.io:33128:we!a15:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S94.liveproxies.io:33128:ewe!a23:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S204.liveproxies.io:33128:we!a30:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S218.liveproxies.io:33128:ewwe!a3:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S43.liveproxies.io:33128:we!a15:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S94.liveproxies.io:33128:ewe!a23:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S204.liveproxies.io:33128:we!a30:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S218.liveproxies.io:33128:ewwe!a3:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S43.liveproxies.io:33128:we!a15:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S94.liveproxies.io:33128:ewe!a23:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S204.liveproxies.io:33128:we!a30:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S194.cliveproxies.io:33128:mboE63A0!a46:pas1qIae</Typography>
                  <Typography className={classes.ambitiontext2}>snkrs-us-S49.liveproxies.io:33128:we!a13:pas1qIae</Typography>
               
                </Box>
                <Box className={classes.copybtn}>
                  <IconButton color="secondary" className={classes.copybtn}><LibraryBooksIcon/></IconButton>
                  <IconButton color="secondary" className={classes.copybtn}><ExitToAppIcon/></IconButton>
                </Box>
              </Paper>
              </Grid>
               
                     
                 
              <Grid item xs={12} md={6} lg={6} className={classes.rightCont}>
              <Grid container spacing={4} className={classes.rightContScroll}> 
                <Grid item xs={12} md={6} lg={6} sm={6}>
                          <Card className={classes.cardd}>
                               <CardHeader
                                  title="Active Plans"
                                  className={classes.Cardheader}
                                  titleTypographyProps={{ align: 'left' }} />
                              <CardContent>
                                <Box className={classes.proxybox} borderTop="1px dashed #fff">
                                      <Typography className={classes.packgetext}>Plan Name</Typography>
                                      <Typography className={classes.ambitiontext}>Ambition 150</Typography> 
                                       <Typography className={classes.packgetext}>Start Date</Typography>
                                      <Typography className={classes.ambitiontext}>06.01.2020</Typography> 
                                       <Typography className={classes.packgetext}>End Date</Typography>
                                      <Typography className={classes.ambitiontext}>06.01.2020</Typography> 
                                       <Typography className={classes.packgetext}>Remaining Data</Typography>
                                      <Typography className={classes.ambitiontext}>500 BG</Typography> 
                               </Box>
                              </CardContent>
                          </Card>
                              
                </Grid>
                 <Grid item xs={12} md={6} lg={6} sm={6}>
                          <Card className={classes.cardd2}>
                               <CardHeader
                          title="Standby Plans"
                                  titleTypographyProps={{ align: 'left' }}
                                  className={classes.Cardheader2}
                              />
                               <CardContent>
                              <Box className={classes.proxybox} borderTop="1px dashed #707ca0">
                                      <Typography className={classes.packgetext1}>Plan Name</Typography>  
                                      <Typography className={classes.ambitiontext1}>Confident 200</Typography>   
                                        <Typography className={classes.packgetext1}>Start Date</Typography>  
                                      <Typography className={classes.ambitiontext1}>06.01.2020</Typography>   
  <Typography className={classes.packgetext1}>End Date</Typography>  
                                      <Typography className={classes.ambitiontext1}>06.01.2020</Typography>   
  <Typography className={classes.packgetext1}>Remaining Data</Typography>  
                                      <Typography className={classes.ambitiontext1}>500 BG</Typography>   

                               </Box>
                              </CardContent>
                          </Card>
                              
                </Grid>
                <Grid item xs={12} md={12} lg={12} sm={12}  className={classes.paddingInn}>
                  <Card className={classes.cardplan}>
                    <Box className={classes.cardheaderbox}>
                      <Typography className={classes.Cardheader1}>Order History</Typography>
                      <Button variant="contained" className={classes.proxiess}>
                              Buy Plan
                          </Button>
                    </Box>
                              
                              
                               <CardContent className={classes.cardcontentplan}>
                                <Box paddingTop="17px" borderTop="1px dashed #707ca0">
                                    <TableContainer component={Paper}>
      <Table  size="small" aria-label="a dense table">
        <TableHead className={classes.tableheadplan}>
          <TableRow >
            <TableCell align="left" className={classes.tblcell}>Plan</TableCell>
            <TableCell align="left" className={classes.tblcell}>Duration</TableCell>
            <TableCell align="left" className={classes.tblcell}>Purchase Date</TableCell>
            <TableCell align="left" className={classes.tblcell}>Exp Date</TableCell>
            <TableCell align="left" className={classes.tblcell}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tblbodyplan}>
          {plans.map((plan) => (
            <TableRow key={plan.id}>
              <TableCell align="left" className={classes.tblcell1}>{plan.plan}</TableCell>
              <TableCell align="left" className={classes.tblcell1}>{plan.duration}</TableCell>
              <TableCell align="left" className={classes.tblcell1}>{plan.purchasedate}</TableCell>
              <TableCell align="left" className={classes.tblcell1}>{plan.expdate}</TableCell>
              <TableCell align="left" className={classes.tblcell1}>{plan.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
                          </TableContainer>   
                               </Box>
                              </CardContent>
                  </Card>
                  </Grid>
                            </Grid>  
                      </Grid>
                      </Grid>
        

        </Container>
        <div className={classes.loginFooter}>
        <div className={classes.lightfooter}>
          <DashboardFooter />
          </div>
          </div>
       </main>
       </div>  
      
    </>
  );
}
export default Dashboardlight;
