import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Listitem1 from './Listitme'
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import image1 from '../../assets/images/innerbanner.jpg';
import { isAuthenticated, isAdmin, logout,isSupport } from '../../auth';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      boxShadow: "none",
      borderBottom: "1px solid #1d1f3e",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }),
    boxShadow: "none",
    borderBottom: "1px solid #1d1f3e",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,

    }),

  },
  menuButton: {
    marginRight: "-3px",
    marginTop: "7px",
    float: "right",
    color: "#fff",
    paddingRight: "0",
    [theme.breakpoints.up(1200)]: {
      display: "none",
    }
  },

  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "none",
    backgroundColor: "#01092d",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  boxaraea: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  logo: {
    height: "auto",
    width: "150px",
    marginRight: "10px",
    marginTop: "6px",
    float: "left",
    marginBottom: "10px"
  },
  topMain: {
    [theme.breakpoints.down("1200")]: {
      padding: "0"
    },

  },

  toolbarTitle: {
    float: "right",
    marginTop: "13px",
  },
  link: {

    [theme.breakpoints.down("1200")]: {
      display: "none",
      textDecoration: "none",
    },
    margin: "13px 0px 0px 45px !important",
    color: "#FFF",
    fontSize: 15,
    fontWeight: "500px",
    textDecoration: "none",
    letterSpacing: 0.6,
    fontFamily: "Poppins",
    "&:hover": {
      textDecoration: "none",
      color: "#1778fe"
    }
  },
  link1: {
    [theme.breakpoints.down("1200")]: {
      display: "none",
      textDecoration: "none",
    },
    margin: "1px 0px 0px 45px !important",
    color: "#fff",
    fontSize: 15,
    fontWeight: "500px",
    textDecoration: "none",
    borderRadius: "25px",
    letterSpacing: 0.6,
    fontFamily: "Poppins",
    display: "inline-block",
    padding: "6px 25px",
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )"

  },
  maincontainer: {
    width: 1200,
    maxWidth: "100%",
    margin: "0px auto"
  },
  closicon: {
    color: "#fff"
  }

}));


export default function Top() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const admin = localStorage.getItem("admin");

  const handleDrawerOpen = () => {
    setOpen(true);

  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{
          backgroundImage: `url(${image1})`,
        }}
      >
        <Toolbar>
          <Container maxWidth="lg" width="1200px" margin="0px auto" className={classes.topMain} >
            <IconButton

              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Link href="/" className={clsx(classes.link, open && classes.hide)}><img src={require("../../assets/images/logo.png")} alt="img" className={classes.logo} /> </Link>



            <nav className={classes.toolbarTitle}>
              <Link variant="button" color="textPrimary" href="/" className={clsx(classes.link, open && classes.hide)}>
                Home
              </Link>
              <Link variant="button" color="textPrimary" href="/pricing" className={clsx(classes.link, open && classes.hide)}>
                Pricing
              </Link>
              <Link variant="button" color="textPrimary" href="/faq" className={clsx(classes.link, open && classes.hide)}>
                FAQ
              </Link>
              <Link variant="button" color="textPrimary" href="/ProxyTester" className={clsx(classes.link, open && classes.hide)}>
                Proxy Tester
              </Link>

              <Link variant="button" target="_blank" color="textPrimary" href="https://liveservers.io" className={clsx(classes.link, open && classes.hide)}>
                Live Servers
              </Link>
              <Link variant="button" color="textPrimary" href="/dashboard" className={clsx(classes.link, open && classes.hide)}>
                Dashboard
              </Link>
              {isAdmin() || isSupport() ?
                <Link variant="button" color="textPrimary" href="/admin" className={clsx(classes.link, open && classes.hide)}>
                  Admin Dashboard
                </Link>
                :
                ""
              }
              {isAuthenticated() ?
                <Button href="/" onClick={logout} variant="contained" className={classes.link1}>
                  logout
                </Button>
                :
                <Button href="/login" variant="contained" className={classes.link1}>
                  login
                </Button>
              }
            </nav>

          </Container>
        </Toolbar>

      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon className={classes.closicon} /> : <ChevronRightIcon className={classes.closicon} />}
          </IconButton>
        </div>
        <Divider />
        <Listitem1 />
      </Drawer>



    </div>



  );
}