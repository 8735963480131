import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
//import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RDrawer from "../components/Top";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import { header, getDomain, logout } from "../../auth";
import { CustomAlert } from "../../helper/helper";
import NativeSelect from '@material-ui/core/NativeSelect';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "react-switch";


const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },
  title1: { justifyContent: "space-between", display: "flex" },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",

    marginBottom: 20,
  },
  dialogtitle: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 5,
  },
  button: {
    textTransform: "none",
    letterSpacing: 1,
    height: 35,
  },
  paper: {
    background: "#e9e9e9",
    maxWidth: "60%",
    borderRadius: "8px",
    padding: "20px 20px 20px 20px",
    marginTop: 10,
    paddingBottom: 25,
    marginBottom: 20,
    boxShadow: "none",
  },
  paper1: {
    background: "#e9e9e9",
    maxWidth: "100%",
    borderRadius: 15,
    padding: "20px 20px 20px 20px",
    marginTop: 10,
    paddingBottom: 25,
    marginBottom: 20,
    boxShadow: "none",
  },
  input: {
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    paddingLeft: 10,
    padding: "5px",
    height: 35,
  },
  input1: {
    background: "#e9e9e9",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    paddingLeft: 10,
    padding: "5px",
    height: 35,
  },
  iconsize: {
    fontSize: 18,
    paddingRight: "5px",
  },
  dialogarea: {
    borderRadius: 30,
  },
  dialogcontent: {
    marginBottom: 10,
  },
  dialoggrid: {
    width: "550px",
    [theme.breakpoints.down(599)]: {
      width: "230px"
    },
    [theme.breakpoints.down(640)]: {
      width: "400px"
    },
  }, nativeslect: {
    backgroundColor: "#f1f2f7",
    color: "#b6b7bb",
    paddingRight: "10px",
    fontSize: 14,
    fontWeight: "400",
    borderRadius: 7,
    letterSpacing: "0.1px",
    marginRight: "10px",
    height: 44,
    width: "210px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
    },
    "& .MuiNativeSelect-icon": {
      color: "#b6b7bb",
    },
    "& .MuiNativeSelect-iconFilled": {
      right: "12px"
    },
    "& .MuiNativeSelect-select:focus": {
      backgroundColor: "#f1f2f7"
    },
    "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
      backgroundColor: "#f1f2f7",
      paddingLeft: "13px"
    },
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
      paddingLeft: "13px"
    }
  },
  dropdwnbox: {
    display: "flex",
    [theme.breakpoints.down(1200)]: {
      display: "block",
    },
    "& .MuiBox-root": {
      float: "left",
      width: "50%",
      marginBottom: "15px",
      paddingRight: "15px",
      [theme.breakpoints.down(479)]: {
        width: "100%",
      },
    }
  },
  table: {
    minWidth: 450,
  },
  tblstyle: 
  { borderTopLeftRadius: 20, 
    borderTopRightRadius: 20,
    [theme.breakpoints.down(1200)]: {
      borderTopLeftRadius: 10, 
      borderTopRightRadius: 10,
    },
    [theme.breakpoints.down(599)]: {
      borderTopLeftRadius: 8, 
      borderTopRightRadius: 8,
    }    
},
  tblhead: {
    background: "#151948",
  },
  txtcolor: {
    color: "#e9e9e9",
    padding: "15px 15px",
    [theme.breakpoints.down(599)]: {
      padding: "10px 15px",
    } 
  },

  tblcellcolor: {
    color: "#797fb3",
    padding: "15px 15px",
    [theme.breakpoints.down(599)]: {
      padding: "13px 15px",
    } 
  },
  tblinput: {
    background: "#FFF",
    borderRadius: 5,
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    paddingLeft: 10,
    padding: "5px",
    height: 35,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1250px)');
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  const obj = {
    id : "",
    group_name : "",
    server_discord_id : "",
    status : "",
    created_at : "",
    updated_at : "",
  }
  function showMessage(newMessage, newSeverity) {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------

  let [paypalInfos, setPaypalInfos] = useState({ paypal_status: "test" });
  let [stripeInfos, setStripeInfos] = useState({ stripe_status: "test" });
  let [discordInfos, setDiscordInfos] = useState([]);
  let [socialInfos, setSocialInfos] = useState([]);
  let [apiInfos, setApiInfos] = useState([]);
  let [passInfos, setPassInfos] = useState({ password_status: "disabled" });
  let [methodInfos, setMethodInfos] = useState([]);
  let [showPaymentDetails, setShowPaymentDetails] = useState();
  let [renewalStatus, setRenewalStatus] = useState({ renewal_status: "active" });
  let [buysformData, setFormData] = useState({ options: [obj] });
  let [duplicateIds, setDuplicateIds] = useState([]);
  const history = useHistory();

  function providerSubmit(key) {
    axios.put(getDomain() + '/provider-update', apiInfos[key], {
      headers: header()
    })
      .then(function (response) {
        console.log(response);
        showMessage("Api informations updated successfully", "success");
      })
      .catch(function (error) {
        console.log(error);
        showMessage("Something is went wrong, please try after sometime", "error");
      });
  }
  function updateServer(obj, name) {
    axios.put(getDomain() + '/setting-update', obj, {
      headers: header()
    })
      .then(function (response) {
        console.log(response);
        showMessage(name + " informations updated successfully", "success");
      })
      .catch(function (error) {
        console.log(error);
        showMessage("Something is went wrong, please try after sometime", "error");
      });
  }
    /*function Capitalize(str){
    console.log(str);
    return str.charAt(0).toUpperCase() + str.slice(1);
    }*/

  const paypalSubmit = (e) => {
    updateServer(paypalInfos, "Paypal");
  }
  const stripeSubmit = (e) => {
    updateServer(stripeInfos, "Stripe");
  }
  const discordSubmit = (e) => {
    updateServer(discordInfos, "Discord");
  }
  const socialSubmit = (e) => {
    updateServer(socialInfos, "Social Network");
  }
  const passwordSubmit = (e) => {
    updateServer(passInfos, "Password");
  }
  const renewalSubmit = (e) => {
    updateServer(renewalStatus, "Renewal Plan Page Only");
  }
  function formSubmit(){
    //formData.parent_id = (formData.parent_id ? formData.parent_id : null);
    //console.log("==> ", formData);
    let cnt=0;
	let allIds=[];
	let dIds=[];
    buysformData.options.forEach((val, key) => {
     if(val.group_name=="" || val.server_discord_id==""){
      cnt=1;
      showMessage("Please Fill all Group name and Server Discord ID.","error");
     }
	  if(allIds.indexOf(val.server_discord_id)>-1){
		  cnt=1;
		  showMessage("Duplicate group discord id ","error");
		  if(dIds.length===0)
		  {
			   dIds.push(val.server_discord_id);
		  }
		 
      }
	  else
	  {
		  allIds.push(val.server_discord_id);
	  }
    })
	setDuplicateIds(dIds);
    if(cnt==0){
      axios.post(getDomain()+"/store-buy-data",buysformData,{
        headers : header()
      })
      .then(function(response){
        console.log(response);
		if(response.data.status===true)
		{
			 if(response.data.existDiscord && response.data.existDiscord.length>0)
			 {
				 showMessage("Your Data has been updated Successfully. But found some duplicate data ("+response.data.existDiscord.join(',')+")","success");
			 }
			 else
			 {
				  showMessage("Your Data has been updated Successfully","success");
                   window.location.reload();
			 }
			
		}
		else
		{
			showMessage("Please provide the valid data","error");
                   //window.location.reload();
		}
		
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
        showMessage("Something is went wrong, please try after sometime","error");
      });
    }
 
  }
  const methodSubmit = (e) => {
    updateServer(methodInfos, "Payment Method");
    updateServer(paypalInfos, "Paypal");
    updateServer(stripeInfos, "Stripe");
  }

  const paypalHandler = (e) => {
    console.log(e.target.name, e.target.value)
    setPaypalInfos({ ...paypalInfos, [e.target.name]: e.target.value })
  }
  const stripeHandler = (e) => {
    setStripeInfos({ ...stripeInfos, [e.target.name]: e.target.value })
  }
  const discordHandler = (e) => {
    setDiscordInfos({ ...discordInfos, [e.target.name]: e.target.value })
  }
  const socialHandler = (e) => {
    setSocialInfos({ ...socialInfos, [e.target.name]: e.target.value })
  }
  const passwordHandler = (e) => {
    console.log(e.target.name, e.target.value)
    setPassInfos({ ...passInfos, [e.target.name]: e.target.value })
  }
  const renewalHandler = (e) => {
    setRenewalStatus({ ...renewalStatus, [e.target.name]: e.target.value });
  }
  const methodHandler = (e) => {
    console.log(e.target.name, e.target.value)
    setShowPaymentDetails('');
    if (e.target.value == "stripe") {
      setShowPaymentDetails('stripe');
    }
    else if (e.target.value == "paypal") {
      setShowPaymentDetails('paypal');
    }
    setMethodInfos({ ...methodInfos, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    axios.get(getDomain() + '/setting', {
      headers: header()
    })
      .then(function (response) {
        console.log(response.data);
        setPaypalInfos(response.data[0]);
        setStripeInfos(response.data[1]);
        setDiscordInfos(response.data[2]);
        setSocialInfos(response.data[3]);
        setApiInfos(response.data[4]);
        setPassInfos(response.data[5]);
        setMethodInfos(response.data[6]);
        setRenewalStatus(response.data[7]);
        if(response.data[8] && response.data[8].length>0)
        {
        setFormData({...buysformData, options: response.data[8]});
        }
        if (response.data[6].payment_method == "stripe") {
          setShowPaymentDetails('stripe');
        }
        else if (response.data[6].payment_method == "paypal") {
          setShowPaymentDetails('paypal');
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response === 401) {
          logout();
          window.location.reload();
        }
      });
  }, [])

  //--------------------------------
  const paperAraa = matches ? classes.paper1 : classes.paper;
  //---------------------------------
  const apiChange = (e, key) => {
    let newArr = [...apiInfos];
    newArr[key][e.target.name] = e.target.value;
    setApiInfos(newArr);
  }
  const addMore = () =>{
    let objArr = buysformData.options?buysformData.options:[];
    objArr.push(obj);
    setFormData({...buysformData, options: objArr});
  }
  const optionHandler =(e,key) =>{
    console.log(buysformData)
    let data= buysformData.options;
    data[key][e.target.name]=e.target.value;
    setFormData({ ...buysformData, options: data })
  }
  const deleteOption = (index) => {
    let objArr = buysformData.options;
    delete objArr[index];
    setFormData({...buysformData, options: objArr});
  }
  const handleChange=(status,ind,)=> {
    //console.log(status);
    //setStatus({ checked: status});
    let objArr = buysformData.options;
    objArr[ind].status=status;
    setFormData({...buysformData, options: objArr});
  }
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CustomAlert open={open} severity={severity} message={message} handleClose={handleClose} />

        <Container maxWidth="100%" className={classes.container}>

          <Paper className={paperAraa}>
            <Box maxWidth="100%">
              <form noValidate autoComplete="off">
                <Grid item xs={12} md={12} sm={12}>
                  <Typography color="secondary" className={classes.titleplan}>
                    Payment
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <NativeSelect
                    name="payment_method"
                    value={methodInfos.payment_method}
                    onChange={methodHandler}
                    variant="filled"
                    className={classes.nativeslect}
                    disableUnderline>
                    <option value="">Choose Method</option>
                    <option value="stripe">Stripe</option>
                    <option value="paypal">Pyapal</option>
                  </NativeSelect>
                </Grid>

                <Typography className={classes.titleplan} color="secondary">

                </Typography>
                {showPaymentDetails == "paypal" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Mode</FormLabel>
                        <RadioGroup aria-label="paypal_status" value={paypalInfos.paypal_status} onChange={paypalHandler} name="paypal_status" row>
                          <FormControlLabel value="test" control={<Radio />} label="Test" />
                          <FormControlLabel value="live" control={<Radio />} label="Live" />
                          <FormControlLabel value="disabled" control={<Radio />} label="Disabled" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography color="secondary">
                        Paypal Sandbox
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Paypal ID"
                        name="paypal_publish_key_test"
                        value={paypalInfos.paypal_publish_key_test}
                        disableUnderline
                        fullWidth
                        onChange={paypalHandler}
                        className={classes.input}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Key"
                        name="paypal_key_test"
                        value={paypalInfos.paypal_key_test}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        inputProps={{ "aria-label": "description" }}
                        onChange={paypalHandler}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography color="secondary">
                        Paypal Live
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Paypal ID"
                        name="paypal_publish_key_live"
                        value={paypalInfos.paypal_publish_key_live}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        onChange={paypalHandler}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Key"
                        name="paypal_key_live"
                        value={paypalInfos.paypal_key_live}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        onChange={paypalHandler}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                  </Grid>) : ""}
                {showPaymentDetails == "stripe" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Mode</FormLabel>
                        <RadioGroup aria-label="stripe_status" value={stripeInfos.stripe_status} onChange={stripeHandler} name="stripe_status" row>
                          <FormControlLabel value="test" control={<Radio />} label="Test" />
                          <FormControlLabel value="live" control={<Radio />} label="Live" />
                          <FormControlLabel value="disabled" control={<Radio />} label="Disabled" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography color="secondary">
                        Stripe Sandbox
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Stripe ID"
                        name="stripe_publish_key_test"
                        value={stripeInfos.stripe_publish_key_test}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        onChange={stripeHandler}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Key"
                        name="stripe_key_test"
                        value={stripeInfos.stripe_key_test}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        onChange={stripeHandler}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography color="secondary">
                        Stripe Live
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Stripe ID"
                        name="stripe_publish_key_live"
                        value={stripeInfos.stripe_publish_key_live}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        onChange={stripeHandler}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Input
                        placeholder="Key"
                        name="stripe_key_live"
                        value={stripeInfos.stripe_key_live}
                        disableUnderline
                        fullWidth
                        className={classes.input}
                        onChange={stripeHandler}
                        inputProps={{ "aria-label": "description" }}
                      />
                    </Grid>
                  </Grid>) : ""}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5} sm={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      onClick={methodSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Paper className={paperAraa}>
            <Box maxWidth="100%">
              <Typography className={classes.titleplan} color="secondary">
                Discord
              </Typography>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <Input
                      placeholder="Discord ID"
                      name="discord_client_id"
                      value={discordInfos.discord_client_id}
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      onChange={discordHandler}
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Input
                      placeholder="Instagram ID"
                      name="discord_secrete_key"
                      value={discordInfos.discord_secrete_key}
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      onChange={discordHandler}
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} sm={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      onClick={discordSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Paper className={paperAraa}>
            <Box maxWidth="100%">
              <Typography className={classes.titleplan} color="secondary">
                Social Media
              </Typography>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <Input
                      placeholder="Twitter ID"
                      name="social_twitter_url"
                      value={socialInfos.social_twitter_url}
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      onChange={socialHandler}
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Input
                      placeholder="Discord ID"
                      name="social_discord_url"
                      value={socialInfos.social_discord_url}
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      onChange={socialHandler}
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Input
                      placeholder="Instagram ID"
                      name="social_instagram_url"
                      value={socialInfos.social_instagram_url}
                      disableUnderline
                      fullWidth
                      className={classes.input}
                      onChange={socialHandler}
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} sm={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      onClick={socialSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Paper className={paperAraa}>
            <Box maxWidth="100%">
              <Typography className={classes.titleplan} color="secondary">
                Api credentials
              </Typography>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  {apiInfos.map((e, key) => {
                    return <>
                      {/*
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography color="secondary">
                        Paypal Sandbox
                      </Typography>
                    </Grid>
                    */}
                      <Grid item xs={12} md={6} sm={6}>
                        <Input
                          placeholder="Api User Name"
                          name="username"
                          value={e.username}
                          disableUnderline
                          fullWidth
                          onChange={(e) => { apiChange(e, key) }}
                          className={classes.input}
                          inputProps={{ "aria-label": "description" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                        <Input
                          placeholder="Api Password"
                          name="user_password"
                          value={e.user_password}
                          disableUnderline
                          fullWidth
                          className={classes.input}
                          inputProps={{ "aria-label": "description" }}
                          onChange={(e) => { apiChange(e, key) }}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} sm={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.btntext}
                          onClick={() => providerSubmit(key)}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </>;
                  })}

                </Grid>
              </form>
            </Box>
          </Paper>

          <Paper className={paperAraa}>
            <Box maxWidth="100%">
              <Typography className={classes.titleplan} color="secondary">
                Password
              </Typography>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Mode</FormLabel>
                      <RadioGroup aria-label="password_status" value={passInfos.password_status} onChange={passwordHandler} name="password_status" row>
                        <FormControlLabel value="enabled" control={<Radio />} label="Enable" />
                        <FormControlLabel value="disabled" control={<Radio />} label="Disable" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography color="secondary">
                      Password Data
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Input
                      placeholder="Password"
                      name="password"
                      value={passInfos.password}
                      disableUnderline
                      fullWidth
                      onChange={passwordHandler}
                      className={classes.input}
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} sm={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      onClick={passwordSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Paper className={paperAraa}>
            <Box maxWidth="100%">
              <Typography className={classes.titleplan} color="secondary">
                Renewal Plan Page Only
              </Typography>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="renewal_status" value={renewalStatus.renewal_status} onChange={renewalHandler} name="renewal_status" row>
                        <FormControlLabel value="all" control={<Radio />} label="Allow both active and expired to buy/renew plan" />
                        <FormControlLabel value="active" control={<Radio />} label="Allow only active or standby to buy/renew plan" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={5} sm={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      onClick={renewalSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Paper className={paperAraa}>
            <Box maxWidth="100%">
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
            <Typography className={classes.titleplan} color="secondary">
                Group Buys Option
              </Typography>
              </Grid>           
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={ addMore }
                      size="small"
                      >
                      Add More
                    </Button>
                  </Grid>
                </Grid>
              <form noValidate autoComplete="off">                
                <TableContainer component={Paper} className={classes.tblstyle}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead className={classes.tblhead}>
                      <TableRow>
                        <TableCell className={classes.txtcolor}>
                          Group Name
                        </TableCell>
                        <TableCell align="" className={classes.txtcolor}>
                          Server Discord Id
                        </TableCell>
                        <TableCell align="" className={classes.txtcolor}>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
              { buysformData.options.map((element, key)=> {
              return <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tblcellcolor}
                        >
                          <input
                            className={classes.input}
                            name="group_name"
                            value={element.group_name}
                            onChange={ (e)=> optionHandler(e,key) }
                            placeholder="Type here.."
                            className={classes.tblinput}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <input
                            className={classes.input}
                            name="server_discord_id"
                            value={element.server_discord_id}
                            onChange={ (e)=> optionHandler(e,key) }
                            placeholder="Type here.."
                            className={classes.tblinput}
							style={{"color":(duplicateIds.indexOf(element.server_discord_id)>-1?"red":"")}}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <Switch 
                          name="status"  
                          value={element.status}
                          onChange={ (e)=> handleChange(e,key) } 
                          checked={element.status==1 ? true: false } />
                          </TableCell>                        
                        
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <DeleteIcon
                            size="small"
                            className={classes.iconcolor}
                           onClick={() => {
                            deleteOption(key)
                           }}
                          />
                          
                        </TableCell>
                      </TableRow>
                  })}
                     
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid item xs={12} md={5} sm={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btntext}
                      onClick={formSubmit}
                    >
                      Submit
                    </Button>
                  </Grid> 
              </form>
            </Box>
          </Paper>
        </Container>
      </main>
    </div>
  );
}
