import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Top from "../components/Top";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Footer1 from "../components/Footer1";
import Typography from "@material-ui/core/Typography";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
//import ListItemText from "@material-ui/core/ListItemText";
//import Grid from '@material-ui/core/Grid';
import image1 from '../../assets/images/innerbanner.jpg'
import { Paper } from "@material-ui/core";
import dividerimage from '../../assets/images/divider.png'
import { colors } from '../components/colors'
import { getDomain } from "../../auth";
const axios = require('axios');

const useStyles = makeStyles((theme) => ({
 
   content: {
    flexGrow: 1,
        
         
        height: "auto",
      
   
    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    },
    container1: {
      backgroundColor: colors.darkBlue,
      padding:"20px"
      
        
    },
    subhead1: {    
      marginTop: 3,
      marginBottom: 10,
      letterSpacing:0.6,
      fontSize: "18px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: colors.accent,
      [theme.breakpoints.down(599)]: {
        fontSize:"16px",
        marginTop:"-3px"
     },
    
  },
    subhead2: {
    
      fontSize: "18px",
        fontWeight: "500",
        fontFamily: "Poppins",
         color: colors.accent,
    
     letterSpacing:0.6,
    
    marginTop: 20,
    marginBottom: 10,
    },
      img: { width: "auto",
    margin: "0px", right: 0 },

    paragraph: {
    color:colors.lightpurple,
    fontSize: "16px",
    fontWight: "400",
    marginTop: "5px",
    marginBottom: "12px",
    letterSpacing: 0.6,
    lineHeight: "24px",
    fontFamily: "Poppins",
    [theme.breakpoints.down(599)]: {
      fontSize:"14px",
      marginTop:"-1px"
   },
    },
    paper: {
     paddingTop: "15px",
      paddingBottom:"30px",
        boxShadow: "none",
         backgroundColor: colors.darkBlue,
       },
  listItemText: {
         fontWeight: "500",
         fontSize: 16,
    
        lineHeight: "22px",
       fontFamily: "Poppins",
     letterSpacing:0.6,
    color:colors.lightpurple,
    [theme.breakpoints.down(599)]: {
      fontSize:"14px",
   },
  },
  imageSrc: {
    height:"auto",
    width:"auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
     paddingBottom:"15px"
  },
  divcolor: {
    backgroundColor: colors.darkBlue,
  },
  dividerimg: {
    height: 20,
    width: "auto",
    marginTop: "30px",
    [theme.breakpoints.down(1200)]: {
      marginTop: "20px",
   },
   [theme.breakpoints.down(959)]: {
    display:"none",
 }
},
pagetitle: {
lineHeight: "30px !important",
fontSize:"32px !important",
[theme.breakpoints.down(1200)]: {
fontSize:"28px !important",
fontWeight: "500"
},
[theme.breakpoints.down(959)]: {
fontSize:"24px !important",
},
[theme.breakpoints.down(599)]: {
fontSize:"20px !important",
marginBottom:"0px",
}
},
titlebox: {
textAlign:"center",
marginBottom:"24px",
paddingTop:"50px",
[theme.breakpoints.down(1200)]: {
  paddingTop:"35px",
  marginBottom:"10px"
},
[theme.breakpoints.down(959)]: {
  paddingTop:"26px",
  marginBottom:"10px"
},
[theme.breakpoints.down(599)]: {
  paddingTop: "33px",
  marginBottom: "5px"
},

},
  
}));

function Refundpolicy() {
    const classes = useStyles();
    const [content, setContent] = useState("");
    useEffect(()=> {
      axios.get(getDomain()+'/page/1')
        .then(function(response){
          setContent(response.data);
        })
        .catch(function(error){
          console.log(error);
        });
    },[])
    

  return (
    <>
    
        <Top />
       
        <div className={classes.drawerHeader}/>
      <main className={classes.content}  >
       <div className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image1})`,
            }}>
            <Box className={classes.titlebox}>
            <Typography variant="h3" className={classes.pagetitle}>Privacy Policy</Typography>   
             <img src={dividerimage} alt="no img" className={classes.dividerimg}/>
             </Box>
        </div>
        <div className={classes.divcolor}>
            <Container className={classes.container1} maxWidth="lg" width="1200px">
          <Paper className={classes.paper}>
          <div className={classes.paragraph} dangerouslySetInnerHTML={{ __html: content }}  />
           </Paper>
          </Container>
         
          <Footer1 />
           </div>
       </main>
            
      
    </>
  );
}
export default Refundpolicy;
