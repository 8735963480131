import React, {useEffect} from "react";
import Footer from '../components/footer'
import Top from '../components/Top'
import { makeStyles } from '@material-ui/core/styles';
import HomeContent1 from '../Content/HomeContent1';
import HomeContent2 from '../Content/HomeContent2';
import HomeContent3 from '../Content/HomeContent3';
import HomeContent4 from '../Content/HomeContent4';
import HomeContent5 from '../Content/HomeContent5';
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import { set_info } from "../../helper/helper"



const useStyles = makeStyles((theme) => ({
 content: {
    flexGrow: 1,
      height: "auto",
      backgroundColor: "#01092d",
    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor:"#151948",
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    },
  }));
function HomeScreen() {
  const location = useLocation();
  const classes = useStyles();
  useEffect(()=> {
    const values = queryString.parse(location.search)
    console.log(values);
    if(values && (values.status==="error" || values.status==="success") && values.message!="" )
    {
      set_info(values.message,values.status);
      window.location.href="/dashboard";
    }
  },[]);

  return (
    <div>
      <Top />
       <div className={classes.drawerHeader}/>
        <main className={classes.content} >
        <HomeContent1 />
        <HomeContent2 />
        <HomeContent3 />
        <HomeContent4 />
        <HomeContent5 />
         <Footer/>
          </main>
     </div>
  );
}

export default HomeScreen;
