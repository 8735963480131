import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import dividerimage from '../../assets/images/divider.png'
import image1 from '../../assets/images/tik.png';
import Content3 from '../../assets/images/curve-blue.png'
const useStyles = makeStyles((theme) => ({
    root1: {
     backgroundColor:"#01092c",
        marginTop: "0 !important",
        backgroundImage:`url(${Content3})`,
        backgroundPosition: "left",
        backgroundRepeat:"no-repeat",
        [theme.breakpoints.down(1200)]: {
           backgroundPosition: "-110px bottom",
        },
        [theme.breakpoints.down(959)]: {
          backgroundImage: "none",
       },
    },
    lies: {
        fontSize: 18,
        fontWeight: "400",
        fontFamily: "Poppins",
        color: "#80859b",
        borderBottom: "1px dashed #484e6e",
        paddingTop: 22,
        paddingBottom: 22,
        backgroundImage:`url(${image1})`,       
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0px center",
        overflow: "visible",
        paddingLeft: "60px",       
        [theme.breakpoints.down(1200)]: {
            color: "#94969e",
            fontSize: 16,
            borderBottom: "1px dashed #94969e",
            fontWeight: "300",
        },
        [theme.breakpoints.down(599)]: {
          paddingLeft: "51px",
          fontSize: 14,
        }, 
    },
     dividerimg: {
        height: 20,
        width: "auto",
        marginTop: "30px",
        [theme.breakpoints.down(959)]: {
            marginTop: "15px",
            height: "17px",
          } ,
          [theme.breakpoints.down(599)]: {
            marginTop: "11px",
            height: "12px",
          }  
     },
    tikimage: {
        height: "auto",
        width:"auto",
       
    },
    ullist: {
        paddingLeft: "0px !important",
        listStyle: "none",
        "& li:last-child": {
             borderBottom: "0px none !important",
        },
        [theme.breakpoints.down(599)]: {
          width:"110%",
          marginLeft:"-5%"
        }, 
    },
    boxlisting: {
        padding:"0px 135px 50px",
        [theme.breakpoints.down(959)]: {
            padding:"0px 0px 50px",
        }, 
        [theme.breakpoints.down(599)]: {
            padding:"0px 0px 10px",
        } 
    },
    sectionHead:{
        [theme.breakpoints.down(959)]: {
          fontWeight: "500",
          fontSize:"30px", 
          marginBottom:"0px",
          lineHeight:"normal", 
        },
        [theme.breakpoints.down(599)]: {
          fontSize:"20px",
          letterSpacing:"0.2px"
        }
      },
      headOut:{
        padding:"45px 0 30px",
        [theme.breakpoints.down(599)]: {
          padding:"35px 0 0px",
        }
      }
    
}));
function HomeContent3() {
    AOS.init();
    const classes = useStyles();

    return (
        <div className={classes.root1} >
            <Container maxWidth="lg">
             <Box textAlign="center" paddingBottom="10px"  className={classes.headOut}>
                    <Typography variant="h3" className={classes.sectionHead}> Why Should You Choose Live Proxies?</Typography>
                     <img src={dividerimage} alt="no img" className={classes.dividerimg}/>
                    
                </Box>
                <Container maxWidth="md">
                    <Box className={classes.boxlisting} paddingTop="0px">
                        <ul className={classes.ullist}>
                        <li className={classes.lies} data-aos="fade-top" data-aos-duration="700">You'll be met with reliability throughout drops and unmatched performance.</li>
                        <li className={classes.lies} data-aos="fade-top" data-aos-duration="700">Whether you run into any issues or need that extra support our trusted support team will always be on hand.</li>
                        <li className={classes.lies} data-aos="fade-top" data-aos-duration="700">You'll enjoy the benefits of an entirely unique pool of private Live proxies. You wont have to share your proxies with any other user... 100% privacy, 100% dedication.</li>
                        <li className={classes.lies} data-aos="fade-top" data-aos-duration="700">You're not just another client, your success is our success! Your residential plan will always prove reliable and take the stress away from that extra element during releases.</li>
                       <li className={classes.lies} data-aos="fade-top" data-aos-duration="700">You won't share the proxies with anyone else unlike all the other players in the market!</li>
                        </ul>
                        </Box>
                    </Container>
           
                </Container>
      </div>
  );
}

export default HomeContent3;
