import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import { useHistory } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Divider from "@material-ui/core/Divider";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import LanguageIcon from "@material-ui/icons/Language";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  nested: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  paper: {
    backgroundColor: "#1d2255",
    borderRadius: 20,
    color: "#797fb3",
  },
  activecolor: {
    color: "#1870ff",
  },
  MuiListItem: {
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 12,
    paddingBottom: 12,
  },
  listItemText: {
    color: "#d0dad1",
    letterSpacing: 1,
    fontSize: 14.5,
    marginLeft: -22,
  },
  listItemText1: {
    color: "#a2a7d4",
    letterSpacing: 1,
    fontSize: 14,
  },
  listItemTextactive: {
    color: "#1870ff",
    letterSpacing: 1,
    fontSize: 14,
  },
  iconcolor: {
    color: "#d0dad1",
    fontSize: 20,
  },
  expandicon: {
    color: "#72769c",
    fontSize: 23,
  },
  dividerspace: {
    marginBottom: 8,
  },
}));

export default function RListItem1() {
  const history = useHistory();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };

  return (
    <div>
      <Divider className={classes.dividerspace} />
      <ListItem button className={classes.MuiListItem}>
        <ListItemIcon>
          <DashboardIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
      <Divider />
      <ListItem button onClick={handleClick} className={classes.MuiListItem}>
        <ListItemIcon>
          <PeopleAltIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="Customers"
          classes={{ primary: classes.listItemText }}
        />
        {open ? (
          <ExpandLess className={classes.expandicon} />
        ) : (
          <ExpandMore className={classes.expandicon} />
        )}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ padding: "0px 24px", marginBottom: "7px" }}
      >
        <Paper className={classes.paper}>
          <List component="div">
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin")}
            >
              <ListItemText
                primary="All Customers"
                classes={{ primary: classes.listItemTextactive }}
              />
            </ListItem>
            
          </List>
        </Paper>
      </Collapse>
      <Divider />
      <ListItem button onClick={handleClick1} className={classes.MuiListItem}>
        <ListItemIcon>
          <MonetizationOnIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="Plans"
          classes={{ primary: classes.listItemText }}
        />
        {open1 ? (
          <ExpandLess className={classes.expandicon} />
        ) : (
          <ExpandMore className={classes.expandicon} />
        )}
      </ListItem>
      <Collapse
        in={open1}
        timeout="auto"
        unmountOnExit
        style={{ padding: "0px 24px", marginBottom: "7px" }}
      >
        <Paper className={classes.paper}>
          <List component="div">
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Viewplans")}
            >
              <ListItemText
                primary="View Plans"
                classes={{ primary: classes.listItemTextactive }}
              />
            </ListItem>
           
          </List>
        </Paper>
      </Collapse>
      <Divider />
      <ListItem button onClick={handleClick2} className={classes.MuiListItem}>
        <ListItemIcon>
          <DescriptionIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="Content"
          classes={{ primary: classes.listItemText }}
        />
        {open2 ? (
          <ExpandLess className={classes.expandicon} />
        ) : (
          <ExpandMore className={classes.expandicon} />
        )}
      </ListItem>
      <Collapse
        in={open2}
        timeout="auto"
        unmountOnExit
        style={{ padding: "0px 24px", marginBottom: "7px" }}
      >
        <Paper className={classes.paper}>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Faq")}
            >
              <ListItemText
                primary="FAQ"
                classes={{ primary: classes.listItemTextactive }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Privacypolicy")}
            >
              <ListItemText
                primary="Privacy Policy"
                classes={{ primary: classes.listItemText1 }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Refundpolicy")}
            >
              <ListItemText
                primary="Refund Policy"
                classes={{ primary: classes.listItemText1 }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Termsofuse")}
            >
              <ListItemText
                primary="Terms of Use"
                classes={{ primary: classes.listItemText1 }}
              />
            </ListItem>
          </List>
        </Paper>
      </Collapse>
      <Divider />
      <ListItem button onClick={handleClick3} className={classes.MuiListItem}>
        <ListItemIcon>
          <SettingsIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="General"
          classes={{ primary: classes.listItemText }}
        />
        {open3 ? (
          <ExpandLess className={classes.expandicon} />
        ) : (
          <ExpandMore className={classes.expandicon} />
        )}
      </ListItem>
      <Collapse
        in={open3}
        timeout="auto"
        unmountOnExit
        style={{ padding: "0px 24px", marginBottom: "7px" }}
      >
        <Paper className={classes.paper}>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Notification")}
            >
              <ListItemText
                primary="Notifications"
                classes={{
                  primary: classes.listItemTextactive,
                }}
                color="secondary"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Coupons")}
            >
              <ListItemText
                primary="Coupons"
                classes={{ primary: classes.listItemText1 }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/admin/Settings")}
            >
              <ListItemText
                primary="Settings"
                classes={{ primary: classes.listItemText1 }}
              />
            </ListItem>
          </List>
        </Paper>
      </Collapse>
      <Divider />
      <ListItem
        button
        className={classes.MuiListItem}
        onClick={() => history.push("/admin/IPs")}
      >
        <ListItemIcon>
          <LanguageIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="IPs"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
      <Divider />
      <ListItem
        button
        className={classes.MuiListItem}
        onClick={() => history.push("/admin/Report")}
      >
        <ListItemIcon>
          <AssessmentIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="Report"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
      <Divider />
      <ListItem
        button
        className={classes.MuiListItem}
        onClick={() => history.push("/admin")}
      >
        <ListItemIcon>
          <ExitToAppIcon className={classes.iconcolor} />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
          classes={{ primary: classes.listItemText }}
        />
      </ListItem>
    </div>
  );
}
