import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import RDrawer from "../components/Top";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';
import Input from "@material-ui/core/Input";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Bar } from "react-chartjs-2";
import TableRow from '@material-ui/core/TableRow';
import { useLocation } from "react-router-dom";
import { header, getDomain } from "../../auth";
import { CustomAlert } from "../../helper/helper";
import copy from "copy-to-clipboard";
import NativeSelect from '@material-ui/core/NativeSelect';
const axios = require('axios');
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth: "100%",
     paddingTop: 19,
  },
 
  datebox: {
    justifyContent: "flex-end",
    display:"flex",
    [theme.breakpoints.down(599)]: {
      justifyContent: "space-evenly",
      display:"block",
      textAlign:"center"
    }
  },
  userimage: {
    height: "auto",
    width: "130px",
    borderRadius:"10px"
    
  },
  userinfobox: {
    display:"flex"
  },
 
  input: {
    background: "#f4f3f8",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    padding: "5px",
    paddingLeft: 10,
    marginTop: "8px",
    width:"100%"
   
  },
  input1: {
    background: "#f4f3f8",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
     fontSize:"15px",
    fontWeight: "500",
    padding: "3px",
    paddingLeft: 10,
    marginTop: "8px",
    width: "200px",
    height:"38px",
    [theme.breakpoints.down(1200)]: {
      width:"100%" 
    },
  },
  infobox: {
    margin: "15px 0 15px 0",
    paddingTop: "47px",
    [theme.breakpoints.down(599)]: {
      margin: "0",
      paddingTop: "0px",
     },
    "& p": {
       color: "#716363",
    fontWeight:"500",
    fontSize: 14,
    [theme.breakpoints.down(599)]: {
      marginTop:"-10px"
    }
    }
   },
  textt: {
    color: "#716363",
    fontWeight: "500",
    fontSize: 14,
    paddingLeft: "10px"
  },
  paperclass: {
    boxShadow: "none",
    marginTop: "0px"
  },
  filecopyicon: {
    color: "#1870ff",
    border: "1px solid #1870ff",
    marginTop: "10px",
    fontSize: "35px",
    padding: "5px",
    marginLeft: "10px",
    marginRight: "10px",
    borderRadius: "5px",
    [theme.breakpoints.down(1200)]: {
      marginLeft: "0",
      marginRight: "0",
      color: "#fff",
      background: "#1870ff",
    }
  },
  buttons: {
    background: "#f4f3f8",
    color: "#797fb3",
    width: "200px",
    justifyContent: "left"
  },
  buttonbox: {
    marginTop: "20px"
  },
  tableheadplan: {
    backgroundColor: "#0055fe",

    borderRadius: "20px"
  },
  tblbodyplan: {
    backgroundColor: "#f4f5f9",

  },
  tblcell: {
    color: "#FFF"
  },
  tblcell1: {
    color: "#797fb3",
  },
  TableContaine1: {
    boxShadow: "none",
    "& table": {
      borderCollapse: "collapse",
      border: "1px solid #797fb3",
      "& th": {
        border: "1px solid #797fb3",
      },
      "& td": {
        border: "1px solid #797fb3",
      },
    }
  },
  tablebox: {
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "10px",
    marginTop: "5px",
    height: "auto",
    [theme.breakpoints.down(599)]: {
      marginTop: "-15px",
      padding: "15px",
      borderRadius: "10px",
    }
  },
  tabletitle: {
    marginBottom: "10px",
    color: "#716363",
    fontSize: "18px"
  },
  Customerinfoo: {
    marginTop: "40px",
    [theme.breakpoints.down(1200)]: {
      marginTop: "10px",
    },
    "& p": {
      fontSize: "18px",
      color: "#1870ff",
      fontWeight: "600",
      [theme.breakpoints.down(1200)]: {
        fontSize: "16px",
      },
    }
  },
  apilogin: {
    fontSize: "18px",
    color: "#1870ff",
    fontWeight: "600",
    [theme.breakpoints.down(1200)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(599)]: {
      borderTop: "1px dashed #0055fe",
      paddingTop: "15px",
      marginTop: "15px"
    },
  },
  tabs: {

    "& button": {
      backgroundColor: "#f4f5f9",
      margin: "10px 10px 10px 0px",
      color: "#797fb3",
      opacity: 9,
      minHeight: "42px",
      borderRadius: "6px",
      textTransform: "none",
      fontSize: "15px",
      [theme.breakpoints.down(599)]: {
        maxWidth: "100% !important"
      }
    },

    "& .MuiTab-wrapper": {
      alignItems: "flex-start"
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#0055fe",
      color: "#FFF"
    }
  },
  boxrevenue: {
    backgroundColor: "#f4f3f8",
    borderRadius: "10px",
    display: "flex",
    padding: "8px 19px 15px",
    height: "auto",
    marginBottom: "20px",
    [theme.breakpoints.down(959)]: {
      display: "block",
    },
    [theme.breakpoints.down(599)]: {
      display: "flex",
      marginBottom: "0"
    },
    "& p": {
      fontSize: "16px",
      flexGrow: 1,
      marginTop: "10px",
      letterSpacing: "0.1px",
      color: "#9596ac",
      [theme.breakpoints.down(1200)]: {
        fontSize: "15px",
        letterSpacing: "0.1",
      },
    }
  },
  pricesrevenue: {
    color: "#0055fe",
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "500",
    [theme.breakpoints.down(1200)]: {
      fontSize: "18px",
      letterSpacing: "0.1px",
    },
    [theme.breakpoints.down(599)]: {
      fontSize: "16px",
    },
  },
  infoboxOut: {
    [theme.breakpoints.down(1280)]: {
      paddingTop: "0 !important",
    },
    [theme.breakpoints.down(599)]: {
      paddingTop: "12px !important",
    },
    "& .MuiBox-root": {
      [theme.breakpoints.down(1280)]: {
        paddingTop: "0 !important",
        marginTop: "0 !important",
      },
    },
  },
  proImg: {
    [theme.breakpoints.down(599)]: {
      display: "block",
      textAlign: "center",
      margin: "20px 0 0px",
    },

  },
  iconOutUser: {
    display: "flex",
    [theme.breakpoints.down(1200)]: {
      display: "block",
    },
    [theme.breakpoints.down(599)]: {
      display: "flex",
    },
  },
  spacingDiv: {
    [theme.breakpoints.down(599)]: {
      marginTop: "15px"
    },
  },
}));
const dataa = [
  {
    label: "Jan 20",
    dataval: 200000,
    datausage: 90,

  },
  {
    label: "Feb 20",
    dataval: 40000,
    datausage: 70,

  },
  {
    label: "March 20",
    dataval: 8000,
    datausage: 170,

  },
  {
    label: "April 20",
    dataval: 50000,
    datausage: 150,

  },
  {
    label: "May 20",
    dataval: 120000,
    datausage: 150,

  },
  {
    label: "jun 20",
    dataval: 30000,
    datausage: 150,

  },
  {
    label: "July 20",
    dataval: 40000,
    datausage: 150,

  },
  {
    label: "Aug 20",
    dataval: 230000,
    datausage: 150,

  },

];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function CustomerDetails(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  //if(props.match.params.id && props.match.params.id>0)
  //{
  const id = props.match.params.id;
  //}
  //const id = location.state.params;
  const emptyObj = {
    user_info: {},
    coupons: [],
    plans: [],
    pay_email: "NA"
  }
  let [infos, setInfos] = React.useState(emptyObj);

  useEffect(() => {
    axios.get(getDomain() + "/user-info/" + id, { headers: header() })
      .then(function (response) {
        setInfos(response.data);
        console.log(response.data);
        setRole(response.data.role);
        setStatus(response.data.status);
        setApiInfos(response.data.user_info.provider_info);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const BarChartRevenue = {
    labels: infos.month_name,
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "#d6d5db",
        data: infos.revenue

      },
    ],
  };
  const BarChartData = {
    labels: infos.month_name,
    datasets: [
      {
        label: "Data Used",
        backgroundColor: "#d6d5db",
        data: infos.data_used

      },
    ],
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /*
  const copyText = () => {
    if(infos.user_info.provider_info)
    {
      copy(infos.user_info.provider_info);
      showMessage("Copied Successfully","success");
    }
    else{
      showMessage("Sorry, there is no information to copy","error");
    }
  }
  */
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  let userRole = [];
  userRole[1] = "Admin";
  userRole[2] = "User";
  userRole[3] = "Support";
  userRole[4] = "Unlimited Plan User";
  let userStatus = [];
  userStatus[0] = "InActive";
  userStatus[1] = "Active";
  let [role, setRole] = React.useState(2);
  let [status, setStatus] = React.useState(0);
  let [apiInfos, setApiInfos] = React.useState("Username:Password");
  const inputChange = (e) => {
    if (e.target.name === "role")
      setRole(e.target.value);
    else if (e.target.name === "status")
      setStatus(e.target.value);
    else if (e.target.name === "credentials")
      setApiInfos(e.target.value);
  }
  const saveUser = (attr) => {
    let objparm = { id: id, attr: attr }
    if (attr === "status")
      objparm['data'] = status;
    else if (attr === "role")
      objparm['data'] = role;
    else if (attr === "credentials")
      objparm['data'] = apiInfos;
    else
      return;
    axios.post(getDomain() + "/update-user", objparm, { headers: header() })
      .then(function (response) {
        if (response.data == 1) {
          showMessage(attr + " updated successfully", "success");
        }
      })
      .catch(function (error) {
        console.log(error);
        showMessage("Sorry, Something is went wrong. Please try after sometime", "error");
      });
  }

  function showMessage(newMessage, newSeverity) {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Box className={classes.datebox}>
            <Typography className={classes.textt}>Signup Date: {infos.created_at}</Typography>
            <Typography className={classes.textt}>Last Login Date: {infos.updated_at}</Typography>
          </Box>
          <CustomAlert open={open} severity={severity} message={message} handleClose={handleClose} />

          <Grid container spacing={3}>
            <Grid item xs={12} lg={2} md={4} sm={4}>
              <Box className={classes.proImg}>
                <img src={infos.image ? infos.image : require("../../assets/admin-images/author.png")} alt="img" className={classes.userimage}></img>
              </Box>
            </Grid>
            <Grid item xs={12} lg={2} md={4} sm={4}>
              <Box className={classes.infobox}>
                <Typography>Discord Name</Typography>
                <Input
                  defaultValue=""
                  fullWidth
                  value={infos.user_info.discord_username}
                  disableUnderline
                  className={classes.input}
                  inputProps={{ "aria-label": "description" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={2} md={4} sm={4}>
              <Box className={classes.infobox}>
                <Typography>Discord ID</Typography>
                <Input
                  defaultValue=""
                  fullWidth
                  value={infos.user_info.discord_id}
                  disableUnderline
                  className={classes.input}
                  inputProps={{ "aria-label": "description" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={2} md={4} sm={4} className={classes.infoboxOut}>
              <Box className={classes.infobox}>
                <Typography>Discord Email</Typography>
                <Input
                  defaultValue=""
                  fullWidth
                  value={infos.user_info.email}
                  disableUnderline
                  className={classes.input}
                  inputProps={{ "aria-label": "description" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={2} md={4} sm={4} className={classes.infoboxOut}>
              <Box className={classes.infobox}>
                <Typography>Paypal Email</Typography>
                <Input
                  defaultValue=""
                  fullWidth
                  value={infos.paypal_email}
                  disableUnderline
                  className={classes.input}
                  inputProps={{ "aria-label": "description" }}
                />
              </Box>
            </Grid>
            {
              <Grid item xs={12} lg={2} md={4} sm={4} className={classes.infoboxOut}>
                <Box className={classes.infobox}>
                  <Typography>Stripe Email</Typography>
                  <Input
                    defaultValue=""
                    fullWidth
                    value={infos.stripe_email}
                    disableUnderline
                    className={classes.input}
                    inputProps={{ "aria-label": "description" }}
                  />
                </Box>
              </Grid>
            }
          </Grid>
          <Paper className={classes.paperclass}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} lg={3} md={3}>
                <Typography className={classes.apilogin}>API Login</Typography>

                <Box className={classes.iconOutUser}>
                  <Input
                    name="credentials"
                    defaultValue="Username:Password"
                    fullWidth
                    onChange={inputChange}
                    disableUnderline
                    value={apiInfos}
                    className={classes.input1}
                    inputProps={{ "aria-label": "description" }}
                  />
                  <Box >
                    {/*<FileCopyIcon onClick={ copyText } className={classes.filecopyicon}/>*/}
                    <SaveIcon onClick={() => saveUser("credentials")} className={classes.filecopyicon} />
                  </Box>
                </Box>
                <Box className={classes.iconOutUser}>
                  <NativeSelect
                    name="role"
                    value={role}
                    onChange={inputChange}
                    variant="filled"
                    className={classes.input1}
                    disableUnderline
                  >
                    {userRole.map((e, key) => {
                      return <option key={key} value={key}>{e}</option>;
                    })}
                  </NativeSelect>
                  <Box >
                    <SaveIcon onClick={() => saveUser("role")} className={classes.filecopyicon} />
                  </Box>
                </Box>
                <Box className={classes.iconOutUser}>
                  <NativeSelect
                    name="status"
                    value={status}
                    onChange={inputChange}
                    variant="filled"
                    className={classes.input1}
                    disableUnderline
                  >
                    {userStatus.map((e, key) => {
                      return <option key={key} value={key}>{e}</option>;
                    })}
                  </NativeSelect>
                  <Box >
                    <SaveIcon onClick={() => saveUser("status")} className={classes.filecopyicon} />
                  </Box>
                </Box>
                <Box className={classes.Customerinfoo}>
                  <Typography>Customer Details</Typography>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                  >
                    <Tab label="$ Revenue" {...a11yProps(0)} />
                    <Tab label="Usage" {...a11yProps(1)} />
                    <Tab label="Used Coupons" {...a11yProps(2)} />
                    <Tab label="Purchased Plans" {...a11yProps(3)} />
                  </Tabs>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} lg={9} md={9}>
                <TabPanel value={value} index={0}>
                  <Box className={classes.tablebox}>
                    <Typography className={classes.tabletitle}>$ Revenue</Typography>

                    <Paper className={classes.paperclass}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Box className={classes.boxrevenue}>
                            <Typography>Total Payment</Typography>
                            <Typography variant="h4" className={classes.pricesrevenue}>$ {infos.total_payment}</Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Box className={classes.boxrevenue}>
                            <Typography>Last Payment</Typography>
                            <Typography variant="h4" className={classes.pricesrevenue}>$ {infos.last_payment}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className={classes.spacingDiv}>

                      </Box>
                      <Bar

                        options={{

                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  drawOnChartArea: false,

                                },
                                ticks: {
                                  fontSize: 12,
                                  fontColor: "#000",
                                },
                              },
                            ],
                            yAxes: [

                              {
                                gridLines: {
                                  drawOnChartArea: false,


                                },
                                ticks: {
                                  callback: function (value, index, values) {
                                    return '$' + value;
                                  },
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#000",

                                },
                              },
                            ],
                          },
                        }}
                        data={BarChartRevenue}
                      />

                    </Paper>
                  </Box>


                </TabPanel>
                <TabPanel value={value} index={1}>

                  <Box className={classes.tablebox}>
                    <Typography className={classes.tabletitle}>Usage</Typography>

                    <Paper className={classes.paperclass}>


                      <Bar
                        options={{

                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  drawOnChartArea: false,


                                },
                                ticks: {
                                  fontSize: 12,
                                  fontColor: "#000",
                                },
                              },
                            ],
                            yAxes: [

                              {
                                gridLines: {
                                  drawOnChartArea: false,
                                },
                                ticks: {
                                  callback: function (value, index, values) {
                                    return value + 'GB';
                                  },
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#000",

                                },
                              },
                            ],
                          },
                        }}
                        data={BarChartData}
                      />

                    </Paper>
                  </Box>


                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box className={classes.tablebox}>
                    <Typography className={classes.tabletitle}>Used Coupons</Typography>
                    <TableContainer component={Paper} className={classes.TableContaine1}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead className={classes.tableheadplan}>
                          <TableRow >
                            <TableCell align="left" className={classes.tblcell}>Title</TableCell>
                            <TableCell align="left" className={classes.tblcell}>%</TableCell>
                            <TableCell align="left" className={classes.tblcell}>$ Amount</TableCell>
                            <TableCell align="left" className={classes.tblcell}>GB</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tblbodyplan}>
                          {infos.coupons.map((coupon) => (
                            <TableRow key={coupon.id}>
                              <TableCell align="left" className={classes.tblcell1}>{coupon.title}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{coupon.coupon_per}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>${coupon.discount_amt}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{coupon.data} GB</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box className={classes.tablebox}>
                    <Typography className={classes.tabletitle}>Purchased Plans</Typography>
                    <TableContainer component={Paper} className={classes.TableContaine1}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead className={classes.tableheadplan}>
                          <TableRow >
                            <TableCell align="left" className={classes.tblcell}>Plan Name</TableCell>
                            <TableCell align="left" className={classes.tblcell}>GB</TableCell>
                            <TableCell align="left" className={classes.tblcell}>Stripe Email used</TableCell>
                            <TableCell align="left" className={classes.tblcell}>Purchased Date</TableCell>
                            <TableCell align="left" className={classes.tblcell}>Plan Start Date</TableCell>
                            <TableCell align="left" className={classes.tblcell}>Plan End Date</TableCell>
                            <TableCell align="left" className={classes.tblcell}>Days</TableCell>
                            <TableCell align="left" className={classes.tblcell}>$ Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tblbodyplan}>
                          {infos.plans.map((plan) => (
                            <TableRow key={plan.id}>
                              <TableCell align="left" className={classes.tblcell1}>{plan.title}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{plan.data} GB</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{infos.pay_email}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{plan.purchase_date}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{plan.plan_active_date}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{plan.plan_expired_date}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{plan.date_diff}</TableCell>
                              <TableCell align="left" className={classes.tblcell1}>{plan.price}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </main>
    </div>
  );
}
