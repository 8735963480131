import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import image1 from '../../assets/images/innerbanner.jpg'
import NativeSelect from '@material-ui/core/NativeSelect';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Input,Button } from '@material-ui/core';
import image3 from '../../assets/images/theme.png'
import { useHistory } from 'react-router-dom';
import Dashboardsidebar from './Dashboardsidebar';
import { logout, display_name, avatar } from '../../auth';
import Popover from '@material-ui/core/Popover';
import { header, getDomain } from "../../auth";
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import AuthenticatJS from './AuthenticateIP';
const axios = require('axios');

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  typography: {
    padding: theme.spacing(1),
  },
  popover: {
    backgroundColor:"#1870ff",
    color: "white",
  },
  appBar: {
   
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      boxShadow: "none",
     
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }),
    boxShadow: "none",
    
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: "0px",
    paddingRight:"0px",
    float:"right",

    [theme.breakpoints.up(1200)]: {
      display: "none",
    },
    color: "#fff"
  },
  
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "none",
    backgroundColor: "#01092d",
    width:"250px",

  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    minHeight: "90px",
    [theme.breakpoints.down(1200)]: {
      minHeight: "65px",
      height: "65px",
     }
  },
 
  boxaraea: {
    paddingLeft: "20px",
    paddingRight: "20px",
    
  },
  logo: {
    height: "auto",
    width: "180px",
    marginRight: "10px",
    marginTop: "15px",
    float: "left",
    marginBottom: "10px",
    [theme.breakpoints.down(1200)]: {
      width: "130px",
      margin: "3px 0px 0px",
      }
  },
  
  toolbarTitle: {
    float: "right",
    marginTop: "13px",
    display: "flex"
  },
  nativeslect: {
    backgroundColor: "#000f48",
    backgroundImage: `url(${image3})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "15px 7px",
    color: "#65708f",
    paddingLeft: "29px",
    fontSize: 13,
    fontWeight: "500",
    borderRadius: 18,
    width: 122,
    letterSpacing: 1,
    height: 38,
    marginTop: "10px",
    "& .MuiNativeSelect-icon": {
      color: "#65708f",
    },
    "& .MuiNativeSelect-iconFilled": {
      right: "10px"
    },
    "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
      backgroundColor: "#000f48",
      paddingLeft: "20px"
    },
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
      paddingLeft: "20px"
    },
[theme.breakpoints.down(1200)]: {
  display: "none",
}
  },
  link: {
    
    [theme.breakpoints.down(1200)]: {
      display: "none",
      textDecoration: "none",
    },
    margin: theme.spacing(1, 1.5),
    color: "#FFF",
    fontSize: 15,
    fontWeight: "400px",
    textDecoration: "none",
    letterSpacing: 1.2
  },
  link1: {
    margin: "8px 30px 8px 20px;",
    lineHeight: "41px",
    color: "#fff",
    fontSize: 15,
    fontWeight: "400",
    textDecoration: "none",
    borderRadius: "25px",
    letterSpacing: 1.2,
    [theme.breakpoints.down(1200)]: {
   display:"none"
    }
    },
    inptbox: {
        backgroundColor: "#000f48",
        margin: "3px 30px 3px 20px",
        color: "#65708f",
        borderRadius: 25,
        letterSpacing:1,
        height: 50,
      outline: "none",
      display: "flex",
       [theme.breakpoints.down(1200)]: {
      display:"none"
    }
    },
    inptbox11: {
        color: "#65708f",
        paddingLeft:"18px",
        fontSize: 15,
        fontWeight: "300",
        letterSpacing: "0.2px",
         [theme.breakpoints.down(1200)]: {
         display:"none"
    }
  },
  editicon:{marginTop: "12px",
    marginRight: "16px",
    fontSize: "22px",
     [theme.breakpoints.down(1200)]: {
   display:"none"
    }
  },
  
  toolbar: {
    minHeight: "90px",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.breakpoints.down(1200)]: {
      minHeight: "65px",
     }
    },
    maincontainer:{
      maxWidth: "100%",
     paddingLeft: "30px",
      paddingRight:"30px",
      [theme.breakpoints.down(1200)]: {
         padding:"0 20px",
     }

    },
 userinfo: {
   fontSize: "15px",
   fontFamily: "Poppins",
   color: "#0059fd",
   marginLeft: "8px",
   fontWeight: "500",
   [theme.breakpoints.down(1200)]: {
   display:"none"
    }
   
  },
 logoutDesign: {
   fontSize: "15px",
   fontFamily: "Poppins",
   color: "#fff",
   marginLeft: "8px",
   fontWeight: "500",
   [theme.breakpoints.down(1200)]: {
   display:"none"
    }
   
  },
 userinfo1: {
   fontSize: "13px",
   fontFamily: "Poppins",
   color: "#5d6987",
   marginLeft: "8px",
   fontWeight: "500",
    [theme.breakpoints.down(1200)]: {
   display:"none"
    }
   
 },
  avatarr: {
    height: "60px",
    width: "60px",
    marginTop: "-2px",
     [theme.breakpoints.down(1200)]: {
       display:"none"
    }
  },
  iconbtn: {
    backgroundColor: "#000f48", marginRight: "32px",
    height: "50px",
    width: "50px",
    marginTop:"3px",
    marginLeft: "18px",
    "&:hover": {
       backgroundColor: "#000f48",
    },
     [theme.breakpoints.down(1200)]: {
   display:"none"
    }
  },
  userinfobox: {
    padding: "8px 35px 0px 6px",
     [theme.breakpoints.down(1200)]: {
   display:"none"
    }
  },
  circleoutline: {
    color: "#007822",
    marginTop: "4px",
    marginLeft: "5px",
    fontSize: "40px",
     [theme.breakpoints.down(1200)]: {
   display:"none"
    }
  },
  closicon: {
    color:"#fff"
  },
    btntext: {
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 7,
    border: "1px solid",
    textTransform: "none", 
    padding: "7px 15px",
  },
    mydialog: {
    background: "#1872ff",
    width:"600px",
  
   padding: "20px 25px",
   "& h2.MuiTypography-h6": {
     color: "#fff",
     marginBottom:"0px",fontSize:"22px",
   }
   
 },
}));


export default function DashboardTop() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const current=localStorage.getItem("theme")?localStorage.getItem("theme"):0;
  const [open, setOpen] = React.useState(false);
  const [openNotifPopup, setNotifPopup] = React.useState(false);
  const [theme1, settheme1] = React.useState(current);

  const handleDrawerOpen = () => {
    setOpen(true);
   
  };
 const handleChange = (event) => {
   if (event.target.value != current) {
     settheme1(event.target.value);
     localStorage.setItem("theme", event.target.value);
     window.location.reload();
   }
      
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [ notifications, setNotification] = React.useState([]);
  const [ notiCount, setNotiCount] = React.useState(0);
  useEffect(()=> {
	getNoti();
	setInterval(() => {
        getNoti();//this won't work as expected
        console.log("timer"); //this works as expected
    }, 60000)
  },[])
  const getNoti =() =>{
	  let last_id=notifications.length>0?notifications[notifications.length-1].id:0;
    axios.get(getDomain()+'/notifications',{headers : header()})
      .then(function(response){
		  if(response.data.notifications.length!=notifications.length || response.data.count>0)
		  {
			  setNotification(response.data.notifications);
		  }        
        if(response.data.count>0) {		  
          setNotiCount(response.data.count > response.data.notifications.length ? response.data.notifications.length : response.data.count);
		  setNotifPopup(true);
        }
        console.log(response.data);
      })
      .catch(function(error){
        console.log(error);
      });
  }
  const updateNoti =() =>{
    axios.put(getDomain()+'/update_notification',{ id: notifications[0].id },{headers : header()})
      .then(function(response){
        console.log(response.data);
        if(response.data)
        {
          setNotiCount(0);
        }
      })
      .catch(function(error){
        console.log(error);
      });
  }
  //----------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    if(notifications.length>0){
      updateNoti();
    }
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const handleCloseChatPopUp= () => {
	   setNotifPopup(false);
	   updateNoti();
  };
  const open2 = Boolean(anchorEl);
  const prpover_id = open2 ? 'simple-popover' : undefined;
  //-----------------------------------------------------

	var indents = [];
	for (var i = 0; i < notiCount; i++) {
	indents.push(<Typography className={classes.typography} style={{ borderBottom: "1px solid #f5f5f5"}}>{notifications[i].message}</Typography>);
	}

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
 style={{
              backgroundImage: `url(${image1})`,
            }}
      >
        <Toolbar className={classes.toolbar}>
          <Container  className={classes.maincontainer}>
           <IconButton
            
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Link href="/" className={clsx(classes.link, open && classes.hide)}> <img src={require("../../assets/images/logo.png")} alt="img" className={classes.logo} /></Link>
         
            <nav className={classes.toolbarTitle}>
                         
            <Link variant="button" color="textPrimary" href="/" className={classes.link1} underline="none">
              Home
            </Link>
           
            <Link variant="button" color="textPrimary" href="/faq" className={classes.link1} underline="none">
              FAQ
            </Link>
              <IconButton aria-describedby={prpover_id} variant="contained" color="primary" onClick={handleClick2} className={classes.iconbtn}>
                { 
                  notiCount>0?
                  <Badge badgeContent={ notiCount } color="secondary">
                    <NotificationsNoneIcon style={{ color: "#fff" }} />
                  </Badge>
                  :
                  <NotificationsNoneIcon style={{ color: "#fff" }} />
                }
              </IconButton>
            <Popover
              id={prpover_id}
              open={open2}
              anchorEl={anchorEl}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{paper:classes.popover }}
            >
              {
                notifications.map((e,key)=>{
                  return <Typography className={classes.typography}>{e.message}</Typography>;
                })
              }
              {notifications.length>0?"":<Typography className={classes.typography}>We have no any notificiation yet</Typography>}
            </Popover>
              <Avatar alt="Remy Sharp" src={avatar()} className={classes.avatarr}/>
            <Box className={classes.userinfobox}>
            <Typography className={classes.userinfo}>{display_name()}</Typography>
            <Link variant="button" color="textPrimary" href="/login" onClick={ logout } className={classes.logoutDesign} underline="none">
              logout
            </Link>
              {/*
              <Typography onClick={ logout } className={classes.userinfo1}>Logout</Typography>
          */}
              </Box>
         
          <NativeSelect
                              value={theme1}
                              onChange={handleChange}
                              variant="filled"
                             
                              className={classes.nativeslect}
          disableUnderline
        >
                      <option value="0">DARK</option>
                      <option value="1">LIGHT</option>
            </NativeSelect>
            </nav>
            </Container>
        </Toolbar>
     
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon className={classes.closicon}/> : <ChevronRightIcon className={classes.closicon}/>}
          </IconButton>
        </div>
        <Divider />
       <Dashboardsidebar/>
      </Drawer>
	  <Dialog
        open={openNotifPopup}
        onClose={handleCloseChatPopUp}
        className={classes.dialogpaper}
       
      >
        <DialogTitle className={classes.mydialog}>You have a new message from Admin</DialogTitle>
        <DialogContent className={classes.dialogcontnet}>
          <DialogContentText >
           { indents }
        </DialogContentText>            
        </DialogContent>
        <DialogActions className={classes.dialogaction}>
        <Button
                variant="contained"
                color="secondary"
                className={classes.btntext}
                size="small"
                onClick={handleCloseChatPopUp}
              >
                Okay
              </Button>
        </DialogActions>
      </Dialog>  
      </div>
    
      
    
  );
}