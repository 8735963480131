import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#151948",
      light: "#1d2255",
        },
        secondary: {
           main: "#1870ff",
        },
        text: {
            main:"#fff"
        }
  
  },
  typography: {
  
    h5: {
      fontSize: 15,
      fontWeight: "400",
      color: "#fff",
      letterSpacing: 1,
      marginBottom: 3,
      lineHeight: "20px",
    },
    h3: {
      fontSize: 36,
      fontWeight: "600",
      color: "#dbdce2",
      letterSpacing: 1.7,
      marginBottom: 3,
      lineHeight:"50px"
     
    },
    h2: {
      fontSize: 15,
      fontWeight: "500",
      color: "#797fb3",
      letterSpacing: 1,
      marginBottom: 3,
    },
    h6: {
      fontSize: 15,
      fontWeight: "500",
      color: "#797fb3",
      letterSpacing: 1,
      marginBottom: 3,
      lineHeight: "20px",
    },
    h4: {
      fontSize: 19,
      fontWeight: "500",
      color: "#101013",
      letterSpacing: 1,
    },
  }
});
