import React, { useState, useEffect } from "react";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Top from "../components/Top";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Footer1 from "../components/Footer1";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from '@material-ui/core/Accordion';
import { colors } from '../components/colors'
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Paper } from "@material-ui/core";
import image1 from '../../assets/images/innerbanner.jpg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import dividerimage from '../../assets/images/divider.png';
import { header, getDomain } from "../../auth";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
 
   content: {
    minHeight: "100vh",
    flexGrow: 1,
      height: "auto",
      backgroundColor: colors.darkBlue,
    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor:colors.lightblue1,
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    },
    container1: {
      backgroundColor: colors.darkBlue,
     
        
    },
    accordion: {
    borderRadius: "15px",
    margin: "20px",
      marginLeft: "0px",
    marginRight:"0px",
    padding: "10px",
    background: "#000c40",
    color: colors.whitelight,
    boxShadow: "none",
    "& .MuiIconButton-root": {
    color: colors.whitelight,
    },
    [theme.breakpoints.down(959)]: {
      padding:"0px 2px"
   }


   
     
    },
   
    heading: {
        fontSize: 17,
        fontFamily: "Poppins",
        fontWeight: "500",
         color: colors.whitelight,
         [theme.breakpoints.down(959)]: {
          fontSize: 16,
          fontFamily: "Poppins",
          fontWeight: "400",
       } 
  },
  imageSrc: {
    height:"auto",
    width:"auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
     paddingBottom:"15px"
  },
  dividerimg: {
    height: 20,
    width: "auto",
    marginTop: "30px",
    [theme.breakpoints.down(1200)]: {
      marginTop: "20px",
   },
   [theme.breakpoints.down(959)]: {
    display:"none",
 }
},
pagetitle: {
lineHeight: "30px !important",
fontSize:"32px !important",
[theme.breakpoints.down(1200)]: {
fontSize:"28px !important",
fontWeight: "500"
},
[theme.breakpoints.down(959)]: {
fontSize:"24px !important",
},
[theme.breakpoints.down(599)]: {
fontSize:"20px !important",
marginBottom:"0px",
}
},
titlebox: {
textAlign:"center",
marginBottom:"24px",
paddingTop:"50px",
[theme.breakpoints.down(1200)]: {
  paddingTop:"35px",
  marginBottom:"10px"
},
[theme.breakpoints.down(959)]: {
  paddingTop:"26px",
  marginBottom:"10px"
},
[theme.breakpoints.down(599)]: {
  paddingTop: "33px",
  marginBottom: "5px"
},

},

      
    paper: {
      paddingTop: "30px",
      paddingBottom:"30px",
        boxShadow: "none",
         backgroundColor: colors.darkBlue,
         [theme.breakpoints.down(959)]: {
          paddingTop: "10px",
       }

       },
    accordiondeatils: {
        fontSize: 15,
      fontWeight: "500",
       color:colors.lightpurple1,
      letterSpacing: 0.6,
      lineHeight: "23px",
      marginTop: "0px",
      "& p": {
        marginTop:"0px",
      
      },
      [theme.breakpoints.down(959)]: {
        marginBottom:"20px",
        marginTop: "-9px",
        fontSize: 14,
     }
    },
  accordationtextt: {
      padding:"0px 16px 0px"
    }
}));
const Accordion1 = withStyles({
  root: {
   
    '&:last-child': {
     borderBottomLeftRadius: "15px",
         borderBottomRightRadius: "15px"
        },
       '&:first-child': {
      borderTopLeftRadius: "15px",
         borderTopRightRadius: "15px"
    },
   
  },
  expanded: {},
})(MuiAccordion);
function FAQ() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
   
  useEffect(()=>{
    axios.get(getDomain()+'/faq-list',{
      headers : header()
    })
      .then(function(response){
        setRows(response.data);
        console.log(response.data);
      })
      .catch(function(error){
        console.log(error);
      });
  },[])

  return (
      <>
          <Top />
          <div className={classes.drawerHeader}/>
          <main className={classes.content} >
                   <div className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image1})`,
            }}>
            <Box className={classes.titlebox}>
            <Typography variant="h3" className={classes.pagetitle}>Frequently Asked Questions</Typography>   
             <img src={dividerimage} alt="no img" className={classes.dividerimg}/>
             </Box>
        </div>
              <Container className={classes.container1} maxWidth="lg" width="1200px">
              <Paper className={classes.paper}>
                {/*--------------------------------------------------------------------------------*/}
                { rows.map((e, key) => {
                  return <Accordion1 className={classes.accordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{ e.question }</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordationtextt}>
                    <Typography className={classes.accordiondeatils}>
                    <div dangerouslySetInnerHTML={{ __html: e.answer }}  />
                    </Typography>
                                        
                  </AccordionDetails>
                </Accordion1>;
                })}
                {/*--------------------------------------------------------------------------------*/}
              </Paper>
        </Container>
        <Footer1/>
       </main>
            
      
    </>
  );
}
export default FAQ;
