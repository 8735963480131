import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import image1 from '../../assets/images/innerbanner.jpg'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Input } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import image3 from '../../assets/images/theme.png'
import { logout, display_name, avatar } from '../../auth';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
 
    root1: {
    //    backgroundColor:"#151948",
  },
     inptbox11: {
        color: "#65708f",
        paddingLeft:"18px",
        fontSize: 15,
        fontWeight: "300",
        letterSpacing:"0.2px"
  },
  editicon:{marginTop: "12px",
    marginRight: "14px",
    fontSize: "16px"},
    circleoutline: {
    color: "#007822",
    marginTop: "4px",
    marginLeft: "5px",
    fontSize: "40px",
    [theme.breakpoints.down(1200)]: {
      display:"none"
    }
    },
       inptbox: {
        backgroundColor: "#000f48",
        margin: "3px 30px 3px 20px",
        color: "#65708f",
        borderRadius: 25,
        letterSpacing:1,
        height: 42,
      outline: "none",
      display: "flex",
      float:"left",
      [theme.breakpoints.down(1200)]: {
        margin: "15px 20px 10px 20px",
      }
    },
       link1: {
    margin: "8px 30px 8px 20px;",
    lineHeight:"41px",
    color: "#fff",
    fontSize: 14,
    fontWeight: "400",
    textDecoration: "none",
    borderRadius: "25px",
    [theme.breakpoints.down(1200)]: {
      margin: "0px 20px 0px 20px",
      display:"block",
    },
   
     letterSpacing:1.2
    },
      userinfo1: {
   fontSize: "13px",
   fontFamily: "Poppins",
   color: "#5d6987",
   marginLeft: "8px",
   fontWeight:"500"
   
 },
  avatarr: {
    height: "50px",
    width: "50px",
    marginTop:"0px",
    marginLeft:"20px",
    marginRight:"0px",
    float:"left",

  },
  iconbtn: {
    backgroundColor: "#000f48", marginRight: "32px",
    height: "50px",
    width: "50px",
    marginTop:"10px",
    marginLeft: "18px",
    "&:hover": {
       backgroundColor: "#000f48",
    }
  },
  userinfobox: {
    padding:"4px 15px 0px 4px",
    float:"left",
    },  
   userinfo: {
   fontSize: "15px",
   fontFamily: "Poppins",
   color: "#0059fd",
   marginLeft: "8px",
  fontWeight:"500"
   
    },
    nativeslect: {
    backgroundColor: "#000f48",
    backgroundImage: `url(${image3})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "15px 7px",
    color: "#65708f",
    paddingLeft: "29px",
    fontSize: 13,
    fontWeight: "500",
    borderRadius: 18,
    width: "84%",
    letterSpacing: 1,
    height: 38,
    marginTop: "21px",
    marginLeft: "20px",
    marginBottom: "21px",
    "& .MuiNativeSelect-icon": {
      color: "#65708f",
    },
    "& .MuiNativeSelect-iconFilled": {
      right: "10px"
    },
    "& .MuiNativeSelect-select:not([multiple]) option, .MuiNativeSelect-select:not([multiple]) optgroup": {
      backgroundColor: "#000f48",
      paddingLeft: "20px"
    },
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
      paddingLeft: "20px"
    }
  },
}));

export default function ListItem1() {
  const history = useHistory();
  const classes = useStyles();
  const [theme1, settheme1] = React.useState("");
 const handleChange = (event) => {
   if (event.target.value == "light") {
     settheme1("light");
     history.push('/Dashboardlight')
   } else {
     settheme1("dark");
     history.push('/Dashboard')
   }
      
    };

  return (
    <div className={classes.root1}>
	      <Avatar alt="Remy Sharp" src={avatar()} className={classes.avatarr} />
            <Box className={classes.userinfobox}>
            <Typography className={classes.userinfo}>{display_name()}</Typography>
          </Box>
           <Box className={classes.inptbox}>
                <CheckCircleOutlineIcon className={classes.circleoutline}/>
                     <Input
                      defaultValue="Authenticate my IP"
                      disableUnderline
                          inputProps={{ "aria-label": "description" }}
                          className={classes.inptbox11}
                      />
                <EditIcon className={classes.editicon}/>
          </Box>
           <Link variant="button" color="textPrimary" href="/" className={classes.link1} underline="none">
              Home
            </Link>
           
            <Link variant="button" color="textPrimary" href="/FAQ" className={classes.link1} underline="none">
              FAQ
            </Link>
          <IconButton className={classes.iconbtn}><NotificationsNoneIcon style={{ color: "#fff" }} /></IconButton>
          <div className={classes.clear}> </div> 
           <NativeSelect
                              value={theme1}
                              onChange={handleChange}
                              variant="filled"
                             
                              className={classes.nativeslect}
          disableUnderline
        >
                      <option value="">DARK</option>
                      <option value="light">LIGHT</option>
            </NativeSelect>
			
			
			<Button href="/" onClick={logout} variant="contained" color="secondary" style={{ textAlign: "center" }} className={classes.link1}>
			Logout
			</Button>


     
    </div>
  );
}
