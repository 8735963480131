import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
   
    description: {
      fontSize: 14,
      fontWeight: "500",
      color: "#515b79",
      letterSpacing: 1,
        marginBottom: 3,
      fontFamily:"Poppins"
      
    },
   imageSrc: {
    backgroundColor:"#01092d"
  },
    readmorebutton: {
    borderRadius: "40px",
    border: "2px solid #1870ff",
    color: "#1870ff",
    letterSpacing: 0.6,
    fontWeight: "600",
    fontSize: 16,
    marginTop: 40,
    padding: "10px 25px",
    [theme.breakpoints.down(599)]: {
      letterSpacing: 0.1,
      fontWeight: "400",
      fontSize: 15,
      marginTop: 25,
      padding: "8px 20px",
    } 
    
    },
  dashboardimage: {
    width: "57%",
    height: "auto",
    position: "relative",
    zIndex: 2,
    float:"left",
    [theme.breakpoints.down(599)]: {
      width: "100%",
      marginLeft: "0",
      marginTop: "-10px",
    } 
  },
   dashboardimage1: {
    width: "57%",
    height: "auto",
    marginLeft: "-14%",
     marginTop: "80px",
    position: "relative",
     zIndex: 1,
    float:"left",
    [theme.breakpoints.down(959)]: {
      marginTop: "45px",
    } ,
    [theme.breakpoints.down(599)]: {
      marginTop: "0px",
      width: "100%",
      marginLeft: "0",
    } 
   },
    heading: {
        fontSize: "18px",
        fontWeight: "500",
        fontFamily: "Poppins",
         color: "#dbdce2",
         [theme.breakpoints.down(599)]: {
          fontSize: "14px",
        fontWeight: "400",
        } 
  },
  dashboardarea: {
    marginTop: "65px",
    display: "block",
    marginBottom: "0px",
    [theme.breakpoints.down(959)]: {
      marginTop: "45px",
    },
    [theme.breakpoints.down(599)]: {
      marginTop: "35px",
    }, 
    },
  clear: {
      clear:"both"
    },
  dashboardtext: {
      textAlign:"center", padding:"45px",
      [theme.breakpoints.down(599)]: {
        padding:"20px 5px 10px",
      } 
    },
  specializeddiv: {
      textAlign:"center"
  },
  specializetxt: {
    display:"inline-block",borderRadius:60,
    backgroundColor:"#010d3f",
    textAlign:"center",
    marginTop:30,
    padding:"30px 70px",
    [theme.breakpoints.down(959)]: {
      padding:"23px 28px",
      marginTop:"0px",
    },
    [theme.breakpoints.down(599)]: {
      padding:"16px 20px",
      marginTop:"22px",
    }
  },
  sectionHead:{
    fontSize:"30px", 
    [theme.breakpoints.down(959)]: {
      fontWeight: "400",
      fontSize:"20px", 
      marginBottom:"0px" ,
      lineHeight:"normal", 
    },
    [theme.breakpoints.down(599)]: {
      fontSize:"15px",
      letterSpacing:"0.2px"
    }
  }
}));
function HomeContent5() {
    AOS.init();
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className={classes.imageSrc}
           >
        <Container  maxWidth="lg" width="1200px">
          <Box className={classes.dashboardarea}>
          <img src={require("../../assets/images/dashboard1.png")} alt="im1" className={classes.dashboardimage}  data-aos="zoom-in" data-aos-duration="700" />
           <img src={require("../../assets/images/dashboard2.png")} alt="im1" className={classes.dashboardimage1}  data-aos="zoom-in" data-aos-duration="700" />
           <div className={classes.clear}></div>
             </Box>

             <Box className={classes.dashboardtext}>
                <Typography className={classes.heading}>Track everything related to your subscription, generate your private Live Proxies via our custom and user-friendly dashboard with the ability to choose dark or light theme.</Typography>
              
                    <Button variant="outlined" className={classes.readmorebutton} onClick={() => history.push("/pricing")}>Get Started</Button>
              </Box>
              <div className={classes.specializeddiv} data-aos="fade-bottom" data-aos-duration="700">
              <Box className={classes.specializetxt} >
                    <Typography variant="h3" style={{color: "#1797fa" }} className={classes.sectionHead}>Specialized the US Based Releases, Raffles</Typography>
                    
            </Box>
            </div>
           
                </Container>
      </div>
  );
}

export default HomeContent5;
