import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Listitem1 from "./Listitem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RListitem1 from "./Rlisitem";
import Link from '@material-ui/core/Link';
import { logout } from '../../auth';
import { useHistory } from "react-router-dom";
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  myTop:{
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
      height: 700,
      background: "#151948",
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",

      borderBottom: "1px solid #d2d3d8",
    },
    boxShadow: "none",

    borderBottom: "1px solid #d2d3d8",
  },
  menuButton: {
    marginRight: "5px",
    marginLeft: "-13px", 
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    borderBottom: "1px solid #24284b",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    position: "fixed",
    whiteSpace: "nowrap",

    color: "#e9e9e9",
    background: "#151948",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  title: {
    flexGrow: 1,
  },
  subhead: {
    color: "#868797",
    fontSize: "13",
  },
  mainheading: {
    fontSize: 20,
    fontWeight: "700",
  },
  mainheading1: {
    fontSize: 20,
    fontWeight: "600",
  },
  logo: {
    width: 35,
    height: "auto",
    cursor: "pointer",
  },
  lgtbtn: {
    marginRight: "1px",
    paddingRight: 0,
  },
}));

function RDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden smUp implementation="css">
        <AppBar position="fixed" className={classes.appBar} color="inherit">
          <Toolbar className={classes.myTop}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.title}>
              <Typography className={classes.mainheading1} color="secondary">
                Live Proxies
              </Typography>
            </div>

            <IconButton color="secondary" className={classes.lgtbtn}>
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden xsDown implementation="css">
        <AppBar position="fixed" className={classes.appBar} color="inherit">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            
            <div className={classes.title}>
              <Typography className={classes.mainheading} color="secondary">
                Live Proxies
              </Typography>
             
            </div>
            <Typography color="secondary" className={classes.logouttxt}>
              <Link href="/" onClick={ logout } underline="none">
                Logout
              </Link>
            </Typography>
            <IconButton color="secondary" className={classes.lgtbtn}>
              <Link href="/" onClick={ logout } underline="none">
                <ExitToAppIcon />
              </Link>
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="sidebar">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <div className={classes.toolbarIcon}>
              <img
                src={require("../../assets/admin-images/logo.png")}
                className={classes.logo}
                onClick={()=> history.push("/") }
                alt="no img"
              />
            </div>
            <RListitem1 />
            <Divider />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <div className={classes.toolbarIcon}>
              <img
                src={require("../../assets/admin-images/logo.png")}
                className={classes.logo}
                onClick={()=> history.push("/") }
                alt="no img"
              />
            </div>
            <Listitem1 />
            <Divider />
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default RDrawer;
