import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core";
import VisibilityOffSharpIcon from "@material-ui/icons/VisibilityOffSharp";
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditSharpIcon from "@material-ui/icons/EditSharp";
import DeleteIcon from "@material-ui/icons/Delete";
import ChatIcon from '@material-ui/icons/Chat';
import FormatColorResetIcon from "@material-ui/icons/FormatColorReset";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import RDrawer from "../components/Top";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { header, getDomain,user_id, logout,isAdmin } from "../../auth";
import { useLocation } from "react-router-dom";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import NativeSelect from "@material-ui/core/NativeSelect";
import { CustomAlert, ConfirmAlert, set_info, user_message, user_severity, delete_info, } from "../../helper/helper";
import { CloseOutlined } from "@material-ui/icons";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
    minHeight: "100vh",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },

  title1: { justifyContent: "flex-end", display: "flex", marginBottom: "10px" },
  btntext: {
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 7,
    border: "1px solid",
    textTransform: "none", 
    padding: "7px 15px",
  },

  iconcolor: {
    color: "#81839f",
    fontSize: "20px",
  },
  mydialog: {
    background: "#1872ff",
    width:"600px",
  
   padding: "20px 25px",
   "& h2.MuiTypography-h6": {
     color: "#fff",
     marginBottom:"0px",fontSize:"22px",
   }
   
 },
 dialogpaper: {
   
   "& .MuiPaper-rounded ":{
    borderRadius: "20px"
    }
 },
 dialogcontnet: {
   padding:"5px 25px",
   minHeight:150
 },
 dialogaction: {
   padding: "10px 17px",
   backgroundColor:"#f9f9f9"
 },
 
    input1: {
      background: "#f4f3f8",
      borderRadius: 5,
      borderBottom: "none",
      color: "#797fb3",
      fontSize: 14,
      fontWeight: "500",
      padding: "5px",
      display: "flex",
      paddingLeft: 10,
      [theme.breakpoints.down(1200)]: {
        width:"100%" 
      },
    },
tbllayout: {
    "& table": {
      overflow: "hidden",
      borderRadius: 10,
      "& thead th span.MuiTableSortLabel-root:hover": {
        color: "#fff !important",
      },
      "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
        color: "#fff !important",
      },
      "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
          color: "#fff !important",
        }
    },
  },
}));
const tableIcons = {
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
     };


export default function Customer() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = React.useState(false);
  const admin=localStorage.getItem("admin");
  let columns = [
  ]
  //---------------------------
  columns[0] = {
    title: "Discord Name",
    field: "discord_username",
  }
  columns[1] = { title: "Discord Email", field: "email" }
  columns[2] = { title: "Discord ID", field: "discord_id" }
  columns[3] = {
	title: "Stripe Email",
	field: "stripe_email",
	}
  if(isAdmin())
  {
    columns[4] = { title: "User:Pass", field: "provider_info" }
    columns[5] = {
      title: "$ Total Amount",
      field: "total_amount",
    }
   
    /*columns[4] = {
      title: "Purchase Date",
      field: "purchased_date",
      type: "date",
    }
    columns[5] = { title: "Expire Date", field: "expire_date", type: "date" }*/
  }
  //-------------------------
  //-------------------------------
  let actionList = [
  ]
  actionList[0] = {
      icon: () => (
        <VisibilityOffSharpIcon
          size="small"
          className={classes.iconcolor}
        />
      ),
      tooltip: "View User",
      onClick: (event, rowData) =>
      window.open("/dashboard/"+rowData.id,"_blank"),
      }
      actionList[1] = {
      icon: () => (
        <FormatColorResetIcon
          size="small"
          className={classes.iconcolor}
        />
      ),
      tooltip: "Format User",
      onClick: (event, rowData) =>
        formatProxies(rowData.id),
    }
    if(isAdmin())
  {
    actionList[2] = {
      icon: () => (
        <ChatIcon
          size="small"
          className={classes.iconcolor}
        />
      ),
      tooltip: "Send Message",
         onClick: (event, rowData) => openChatModel(rowData),
    }
    actionList[3] = {
      icon: () => (
        <EditSharpIcon
          size="small"
          className={classes.iconcolor}
        />
      ),
      tooltip: "Edit User",
         onClick: (event, rowData) =>  window.open("/admin/customer-details/"+rowData.id,"_blank"),
    }
    actionList[4] = {
      icon: () => (
        <DeleteIcon
          size="small"
          className={classes.iconcolor}
        />
      ),
      tooltip: "Format User",
      onClick: (event, rowData) =>
      openModel(rowData),
    }
  }
    actionList[5] = {
      icon: () => (
        <AddBoxIcon
          size="small"
          className={classes.iconcolor}
        />
      ),
      tooltip: "Add Plan",
      onClick: (event, rowData) =>
      handleClickopenPlanPopUp(rowData),
    }
  //------------------------------
   function formatProxies(id)
  {
    console.log(id);
    axios.post(getDomain()+"/format-proxies",{ user_id:id },{
      headers : header()
    })
    .then(function(response){
      if(response.data==1)
      {
        showMessage("Proxies reset successfully","success");
      }
      else
      {
        showMessage("Something is went wrong, please try after sometime","error");
      }
    })
    .catch(function(error){
      console.log(error);
      showMessage("Something is went wrong, please try after sometime","error");
    })
  }
  var [ deleteObj, setDeleteObj ]=useState({});
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  const [PopUpMessage, setPopUpMessage] = React.useState("");
  const [openPlanPopUp, setopenPlanPopUp] = React.useState(false);
  const [openChatPopUp, setopenChatPopUp] = React.useState(false);
  const [allPlans, setAllPlansData] = React.useState([]);
  const emptyObj= {
    user_id: "",
    sub_plan_id: "",
    message: "",
  }
  const [planFormData, setPlanFormData] = React.useState(emptyObj);

   const handleClickopenPlanPopUp = (rowData) => {
    console.log(rowData);
    setopenPlanPopUp(true);
    setPopUpMessage("Loading.......");
    setPlanFormData({ ...planFormData, user_id: rowData.id })
    axios.get(getDomain()+"/plan-list", { params: { type: '-2' }, headers: header() })
    .then(function(response){ 
      setPopUpMessage("");     
      setAllPlansData(response.data);
    })
    .catch(function(error){
      console.log(error);
      setPlanFormData(emptyObj)
      showMessage("Something is went wrong, please try after sometime","error");
    });

    };
        
  const handleCloseChatPopUp= () => {
	   setopenChatPopUp(false);
  };
  const handleClosePlanPopUp= () => {
    setopenPlanPopUp(false);
    setPlanFormData(emptyObj);
    };
    const eventHandler = (e) => {
      setPlanFormData({ ...planFormData, sub_plan_id: e.target.value });
     };
	const eventMessageHandler = (e) => {
      setPlanFormData({ ...planFormData, message: e.target.value });
     }; 
    const addPlanSubmit = () => {
      if(planFormData.sub_plan_id!="")
      {
        setPopUpMessage("Sending data,Please wait... ");
        axios.post(getDomain()+'/assing-plan',planFormData,{
          headers : header()
          
        }).then(function(response){
          console.log(response);
          handleClosePlanPopUp();
           window.location.reload();
          showMessage("Plan added Successfully","success");
        })
        .catch(function (error) {
          console.log(error);
          if(error.response.status===401)
          {
            logout();
            //window.location.reload();
          }
          window.location.reload();
          showMessage("Something is went wrong, please try after sometime","error");
        });
      }
      else{
        showMessage("Please select a plan","error");
      }
    };
	const addChatSubmit = (e) => {
		e.preventDefault();
		//console.log(e.target.message.value,planFormData.user_id);
      if(planFormData.user_id!="")
      {
        setPopUpMessage("Sending data,Please wait... ");
		axios.post(getDomain()+"/notification/store",{ to_user_id:planFormData.user_id,message: e.target.message.value},{
		  headers : header()
		}).then(function(response){
          console.log(response);
          handleCloseChatPopUp();
           window.location.reload();
          showMessage("Message sent Successfully","success");
        })
        .catch(function (error) {
          console.log(error);
          if(error.response.status===401)
          {
            logout();
			window.location.reload();
          }          
          showMessage("Something is went wrong, please try after sometime","error");
        });
      }
      else{
        showMessage("Please put message","error");
      }
    }
  function showMessage(newMessage,newSeverity)
  {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [modelInfos, setModelInfos] = React.useState({ title: "", description: "" });
  const disagree = () =>{
    setConfirmOpen(false);
  }
  const agree = () =>{
    setConfirmOpen(false);
    deleteRow(deleteObj);
  }
  
  const openModel = (rowData) =>{
    if(rowData.id!=user_id())
    {
      setDeleteObj(rowData)
      setModelInfos({
        title: "Delete User",
        description: "Do you really want to delete this User ?"
      })
      setConfirmOpen(true);
    }
    else
    {
      showMessage("You can't delete your own account","error");
    }
  };
    const openChatModel = (rowData) =>{
    if(rowData.id!=user_id())
    {
		setPlanFormData({ ...planFormData, user_id: rowData.id })
		setopenChatPopUp(true);
		setPopUpMessage("Loading.......");
		//setPlanFormData({ ...planFormData, user_id: rowData.id })
    }
    else
    {
      showMessage("You can't send message to your own account","error");
    }
  }
  //----------------------------------------
  function deleteRow(rowData){
    axios.delete(getDomain()+'/user/destroy/'+rowData.id,{
      headers : header()
    })
    .then(function(response){
      console.log(response);
      setDeleteObj({});
      set_info("Delete user successfully","success");
      window.location.reload();
    })
    .catch(function(error){
      console.log(error);
      set_info("Something is went wrong, please try after sometime","error");
    });
  }
  useEffect(()=>{
    if(user_message())
    {
      setSeverity(user_severity());
      setMessage(user_message());
      setOpen(true);
    }
    delete_info();
  },[])

  return (
    <div className={classes.root}>
      <CssBaseline />

      <RDrawer />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CustomAlert open={ open } severity={ severity } message={ message } handleClose={ handleClose } />
        <ConfirmAlert open={ confirmOpen } handleClose={ disagree } agree={ agree } disagree={ disagree }
        title={ modelInfos.title } description={ modelInfos.description } />

        <Container maxWidth="100%" className={classes.container}>
          <Box className={classes.title1}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.btntext}
              onClick={ ()=> formatProxies(0) }
            >
              Reset # Proxy Limit-All
            </Button>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.tbllayout}>
                <MaterialTable
                  icons={tableIcons}
                  columns={ columns }
                  data={query =>
                        new Promise((resolve, reject) => {
                            // prepare your data and then call resolve like this:
                            let url= getDomain()+"/user?";
                            url += 'per_page=' + query.pageSize;
                            url += '&page=' + (query.page+1);
                            url += '&search=' + query.search;
                            if(query.orderBy!=undefined){
                              url += '&order_by=' + query.orderBy.field;
                              url += '&order_direc=' + query.orderDirection;
                            }
                            console.log(query);
                            axios.get(url, { headers : header()})
                            .then(function(response){
                             console.log(response.data.data);
                              resolve({
                                data: response.data.data,// your data array
                                page: response.data.page-1,// current page number
                                totalCount: response.data.totalCount// total row number
                              });
                            })
                            .catch(function(error){
                              console.log(error);
                              if(error.response.status===401)
                              {
                                logout();
                                window.location.reload();
                              }
                              showMessage("Something is went wrong, please try after sometime","error");
                            });
                            
                        })
                    }
                  options={{
                    showTitle: false,
                    actionsColumnIndex: -1,
                    searchFieldVariant: "outlined",
                    outline: "none",
                    searchFieldStyle: {
                      height: 38,
                      paddingRight:"0px",
                    },
                    pageSizeOptions: [10,20,50, 100, 250],
                    pageSize:20,
                    headerStyle: {
                      backgroundColor: "#151948",
                      color: "#FFF",
                      alignContent: "left",
                      textAlign: "left",
                    },

                    rowStyle: {
                      backgroundColor: "#e9e9e9",
                      color: "#797fb3",
                      alignContent: "left",
                      textAlign: "left",
                    },

                    cellStyle: {
                      alignContent: "left",
                      textAlign: "left",
                    },
                    exportButton: isAdmin()?true:false,
                  }}
                  style={{
                    boxShadow: "none",
                  }}
                  actions={actionList}
                 
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        <Dialog
        open={openPlanPopUp}
        onClose={handleClosePlanPopUp}
        className={classes.dialogpaper}
       
      >
        <DialogTitle className={classes.mydialog}>Add Plan</DialogTitle>
        <DialogContent className={classes.dialogcontnet}>
        { allPlans.length>0 && PopUpMessage==""?
          <DialogContentText >
               <Box marginTop={2} justifyContent="space-between" display="flex">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography variant="h2" marginBottom={5}>Plan</Typography>
                    
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="add-plan"                     
                      disableUnderline
                      onChange={ eventHandler }
                      value={planFormData.sub_plan_id}
                      className={classes.input1}
                    >
                      <option key="0" value="0">
                      Select Plan
                      </option>
                      {allPlans.map(plan => {
                      return ( 
                      <optgroup key={plan.id} label={plan.title}>
                       {plan.options.map(opt => {
                      return (
                      <option key={opt.id} value={opt.id}>
                      ${opt.plan_price}-{(opt.plan_data) == 0 ? "UNLIMITED" : opt.plan_data + " GB"}  /  {opt.no_proxy} IPS |  {opt.validity} Days
                      </option>
                      )
                      })}
                      </optgroup>
                      )
                      })}
                    </NativeSelect>
                    
                  </Grid>
                 
                  </Grid>
              </Box>
            <Box marginTop={2} justifyContent="space-between" display="flex">
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btntext}
                size="small"
                onClick={ addPlanSubmit }
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btntext}
                size="small"
                onClick={handleClosePlanPopUp}
              >
                Cancel
              </Button>
            </Box>
            </Box>
        </DialogContentText>
            :<Typography variant="h2" style={ {marginTop:70} }>{PopUpMessage}</Typography> }
        </DialogContent>
        <DialogActions className={classes.dialogaction}>
        
        </DialogActions>
      </Dialog>  
      <Dialog
        open={openChatPopUp}
        onClose={handleCloseChatPopUp}
        className={classes.dialogpaper}
       
      >
        <DialogTitle className={classes.mydialog}>Send Message</DialogTitle>
        <DialogContent className={classes.dialogcontnet}>
          <DialogContentText >
		      <form onSubmit={ addChatSubmit }>
               <Box marginTop={2} justifyContent="space-between" display="flex">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <Typography variant="h2" marginBottom={5}>Message</Typography>
					<textarea name="message"
					//onChange={ eventMessageHandler }
					//value={planFormData.message}
					rows="2" cols="80"
					/>     
                                      
                  </Grid>
                 
                  </Grid>
              </Box>
            <Box marginTop={2} justifyContent="space-between" display="flex">
            <Box>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btntext}
                size="small"
             
				type="submit"
              >
                Send
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btntext}
                size="small"
                onClick={handleCloseChatPopUp}
              >
                Cancel
              </Button>
            </Box>
			
            </Box>
			</form>
        </DialogContentText>
            
        </DialogContent>
        <DialogActions className={classes.dialogaction}>
        
        </DialogActions>
      </Dialog>   	  
      </main>
    </div>
  );
}
