import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
//import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import RDrawer from "../components/Top";
import NativeSelect from "@material-ui/core/NativeSelect";

import { Editor } from '@tinymce/tinymce-react';
import { header, getDomain, logout } from "../../auth";
import { CustomAlert, editor_apiKey } from "../../helper/helper";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },
  inputbtn: {
    border: "1px solid #1870ff",
    height: "34px",
    padding: "9px",
    borderRadius: "4px",
    fontSize: "14px",
    color: "#1870ff",
    marginTop: "0",
    marginLeft: "0",
    width: 80,
    [theme.breakpoints.down(400)]: {
      width: 70,
      padding: "9px 6px",
   },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
    minHeight: "100vh",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },
  title1: { justifyContent: "space-between", display: "flex" },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    margin: "0 10px 0 0",
    textTransform: "none",
    letterSpacing: "0.1px",
    padding:"7px 10px", 
    boxShadow:"none",    
    height: "34px",
    [theme.breakpoints.down(400)]: {
      margin: "0 8px 0 0",
      padding:"7px 8px", 
   },

  },

  paper: {
    background: "#e9e9e9",
    paddingBottom: "30px",
    borderRadius: 10,
    padding: "20px 20px 20px 20px",
    marginTop: 10,
    marginBottom: 20,
    boxShadow: "none",
   "& .MuiTypography-h6":{
    fontWeight:"400",
    letterSpacing:"0.1px",
    lineHeight:"24px"
    }
  },
  input: {
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    paddingLeft: 5,
  },
  input1: {
    maxWidth: 300,
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    display: "flex",
    paddingLeft: 5,
  },
}));
const MyNativeSelect = withStyles({
  icon: {
    color: "#1870ff",
  },
  select: {
    "&.MuiNativeSelect-select": {
      paddingRight: "15px",
    },
  },
})(NativeSelect);
export default function Termsofuse() {
  const classes = useStyles();
  const history = useHistory();
  //-------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  function showMessage(newMessage,newSeverity)
  {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpen(true);
  }
  //----------------------------------------
  let [formData, setFormData] = useState({});
  const textHandler =(e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
    setFormData({ ...formData, description: content })
  }
  useEffect(()=> {
    axios.get(getDomain()+'/get-page/3',{
      headers : header()
    })
      .then(function(response){
        setFormData(response.data);
      })
      .catch(function(error){
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
      });
  },[])
  const saveData = () =>{
    axios.put(getDomain()+'/save-page/3',formData,{
      headers : header()
    })
      .then(function(response){
        console.log(response);
        showMessage("You have updated a Terms of Use successfully","success");
      })
      .catch(function(error){
        console.log(error);
        showMessage("Something is went wrong, please try after sometime","error");
      });
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CustomAlert open={ open } severity={ severity } message={ message } handleClose={ handleClose } />

        <Container maxWidth="100%" className={classes.container}>
          <Paper className={classes.paper}>
          <Editor
            initialValue=""
            apiKey={editor_apiKey()}
            value={ formData.description }
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | code | help'
            }}
            onEditorChange={handleEditorChange}
          />
          </Paper>
          <MyNativeSelect
            name="status"
            value={formData.status}
            disableUnderline
            onChange={textHandler}
            className={classes.inputbtn}
          >
            <option aria-label="" value="None" disabled>
              None
            </option>
            <option value="Active">Active</option>
            <option value="InActive">Deactive</option>
          </MyNativeSelect>
           
            <Box marginTop={2} display="flex">
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  size="small"
                  onClick={saveData}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  size="small"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
        </Container>
      </main>
    </div>
  );
}
