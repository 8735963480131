import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core//Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import NativeSelect from "@material-ui/core/NativeSelect";
import RDrawer from "../components/Top";
import { useLocation } from "react-router-dom";
import { header, getDomain, logout } from "../../auth";
import { set_info, editor_apiKey } from "../../helper/helper";
import { Editor } from '@tinymce/tinymce-react';

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
  },

  fixedHeight: {
    height: 240,
  },
  title1: { justifyContent: "space-between", display: "flex" },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    marginRight: 10,
    textTransform: "none",
    letterSpacing: 1,
  },
  paper: {
    background: "#e9e9e9",

    borderRadius: 15,
    padding: "20px 20px 20px 20px",
    marginTop: 10,
    boxShadow: "none",
  },
  input: {
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    padding: "5px",
    paddingLeft: 10,
  },
  input1: {
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    color: "#797fb3",
    fontSize: 14,
    fontWeight: "500",
    padding: "5px",
    display: "flex",
    paddingLeft: 10,
  },
  table: {
    minWidth: 450,
  },
  tblstyle: 
  { borderTopLeftRadius: 20, 
    borderTopRightRadius: 20,
    [theme.breakpoints.down(1200)]: {
      borderTopLeftRadius: 10, 
      borderTopRightRadius: 10,
    },
    [theme.breakpoints.down(599)]: {
      borderTopLeftRadius: 8, 
      borderTopRightRadius: 8,
    }    
},
  tblhead: {
    background: "#151948",
  },
  txtcolor: {
    color: "#e9e9e9",
    padding: "15px 15px",
    [theme.breakpoints.down(599)]: {
      padding: "10px 15px",
    } 
  },

  tblcellcolor: {
    color: "#797fb3",
    padding: "15px 15px",
    [theme.breakpoints.down(599)]: {
      padding: "13px 15px",
    } 
  },
  tblinput: {
    backgroundColor: "#e9e9e9",
    border: "1px solid #d2d4d8",
    width: 130,
    color: "#797fb3",
    height: "30px",
    padding:"0 5px"
  },
}));

export default function Addpackage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  let [formData, setFormData] = useState(location.state.params);
  //let [planIds, setPlanIds] = useState([]);
  console.log(location.state.params)
  const eventHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const handleEditorChange = (content, editor) => {
    setFormData({ ...formData, body: content })
  }
  const optionHandler =(e,key) =>{
    console.log(formData)
    let data= formData.options;
    data[key][e.target.name]=e.target.value;
    setFormData({ ...formData, options: data })
  }
  function formSubmit(){
    //formData.parent_id = (formData.parent_id ? formData.parent_id : null);
    //console.log("==> ", formData);
    if(formData.id==="")
    {
      axios.post(getDomain()+"/plan/store",formData,{
        headers : header()
      })
      .then(function(response){
        console.log(response);
        set_info("You have created a plan successfully","success");
        history.goBack();
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status===401)
        {
          logout();
          window.location.reload();
        }
        set_info("Something is went wrong, please try after sometime","error");
        history.goBack();
      });
    }
    else{
      axios.put(getDomain()+"/plan/update/"+formData.id,formData,{
        headers : header()
      })
      .then(function(response){
        console.log(response);
        set_info("You have update a plan successfully","success");
        history.goBack();
      })
      .catch(function (error) {
        console.log(error);
        set_info("Something is went wrong, please try after sometime","error");
        history.goBack();
      });
    }
    
  }
  const addMore = () =>{
    const obj = {
      id : "",
      plan_id : formData.id,
      plan_data : "",
      plan_price : "",
      validity : "",
      no_proxy : "",
      created_at : "",
      updated_at : "",
    }
    let objArr = formData.options;
    objArr.push(obj);
    setFormData({...formData, options: objArr});
  }
  const deleteOption = (index) => {
    let objArr = formData.options;
    delete objArr[index];
    setFormData({...formData, options: objArr});
  }
/*
  useEffect(()=> {
    axios.get(getDomain()+"/plan-ids?plan_id=" + formData.id, {
      headers : header()
    })
    .then(function(response){
      console.log(response.data);
      setPlanIds(response.data);
      planIds.map((elem) => {
        console.log(elem.id, " ==> ", elem.title);
      });
    })
    .catch(function(error){
      console.log(error);
    })
  },[]);
*/
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="100%" className={classes.container}>
          <Paper className={classes.paper}>
            <Box maxWidth={800}>
              <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Plan Name</Typography>
                    <Input
                      defaultValue=""
                      name="title"
                      value={formData.title}
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      onChange={ eventHandler }
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Availability</Typography>
                    <Input
                      defaultValue=""
                      name="limit_proxy_data"
                      value={formData.limit_proxy_data}
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      onChange={ eventHandler }
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Product</Typography>
                    <NativeSelect
                      id="demo-customized-select-native"
                      name="type"
                      value={formData.type}
                      disableUnderline
                      onChange={eventHandler}
                      className={classes.input1}
                    >
                      <option value="1">
                        Residential Proxies
                      </option>
                    </NativeSelect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h2">Order Display #</Typography>
                    <Input
                      defaultValue=""
                      name="order_num"
                      value={formData.order_num}
                      fullWidth
                      disableUnderline
                      className={classes.input}
                      onChange={ eventHandler }
                      inputProps={{ "aria-label": "description" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Typography variant="h2">Status</Typography>
                        <NativeSelect
                          name="status"
                          value={ formData.status }
                          onChange={eventHandler}
                          variant="filled"
                          className={classes.input1}
                          disableUnderline
                        >
                         <option value="Active">Active</option>
                         <option value="InActive">InActive</option>
                        </NativeSelect>
                  </Grid>
				{/*
                  <Grid item xs={12} md={6}>
                  <Typography variant="h2">Renewal Plan</Typography>
                        <NativeSelect
                          name="parent_id"
                          value={ formData.parent_id }
                          onChange={eventHandler}
                          variant="filled"
                          className={classes.input1}
                          disableUnderline
                        >
                         <option value="">Choose Renewal Plan</option>
                         {
                          planIds.map((elem) => {
                            return <option value={elem.id}>{elem.title}</option>;
                          })
                         }
                        </NativeSelect>
                  </Grid>
				*/}
                  
                  <Grid item xs={12} md={12}>
                    <Typography variant="h2">Description</Typography>
                    <Editor
                    apiKey={editor_apiKey()}
                    initialValue=""
                    value={ formData.body }
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | code | help'
                    }}
                    onEditorChange={handleEditorChange}
                  />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.titleplan} color="secondary">
                      Plan Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={ addMore }
                      size="small"
                    >
                      Add More
                    </Button>
                  </Grid>
                </Grid>
                
                <TableContainer component={Paper} className={classes.tblstyle}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead className={classes.tblhead}>
                      <TableRow>
                        <TableCell className={classes.txtcolor}>
                          BW (GB)
                        </TableCell>
                        <TableCell align="" className={classes.txtcolor}>
                          Proxy Qty
                        </TableCell>
                        <TableCell align="" className={classes.txtcolor}>
                          Duration (Days)
                        </TableCell>
                        <TableCell align="" className={classes.txtcolor}>
                          $ Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
              { formData.options.map((element, key)=> {
              return <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tblcellcolor}
                        >
                          <input
                            type="number"
                            name="plan_data"
                            value={element.plan_data}
                            onChange={ (e)=> optionHandler(e,key) }
                            placeholder="Type here.."
                            className={classes.tblinput}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <input
                            type="number"
                            name="no_proxy"
                            value={element.no_proxy}
                            onChange={ (e)=> optionHandler(e,key) }
                            placeholder="Type here.."
                            className={classes.tblinput}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <input
                            type="number"
                            name="validity"
                            value={element.validity}
                            onChange={ (e)=> optionHandler(e,key) }
                            placeholder="Type here.."
                            className={classes.tblinput}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <input
                            type="number"
                            name="plan_price"
                            value={element.plan_price}
                            onChange={ (e)=> optionHandler(e,key) }
                            placeholder="Type here.."
                            className={classes.tblinput}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tblcellcolor}
                        >
                          <DeleteIcon
                            size="small"
                            className={classes.iconcolor}
                           onClick={() => {
                            deleteOption(key)
                           }}
                          />
                          
                        </TableCell>
                      </TableRow>
                  })}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
              </form>
            </Box>
          </Paper>
          <Box marginTop={5}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={ formSubmit }
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => history.goBack()}
              size="small"
            >
              Cancel
            </Button>
          </Box>
        </Container>
      </main>
    </div>
  );
}
