import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import RDrawer from "../components/Top";
import { Bar } from "react-chartjs-2";
import {
  CircularProgressbar,
  
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { header, getDomain, user_id } from "../../auth";

const axios = require('axios')
const percentage = 30;


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  logo: {
    width: 35,
    height: "auto",
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "auto",
    overflow: "auto",
    backgroundColor: "#FFF",
  },
  container: {
    paddingTop: 19,
    paddingBottom: theme.spacing(4),
    maxWidth:"100%"
  },

  fixedHeight: {
    height: 240,
  },
  title1: {
    justifyContent: "flex-end", display: "flex", marginRight: "0", marginBottom: "20px",
     [theme.breakpoints.down(450)]: {
       display: "block",
       justifyContent: "flex-start"
    },
  },
  title2: {
    paddingBottom: 20,
    textAlign: "left",
  },
  titleplan: {
    fontSize: 17,
    fontWeight: "600",
    marginTop: 20,
    marginBottom: 10,
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
    letterSpacing: "0.1px",
  },

  input: {
    background: "#e9e9e9",
    borderRadius: 5,
    borderBottom: "none",
    padding: "3px 8px",
    color: "#797fb3",
    border: "1px solid #e9e9e9",
    fontSize:"13px",
    width:"148px",
    [theme.breakpoints.down(599)]: {
      width:"150px",
    },
    [theme.breakpoints.down(390)]: {
      width:"100%",
      padding: "5px 10px",
    }, 

  },
  input1: {
    maxWidth: 300,
    background: "#FFF",
    borderRadius: 5,
    borderBottom: "none",
    display: "flex",
    paddingLeft: 5,
  },
  image1: {
    height: "450px",
    maxWidth: "100%",
  },
  imagesm: {
    height: "450px",
    maxWidth: "100%",
  },
  reportbox: {
    border: "1px solid #ccc",
    height: "auto",
    borderRadius:"10px",
    [theme.breakpoints.down(500)]: {
      heigth:"500px"
    },
  },
  boxrevenue: {
    backgroundColor: "#f4f3f8",
    borderRadius: "10px",
    display: "flex",
    padding: "30px 14px",
    height: "133px",
    justifyContent:"space-between",
      [theme.breakpoints.down(1280)]: {
      padding:"30px 15px 30px 30px"
    },
    [theme.breakpoints.down(625)]: {
      padding:"30px 14px"
    },

    [theme.breakpoints.down(599)]: {
      marginBottom:"0px",
      height: "auto",
      padding: "20px 20px 20px"
    },


    marginBottom:"20px",
    "& p": {
      fontSize: "13px",
      marginRight: "15px",
      marginTop: "27px",
      letterSpacing: "0.1px",
      color:"#9596ac"
    }
    
    
  },
  boxrevenue1: {
    backgroundColor: "#f4f3f8",
    borderRadius: "10px",
    padding: "30px 14px",
    height: "133px",
    justifyContent:"space-between",
    [theme.breakpoints.down(1280)]: {
      padding:"30px 40px"
    },
    [theme.breakpoints.down(625)]: {
      padding:"30px 14px"
    },
    [theme.breakpoints.down(599)]: {
      height: "auto",
      padding: "20px 20px 10px",
    }, 
    "& h2": {
      fontSize: "14px",
      marginBottom: "18px",
      color:"#9596ac",
      letterSpacing: "0.1px",
        fontWeight: "400",
    },
    "& h3": {
        fontSize: "14px",
     
      color:"#9596ac",
      letterSpacing: "0.1px",
        fontWeight: "400",
    }
      
    
  },
  infobox: {
    backgroundColor: "#f4f3f8",
    height: "133px",
    padding: "30px 20px",
    textAlign: "center",
    borderRadius: "10px",
    [theme.breakpoints.down(599)]: {
      height: "auto",
      padding: "20px 20px 10px",
    }, 
    "& h2": {
      fontSize: "15px",
      color:"#9596ac",
      letterSpacing: "0.1px",
    },
     "& h3": {
      fontSize: "15px",
       fontWeight: "600",
       color: "#2075fe",
       marginTop:"10px"
    }
  },
  pricecontent: {
    color: "#2075fe",
    fontSize: "14px",
    marginTop: "15px",
    marginLeft: "43px",
     fontWeight: "600",
  },
    pricecontent1: {
      color: "#2075fe",
      fontSize: "14px",
      marginBottom: "15px",
      marginLeft: "20px",
     fontWeight: "600",
    marginTop: "-2px"
 },
  progreess: {
    width: "26%",
    marginLeft: "10px",
    color:"#2075fe"
  },
  paperclass: {
    padding: "15px",
    boxShadow:"none",
    borderRadius:"10px",
  },
  monthbox: {
    display:"flex" ,borderBottom:"1px solid #ccc" ,justifyContent:"space-between"
  },
  lastmonthbox: {
    display:"flex" ,justifyContent:"space-between"
  },
  spacingDiv:{
    [theme.breakpoints.down(599)]: {
      marginTop:"15px"
  }, 
  },
  toDivider:{
    margin: "10px", 
    fontSize:"14px",
    display:"inline-block",
    [theme.breakpoints.down(390)]: {
      display:"block",
      textAlign:"center",
    },       
  }

}));
const dataa = [
  {
    label: "Jan 20",
    dataval:200000,
    datausage: 90,
   
  },
  {
    label: "Feb 20",
    dataval: 40000,
    datausage: 70,
    
  },
  {
    label: "March 20",
    dataval: 8000,
    datausage: 170,
    
  },
  {
    label: "April 20",
    dataval: 50000,
    datausage: 150,
    
  },
   {
    label: "May 20",
    dataval: 250000,
    datausage: 150,
   
  },
   
];
export default function Termsofuse() {
  const classes = useStyles();
  const Labels = dataa.map((data) => data.label);
  const datavals = dataa.map((vales) => vales.dataval);
 
  const BarChartData = {
    labels:Labels,
    datasets: [
      {
        label:"Revenue",
        backgroundColor: "#d6d5db",
        data: datavals 
    
      },
    ],
  };
  const emptyObj={
    last_month_payments: [],
    current_month_payments: [],
  }
  let [infos, setInfos] = React.useState(emptyObj);
  
  useEffect(()=>{
    axios.get(getDomain()+"/report",{ headers : header()})
    .then(function(response){
      setInfos(response.data);
      console.log(response.data);
      })
    .catch(function(error){
      console.log(error);
    });
  },[])
  const BarChartRevenue = {
    labels:infos.month_name,
    datasets: [
      {
        label:"Revenue",
        backgroundColor: "#d6d5db",
        data: infos.revenue 
    
      },
    ],
  };
  const DataBarChartData = {
    labels:infos.month_name,
    datasets: [
      {
        label:"Data",
        backgroundColor: "#d6d5db",
        data: infos.data_used 
    
      },
    ],
  };
  const userData = {
    labels:infos.month_name,
    datasets: [
      {
        label:"Customers",
        backgroundColor: "#d6d5db",
        data: infos.user_list 
    
      },
    ],
  };
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <RDrawer />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container  className={classes.container}>
          
            
         
           {/* <Box className={classes.title1}>
              <Input
                className={classes.input}
                id="date"
                disableUnderline
                label="Date"
                type="date"
                defaultValue=""
              />

              <Typography variant="h2" className={classes.toDivider}>To</Typography>
              <Input
                className={classes.input}
                id="date"
                disableUnderline
                label="Date"
                type="date"
                defaultValue=""
              />
  </Box>*/}
        
          <Grid container spacing={3}>
             <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className={classes.reportbox}>
                <Paper className={classes.paperclass}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className={classes.boxrevenue}>
                   <Typography>Revenue Growth</Typography>
                   {/* <CircularProgressbar value={percentage} text={`${percentage}%`} className={classes.progreess}/>*/}
                      </Box>
                      </Grid>
                       <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.boxrevenue1}>
                        <Box className={classes.monthbox}>
                          <Typography variant="h2">Current Month</Typography>
                           <Typography className={classes.pricecontent1}>${infos.current_month_payments}</Typography>
                          </Box>
                         <Box className={classes.lastmonthbox}>
                          <Typography variant="h3">Last Month</Typography>
                           <Typography className={classes.pricecontent}>${infos.last_month_payments}</Typography>
                          </Box>
                      </Box>
                      </Grid>
                   </Grid>
                   <Box className={classes.spacingDiv}>
                    
                  </Box>
                   <Bar
                  options={{
                    responsive: true,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                              
                            drawOnChartArea: false,
                        },
                          ticks: {                           
                            fontSize: 11,
                            fontColor: "#000",
                          },
                        },
                      ],
                      yAxes: [

                        {
                            gridLines: {
                            drawOnChartArea: false,
                               
                             
           
          },
                          ticks: {
                          
                             callback: function(value, index, values) {
                            return '$' + value;
                            },
                            beginAtZero: true,
                            fontSize: 11,
                            fontColor: "#000",
                            

                          },
                        },
                      ],
                    },
                  }}
                  data={BarChartRevenue}
                />
            
                </Paper>
              </Box>
            </Grid>
             <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className={classes.reportbox}>
                <Paper className={classes.paperclass}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className={classes.boxrevenue}>
                   <Typography>Customers Growth</Typography>
                   {/* <CircularProgressbar value={percentage} text={`${percentage}%`} className={classes.progreess}/> */}
                      </Box>
                      </Grid>
                       <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.boxrevenue1}>
                        <Box className={classes.monthbox}>
                          <Typography variant="h2">Current Month</Typography>
                           <Typography className={classes.pricecontent1}>{infos.current_month_user}</Typography>
                          </Box>
                         <Box className={classes.lastmonthbox}>
                          <Typography variant="h3">Last Month</Typography>
                           <Typography className={classes.pricecontent}>{infos.last_month_users}</Typography>
                          </Box>
                      </Box>
                      </Grid>
                   </Grid>
                   <Box className={classes.spacingDiv}>
                    
                  </Box>
                   <Bar
                  options={{
                   
                    scales: {
                      xAxes: [
                        {
                           gridLines: {
                            drawOnChartArea: false,
                             
           
          },
                          ticks: {
                            fontSize: 11,
                            fontColor: "#000",
                          },
                        },
                      ],
                      yAxes: [

                        {
                            gridLines: {
                            drawOnChartArea: false,
                             
           
          },
                          ticks: {
                             callback: function(value, index, values) {
                            return value;
                            },
                            beginAtZero: true,
                            fontSize: 11,
                            fontColor: "#000",

                          },
                        },
                      ],
                    },
                  }}
                  data={userData}
                />
            
                </Paper>
              </Box>
            </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className={classes.reportbox}>
                <Paper className={classes.paperclass}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box className={classes.boxrevenue}>
                   <Typography>Usage Growth</Typography>
                    {/* <CircularProgressbar value={percentage} text={`${percentage}%`} className={classes.progreess}/> */}
                      </Box>
                      </Grid>
                       <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.boxrevenue1}>
                        <Box className={classes.monthbox}>
                          <Typography variant="h2">Current Month</Typography>
                           <Typography className={classes.pricecontent1}>{infos.current_month_gb_data} GB</Typography>
                          </Box>
                         <Box className={classes.lastmonthbox}>
                          <Typography variant="h3">Last Month</Typography>
                           <Typography className={classes.pricecontent}>{infos.last_month_gb_data} GB</Typography>
                          </Box>
                      </Box>
                      </Grid>
                   </Grid>
                   <Box className={classes.spacingDiv}>
                    
                  </Box>
                   <Bar
                  options={{
                   
                    scales: {
                      xAxes: [
                        {
                           gridLines: {
                            drawOnChartArea: false,
                             
           
          },
                          ticks: {
                            fontSize: 11,
                            fontColor: "#000",
                          },
                        },
                      ],
                      yAxes: [

                        {
                            gridLines: {
                            drawOnChartArea: false,
                             
                        },
                           ticks: {
                             callback: function(value, index, values) {
                            return value+ ' GB';
                            },
                            beginAtZero: true,
                            fontSize: 11,
                            fontColor: "#000",

                          },
                        },
                      ],
                    },
                  }}
                  data={DataBarChartData}
                />
            
                </Paper>
              </Box>
            </Grid>
             {/*<Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className={classes.reportbox}>
                <Paper className={classes.paperclass}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={12} md={6} sm={12}>
                    <Box className={classes.infobox}>
                        <Typography variant="h2">Average Customer Payment</Typography>
                         <Typography variant="h3">$77.35</Typography>
                      </Box>
                    </Grid>
                     <Grid item lg={6} xs={12} md={6} sm={12}>
                    <Box className={classes.infobox}>
                        <Typography variant="h2">Average Customer Lifetime</Typography>
                         <Typography variant="h3">225 Days</Typography>
                      </Box>
                    </Grid>
                     <Grid item lg={6} xs={12} md={6} sm={12}>
                    <Box className={classes.infobox}>
                        <Typography variant="h2">Average Customer Payment</Typography>
                         <Typography variant="h3">$77.35</Typography>
                      </Box>
                    </Grid>
                     <Grid item lg={6} xs={12} md={6} sm={12}>
                    <Box className={classes.infobox}>
                        <Typography variant="h2">Average Customer Monthly Usage</Typography>
                         <Typography variant="h3">2.35GB</Typography>
                      </Box>
                      </Grid>
                   
                    </Grid>
                 </Paper>
              </Box>
                </Grid>*/}
          </Grid>
         
        </Container>
      </main>
    </div>
  );
}

