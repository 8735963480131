export const isAuthenticated = () => {
  const infos = localStorage.getItem("api_token");
  if (infos && infos != "") {
    return true;
  }
  return false;
}
export const logout = () => {
  localStorage.removeItem("api_token");
  localStorage.removeItem("admin");
  localStorage.removeItem("user_id");
  localStorage.removeItem("display_name");
  localStorage.removeItem("unlimited_user");
}
export const isAdmin = () => {
  const infos = localStorage.getItem("api_token");
  const admin = localStorage.getItem("admin");
  if (infos && admin && admin == 1) {
    return true;
  }
  return false;
}
export const isSupport = () => {
  const infos = localStorage.getItem("api_token");
  const admin = localStorage.getItem("admin");
  if (infos && admin && (admin == 1 || admin == 3)) {
    return true;
  }
  return false;
}
export const isUnlimitedUser = () => {
  const infos = localStorage.getItem("api_token");
  const unlimited = localStorage.getItem("unlimited_user");
  if (infos && unlimited && unlimited == 4) {
    return true;
  }
  return false;
}
export const header = () => {
  const infos = localStorage.getItem("api_token");
  const headerInfo = {
    Authorization: infos
  }
  return headerInfo;
}
export const getDomain = () => {
  // return "http://lliveproxies.com";
  return "https://liveapi.proxydashboards.com";
}
export const display_name = () => {
  const temp = localStorage.getItem("display_name");
  //console.log("user_name= ",temp);
  return temp;
}
export const user_id = () => {
  const temp = localStorage.getItem("user_id");
  return temp;
}
export const avatar = () => {
  let temp = localStorage.getItem("avatar");
  return temp;
}
export const passwordExpire = () => {
  let hours = localStorage.getItem('pricing_password_time');
  let pricing_password = localStorage.getItem('pricing_password');
  console.log(hours, pricing_password);
  if (pricing_password && hours && (new Date() >>> hours)) {
    localStorage.removeItem('pricing_password');
    localStorage.removeItem('pricing_password_time');
  }
}
