import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Top from "../components/Top";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Box";
import Footer1 from "../components/Footer1";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NativeSelect from '@material-ui/core/NativeSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Input } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import image1 from '../../assets/images/innerbanner.jpg';
import { Paper } from "@material-ui/core";
import dividerimage from '../../assets/images/divider.png';
import { colors } from '../components/colors';
import Windows from '../../assets/images/windows.svg';
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
 
   content: {
    flexGrow: 1,
      height: "auto",
    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    },
    container1: {
      backgroundColor: colors.darkBlue,
      padding:"20px"  
    },
    subhead1: {    
      marginTop: 3,
      marginBottom: 10,
      letterSpacing:0.6,
      fontSize: "18px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: colors.accent,
      [theme.breakpoints.down(599)]: {
        fontSize:"14px",
        marginTop:"-3px"
     },
  },
    subhead2: {
      fontSize: "18px",
        fontWeight: "500",
        fontFamily: "Poppins",
         color: colors.accent,
    letterSpacing:0.6,
    marginTop: 20,
    marginBottom: 10,
    },
      img: { width: "auto",
    margin: "0px", right: 0 },

    paragraph: {
      color:colors.lightpurple,
      fontSize: "16px",
      fontWight: "400",
      marginTop: "5px",
      marginBottom: "12px",
      letterSpacing: 0.6,
      lineHeight: "24px",
      fontFamily: "Poppins",
      [theme.breakpoints.down(599)]: {
        fontSize:"14px",
        marginTop:"-1px"
     },

    },
    paper: {
     paddingTop: "15px",
      paddingBottom:"30px",
        boxShadow: "none",
         backgroundColor: colors.darkBlue,
       },
  listItemText: {
         fontWeight: "500",
         fontSize: 16,
    
        lineHeight: "22px",
       fontFamily: "Poppins",
     letterSpacing:0.6,
    color:colors.lightpurple,
    [theme.breakpoints.down(599)]: {
      fontSize:"14px",
   },
  },
  imageSrc: {
    height:"auto",
    width:"auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
     paddingBottom:"15px"
  },
  myButton: {
   cursor:"pointer",
   color: "#fff",
   fontSize: 14,
   fontWeight: "500px",
   textDecoration: "none",
   borderRadius: "4px",
   letterSpacing: 0.6,
   fontFamily: "Poppins",
   display: "inline-flex",
   height:"40px",
   padding: "9px 20px",
   background: "rgb(23,111,254)",
   background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
   background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
   background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
   filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )"   
 },
 myButtonImg: {
    marginLeft: "10px",
 },

 myButtonFull: {
    [theme.breakpoints.down("479")]: {
        width: '100%',
        textAlign:"center",
        display:'block ',
    },
    color: "#fff",
    fontSize: 14,
    fontWeight: "500px",
    textDecoration: "none",
    borderRadius: "4px",
    letterSpacing: 0.6,
    fontFamily: "Poppins",
    display: "inline-flex",
    height:"40px",
    padding: "9px 20px",
     background: "rgb(23,111,254)",
     background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
     background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
     filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )"   
  },

 testerTop:{
     color:"#fff",
     textAlign:"center",
 },
 testerTopHead:{
    fontSize: 18,
    fontWeight: "400",
 [theme.breakpoints.down("767")]: {
    fontSize: 16,
    marginTop:'0',
    marginBottom: '15px'
  },
  [theme.breakpoints.down("479")]: {
    fontSize: 14,
  },
},
downloadOut:{
    position:"relative",
    marginBottom:"-38px",   
    [theme.breakpoints.down("767")]: {
        marginBottom:"20px",
    },
},
testerInnerBx:{
    borderRadius:"10px",
    padding:"10px",
    background:"#010A35",
    border:"1px solid #091550",
    marginTop:"25px",
    [theme.breakpoints.down("767")]: {
        marginTop:"20px",
    },
},
tableOut:{
    overflow:'auto', 
},
myTable:{
    minWidth:'900px',
    minHeight:'500px',
    [theme.breakpoints.down("767")]: {
        minHeight:'400px',
        fontSize:'13px',
    },
},
testerBottom:{
    display:"flex",  
    justifyContent:"space-between", 
    marginTop:"20px",
    [theme.breakpoints.down("1023")]: {
        display:"block",
        marginTop:"5px",
    },
},
tableheadplan:{
    color:"#fff",
    textAlign:"left",
    borderRadius:"8px !important",
    background: "rgb(23,111,254)",
    background: "-moz-linear-gradient(left, rgba(23,111,254,1) 0%, rgba(24,173,253,1) 100%)",
    background: "-webkit-linear-gradient(left, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    background: "linear-gradient(to right, rgba(23,111,254,1) 0%,rgba(24,173,253,1) 100%)",
    filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#176ffe', endColorstr='#18adfd',GradientType=1 )"  
},
mythead:{
    padding:"8px 12px !important",
    color:"#fff",
    borderBottom:"0px none",
    width:"9%",
    [theme.breakpoints.down("767")]: {
        fontSize:'13px',
    },
},
tableData:{
    color:"#8490BA !important",
    padding:"13px 12px !important",
    fontSize:"14px",
    borderBottom:"0px none",
    [theme.breakpoints.down("767")]: {
        fontSize:'13px',
    },
},
allProxyOut:{
    marginLeft:"10px"
},
testerBotBox:{
   display:"flex",
   [theme.breakpoints.down("1023")]: {
    marginTop:"15px",
}, 
    [theme.breakpoints.down("479")]: {
        display:"block",
        marginTop:'25px !important'
}, 

},
mySelect:{
   background:"#091550",
   borderRadius:"4px",
   color:"#B0AAAA",
   fontSize:"14px",
   padding:"10px 5px 10px 10px",
   fontWeight:"300 !important",
   height:"40px",
   marginRight:"5px",
   [theme.breakpoints.down("767")]: {
        fontSize:'13px',
   },
   [theme.breakpoints.down("479")]: {
        marginRight:"0px",
        width:'100%',
        marginBottom:'15px'
   },

},
 
  divcolor: {
    backgroundColor: colors.darkBlue,
  },
  dividerimg: {
    height: 20,
    width: "auto",
    marginTop: "30px",
    [theme.breakpoints.down(1200)]: {
      marginTop: "20px",
   },
   [theme.breakpoints.down(959)]: {
    display:"none",
 }
},
pagetitle: {
lineHeight: "30px !important",
fontSize:"32px !important",
[theme.breakpoints.down(1200)]: {
fontSize:"28px !important",
fontWeight: "500"
},
[theme.breakpoints.down(959)]: {
fontSize:"24px !important",
},
[theme.breakpoints.down(599)]: {
fontSize:"20px !important",
marginBottom:"0px",
}
},
titlebox: {
textAlign:"center",
marginBottom:"24px",
paddingTop:"50px",
[theme.breakpoints.down(1200)]: {
  paddingTop:"35px",
  marginBottom:"10px"
},
[theme.breakpoints.down(959)]: {
  paddingTop:"26px",
  marginBottom:"10px"
},
[theme.breakpoints.down(599)]: {
  paddingTop: "33px",
  marginBottom: "5px"
},

},
  
}));

function ProxyTester() {
    const history = useHistory();
    const classes = useStyles();
    
  return (
    <Box>
            <Top />
       
        <div className={classes.drawerHeader}/>
      <main className={classes.content}  >
       <div className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image1})`,
            }}>
            <Box className={classes.titlebox}>
            <Typography variant="h3" className={classes.pagetitle}>Proxy Tester</Typography>   
             <img src={dividerimage} alt="no img" className={classes.dividerimg}/>
             </Box>
        </div>
        <div className={classes.divcolor}>
            <Container className={classes.container1} maxWidth="lg" width="1200px">
            <div className={classes.testerTop}>
                <h4 className={classes.testerTopHead}> The most advanced and accurate free proxy tester application is now available by Live Proxies.</h4>
                <Box className={classes.downloadOut}>
                    <Button variant="contained" className={classes.myButton} 
                    onClick={()=> window.open("https://drive.google.com/file/d/1Xy_GWmFczkPn9Kg66LOUjjMxHJ3G2tYd/view", "_blank")} >
                        Download <img src={Windows} alt="Windows"  className={classes.myButtonImg} />
                    </Button>
                </Box>
            </div>
            <div className={classes.testerBox}>
                <Box className={classes.allProxyOut}>
                    <Button variant="contained" className={classes.myButton}>
                        Add Proxies 
                    </Button>
                </Box>
                <Box className={classes.testerInnerBx}>
                    <Box className={classes.tableOut}>
                     <Table className={classes.myTable}>
                     <TableHead className={classes.tableheadplan}>
                        <TableRow>
                             <TableCell className={classes.mythead}>IP</TableCell>
                             <TableCell className={classes.mythead}>PORT</TableCell>  
                             <TableCell className={classes.mythead}>USER</TableCell>  
                             <TableCell className={classes.mythead}>PASSWORD</TableCell>
                             <TableCell className={classes.mythead}>COUNTRY</TableCell>
                             <TableCell className={classes.mythead}>ISP</TableCell>
                             <TableCell className={classes.mythead}>SPEED</TableCell>
                             <TableCell className={classes.mythead}>PASSWORD</TableCell>    
                        </TableRow>
                     </TableHead>
                     <TableBody className={classes.tblbodyplan}>
                         <TableRow>
                             <TableCell className={classes.tableData}></TableCell>
                             <TableCell className={classes.tableData}></TableCell>  
                             <TableCell className={classes.tableData}></TableCell>  
                             <TableCell className={classes.tableData}></TableCell>
                             <TableCell className={classes.tableData}></TableCell>
                             <TableCell className={classes.tableData}></TableCell>  
                             <TableCell className={classes.tableData}></TableCell>  
                             <TableCell className={classes.tableData}></TableCell>    
                        </TableRow>
                              
                     </TableBody>    

                     </Table>
                     </Box> 
                </Box>
            </div>    
            <div className={classes.testerBottom}>
                <div className={classes.testerBotBox}>
                      <Input placeholder="AMAZON" className={classes.mySelect}  /> 

                    <NativeSelect className={classes.mySelect} >
                        <option value="" disabled>Test Random 5 Proxies</option>
                        <option value="">Test Random 10 Proxies</option>
                        <option value="">Test Random 15 Proxies</option>
                    </NativeSelect>
                    <Button variant="contained" className={classes.myButtonFull} >
                        TEST
                    </Button>
                </div>
                <div className={classes.testerBotBox}>
                    <NativeSelect className={classes.mySelect} >
                        <option value="" disabled>Clear All Proxies</option>
                        <option value="">Clear All Proxies</option>
                        <option value="">Clear All Proxies</option>
                    </NativeSelect>
                    <Button variant="contained" className={classes.myButtonFull} >
                        CLEAR
                    </Button>
                </div>
                <div className={classes.testerBotBox}>
                    <NativeSelect className={classes.mySelect} >
                        <option value="" disabled>Export All Proxies</option>
                        <option value="">Export All Proxies</option>
                        <option value="">Export All Proxies</option>
                    </NativeSelect>
                    <Button variant="contained" className={classes.myButtonFull} >
                        EXPOR
                    </Button>
                </div>
            </div>
           </Container>
         
          <Footer1 />
           </div>
       </main>
            
      
    </Box>
  );
}
export default ProxyTester;
